import React from 'react';
import {
  SzAlert,
  SzButton,
  SzDropDown,
  SzPagination,
  SzTable,
  SzTypographie
} from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import Error from '../../components/Error/ErrorModel';
import './userList.scss';
import { departmentCodeList } from '../../utils';
import { Modals } from './';

export default function UserList(props) {
  const {
    data,
    activePage,
    totalItemsCount,
    pageRangeDisplayed,
    onChangePage,
    userInfos,
    setUserInfos,
    displayModal,
    onClose,
    showMessage
  } = props;
  const { t } = useTranslation('components');
  const columns = [
    {
      dataField: 'name',
      text: 'Identifiant',
      formatter: (value: any, { firstName, lastName, email }: any) => {
        return (
          <div>
            <SzTypographie className="mb-0" weight="medium">
              {`${firstName || '-'} ${lastName || ''}`}
            </SzTypographie>
            <SzTypographie variant="caption" size="M" color="inactive">
              {email}
            </SzTypographie>
          </div>
        );
      }
    },
    {
      dataField: 'type',
      text: 'Rôle',
      formatter: (role) => {
        return t(`components:admins.roles.${role}`);
      }
    },
    {
      dataField: 'lastLogin',
      text: 'Dernière connexion'
    },
    {
      dataField: 'registrationDate',
      text: "Date d'ajout"
    },
    {
      dataField: 'departmentCode',
      text: 'Service',
      formatter: (service) => departmentCodeList[service]
    },
    {
      dataField: 'actions',
      text: 'Options',
      formatter: (actions: Modals[], { id, email }: any) => {
        if (
          actions.length > 0 &&
          (actions.includes('delete_user') ||
            actions.includes('send_invitation'))
        ) {
          const toMove = actions.indexOf('send_invitation');
          if (toMove) {
            delete actions[toMove];
            actions.splice(0, 0, 'send_invitation');
          }
          return (
            <SzDropDown alignRight className="cy-admin-actions">
              {actions.map((action) => {
                if (
                  action === 'delete_user' ||
                  action === 'send_invitation' ||
                  action === 'update_to_admin'
                ) {
                  const onClick = () => {
                    setUserInfos({ userId: id, email });
                    displayModal(action);
                  };

                  const icon = classnames({
                    'bin-2-alternate': action === 'delete_user',
                    'send-email-1': action === 'send_invitation',
                    'single-neutral-actions-setting':
                      action === 'update_to_admin'
                  });

                  return (
                    <SzDropDown.Item key={action} onClick={onClick}>
                      <SzButton variant="tertiary" icon={icon}>
                        {t(`components:admins.actions.${action}`)}
                      </SzButton>
                    </SzDropDown.Item>
                  );
                }
              })}
            </SzDropDown>
          );
        }
      }
    }
  ];

  return (
    <div className="tsme-userlist cy-userlist row no-gutters bg-white">
      <div className="col-12 pt-3 px-3">
        {data.length == 0 ? (
          <Error
            title={t('components:errors.noResult')}
            errorType={'no-result'}
            searchError
          />
        ) : (
          <div className="w-100">
            <SzTable
              keyField="id"
              className="w-100 m-0"
              data={data}
              columns={columns}
              sticky={isMobile}
            />
          </div>
        )}
      </div>
      {showMessage && (
        <div className="col-12 position-relative d-flex justify-content-center align-items-end">
          <SzAlert
            variant={showMessage.endsWith('success') ? 'success' : 'danger'}
            className="position-absolute"
            onClose={onClose}
          >
            {t(`pages:adminSpace.message.${showMessage}`, {
              email: userInfos.email
            })}
          </SzAlert>
        </div>
      )}
      <div className="col-12 d-flex align-items-center justify-content-center bg-white">
        <SzPagination
          activePage={activePage}
          itemsCountPerPage={1}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={onChangePage}
        />
      </div>
    </div>
  );
}
