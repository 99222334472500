export const fallBackRole = { enabled: false, reason_code: null };

export type RoleList =
  | 'CB'
  | 'CONSULT_CONSO'
  | 'INFOS_CONTRAT'
  | 'PRELEVEMENT'
  | 'RIB'
  | 'DEPOSE_RELEVE'
  | 'FACTURE'
  | 'MENSUALISATION'
  | 'TELERELEVE';

export type ReasonCode = 'PAYMENT_AUTO' | 'PRELEVE_AUTO' | 'OK' | 'internal_adviser';

export type Role = {
  enabled: boolean;
  reason_code: ReasonCode;
  role: RoleList;
};

// GetRoleInfo et hasRoleEnable font presque la même chose, à améliorer (peut-être un hook)

export default function GetRoleInfo(roles: Role[], lookupRole: RoleList) {
  const filteredRoles = roles.filter(item => item.role === lookupRole);
  const { enabled, reason_code } = filteredRoles[0] || {};

  return filteredRoles.length === 0 ? fallBackRole : { enabled, reason_code };
}

export function hasRoleEnable(roles: Role[], lookupRole: RoleList) {
  const matchingRoles = roles.filter(({ role }) => role === lookupRole);

  if (!matchingRoles.length) {
    return false;
  }

  return matchingRoles[0].enabled;
}
