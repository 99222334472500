import { SzButton, SzTypographie } from '@suezenv/react-theme-components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

type Message = 'success' | 'danger';

interface DropboxProps {
  showError?(showErrorMessage: Message, message: string): any;
  onCloseErrorMessage();
  postFiles(files: File[]);
}

export const Dropbox: FC<DropboxProps> = ({
  showError,
  onCloseErrorMessage,
  postFiles
}) => {
  const { t, i18n } = useTranslation();

  const nbMaxFilesUpload = 3;
  const sizeTotalFiles = 5;
  const [key, setMyKey] = useState(Date.now());

  const customValidator = (file) => {
    const sizeTotalFilesOctect =
      sizeTotalFilesMo() > 0 ? sizeTotalFilesMo() * 1024 * 1024 : 0;

    const currentTotalFileOctect = sizeTotalFilesOctect + file.size;
    const sizeTotalFilesOctets = sizeTotalFiles * 1024 * 1024;

    if (currentTotalFileOctect > sizeTotalFilesOctets) {
      return {
        code: 'size-files-too-large',
        message: t('customerService.contactForm.errors.size-files-too-large', {
          sizeTotalFiles: sizeTotalFiles
        })
      };
    }

    return null;
  };

  const { acceptedFiles, fileRejections, open, getRootProps, getInputProps } =
    useDropzone({
      validator: customValidator,
      accept:
        'image/jpeg, image/png, image/gif, application/pdf, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      maxFiles: nbMaxFilesUpload,
      noClick: true,
      noKeyboard: true
    });

  const [currentAcceptedFile, setCurrentAcceptedFile] =
    useState<File[]>(acceptedFiles);

  useEffect(() => {
    onCloseErrorMessage();
    const newAcceptedFiles = [...currentAcceptedFile, ...acceptedFiles];

    setCurrentAcceptedFile(newAcceptedFiles);

    if (fileRejections.length > 0 && showError) {
      const message = construcErrorMessage(fileRejections);

      if (message) {
        showError('danger', message);
      }
    }
  }, [acceptedFiles, fileRejections]);

  const construcErrorMessage = (fileRejections): string => {
    let message = '';
    let addMessage = true;

    fileRejections.forEach((file) =>
      file?.errors?.forEach((error) => {
        const keyMessage = `customerService.contactForm.errors.${error.code}`;

        if (error.code === 'too-many-files' && !addMessage) {
          return;
        }

        if (error.code === 'too-many-files') {
          addMessage = false;
        }

        if (i18n.exists(keyMessage)) {
          message += t(keyMessage, {
            fileName: file.file.name,
            sizeTotalFiles: sizeTotalFiles,
            nbTotalFiles: nbMaxFilesUpload
          });
        }
      })
    );

    return message;
  };

  const deleteFile = (keyFile) => {
    currentAcceptedFile.splice(keyFile, 1);
    setCurrentAcceptedFile(currentAcceptedFile);
    setMyKey(Date.now());
  };

  const sizeTotalFilesMo = () => {
    let sizeTotalFiles = 0;

    for (let index = 0; index < currentAcceptedFile.length; index++) {
      sizeTotalFiles += currentAcceptedFile[index].size;
    }

    return sizeTotalFiles / 1024 / 1024;
  };

  const restSizeFileToUpload = (decimals = 2) => {
    const currentSizeTotalFilesOctect =
      sizeTotalFilesMo() > 0 ? sizeTotalFilesMo() * 1024 * 1024 : 0;

    const sizeTotalFilesOctets = sizeTotalFiles * 1024 * 1024;

    const currentSize = sizeTotalFilesOctets - currentSizeTotalFilesOctect;

    if (currentSize === 0) return '0 Octets';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(currentSize) / Math.log(k));

    return (
      parseFloat((currentSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    );
  };

  if (currentAcceptedFile.length > 0) {
    postFiles(currentAcceptedFile);
  }

  return (
    <>
      <section className="cy-dropzone container p-0 border " key={key}>
        {currentAcceptedFile.map((file, key) => (
          <div
            className="cy-dropzone-file row bg-light no-gutters py-3"
            key={file.name}
          >
            <SzButton
              variant="tertiary"
              className="col-4"
              icon="bin-2-alternate"
              onClick={() => deleteFile(key)}
            >
              {t('customerService.contactForm.labelDeleteFile')}
            </SzButton>
            <p className="d-none d-md-block col-8 my-auto pl-3">
              <SzTypographie weight="medium" className="mb-0">
                {file.name}
              </SzTypographie>
            </p>
          </div>
        ))}

        {currentAcceptedFile.length < nbMaxFilesUpload && (
          <div className="cy-dropzone-action row bg-light no-gutters py-3 ">
            <div
              {...getRootProps({ className: 'dropzone' })}
              className="col-12 col-md-4 text-center"
            >
              <input {...getInputProps()} />
              <SzButton variant="tertiary" icon="attachment" onClick={open}>
                {t('customerService.contactForm.labelJoinFiles')}
              </SzButton>
            </div>
            <p className="d-none d-md-block col-8 my-auto pl-3">
              <SzTypographie
                weight="medium"
                variant="caption"
                size="M"
                color="disabled"
              >
                {t('customerService.contactForm.acceptedFiles')}
              </SzTypographie>
            </p>
          </div>
        )}

        <div className="cy-dropzone-infos">
          <SzTypographie
            weight="regular"
            color="disabled"
            className="text-center py-5"
          >
            {t('customerService.contactForm.descMaxFile', {
              nbFiles: nbMaxFilesUpload - currentAcceptedFile.length,
              sizeTotalFiles: sizeTotalFiles,
              sizeTotalFilesMo: restSizeFileToUpload()
            })}
          </SzTypographie>
        </div>
      </section>
    </>
  );
};

export default Dropbox;
