import React, { FC, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SzIcon } from '@suezenv/react-theme-components';
import classnames from 'classnames';
import resolveProducts from './ProductResolver';
import './ButtonLink.scss';

export interface ButtonLinkProps {
  setAnimateSlide(value: boolean): void;
  type: string;
}

export const ButtonLink: FC<ButtonLinkProps> = ({ setAnimateSlide, type }) => {
  const allButtons = [
    {
      icon: 'smart-house-water',
      to: '/espace-produit/switch',
      title: 'ON’connect™ switch',
      id: 'switch'
    },
    {
      icon: 'analytics-bars-3d',
      to: '/espace-produit/fluids',
      title: 'ON’connect™ fluids',
      id: 'fluids'
    },
    {
      icon: 'database-1',
      to: '/espace-produit/water',
      title: 'SUEZ Water Data Transfer',
      id: 'water'
    }
  ];
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { pathname } = { ...location, ...params };
  const buttons = resolveProducts(allButtons, type);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerClasses = classnames(
    'tsme-product-btnLink-container overflow-auto'
  );
  const activeIndex = buttons.findIndex(({ to }) => to === pathname);

  const className = (active: boolean) => {
    return classnames('cursor-pointer text-center py-3 px-4 mx-3', {
      'shadow-sm': active
    });
  };

  const minWidth = 240;
  const btnStyle = (active: boolean) => ({
    minWidth,
    backgroundColor: active ? 'rgba(119,183,252,0.2)' : '#ffffff',
    border: `2px solid ${active ? '#77B7FC' : '#ffffff'}`,
    borderRadius: '4px'
  });

  const onClick = (to: string, key: number): void => {
    const scrollOptions: ScrollToOptions = {
      left: key === 0 ? 0 : minWidth,
      behavior: 'smooth'
    };
    scrollTo(scrollOptions);
    setAnimateSlide(false);
    setTimeout(() => {
      history.push(to);
      setAnimateSlide(true);
    }, 600);
  };

  const scrollTo = (options: ScrollToOptions) => {
    if (containerRef && containerRef.current) {
      const { current } = containerRef;
      current.scrollTo(options);
    }
  };

  useEffect(() => {
    if (activeIndex !== 0) {
      const scrollOptions: ScrollToOptions = {
        left: minWidth,
        behavior: undefined
      };
      scrollTo(scrollOptions);
    }
  }, []);

  return (
    <div ref={containerRef} className={containerClasses}>
      <div className="tsme-product-btnLink d-flex justify-content-center pt-4 pb-5">
        {buttons.map(({ icon, to, title }, key) => (
          <button
            key={title}
            className={className(to === pathname)}
            style={btnStyle(to === pathname)}
            onClick={() => onClick(to, key)}
          >
            <SzIcon
              icon={icon}
              variant="line"
              className="d-md-block d-none big"
            />
            {title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ButtonLink;
