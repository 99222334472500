import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SzButton, SzTypographie } from '@suezenv/react-theme-components';
import classnames from 'classnames';
import { isBrowser, isMobile } from 'react-device-detect';
import GetRoleInfo from '../../../services/Role';
import {
  string_truncate,
  hideInEnv,
  getInvoiceBillingAmount
} from '../../../services/Utils';
import { SzTypographieProps } from '@suezenv/react-theme-components/build/components/SzTypographie/SzTypographie';
import { useDispatch } from 'react-redux';
import { AppActions, ContractActions } from '../../../redux/actions';

export type CollapseHeaderProps = {
  reference: string;
  nbFacture: number | any;
  roles: any;
  name: string;
  payment_mode: 'N' | 'P' | 'M';
  balance_amount: string;
  served_address: string;
  [key: string]: any;
};

export const Header: FC<CollapseHeaderProps> = (props) => {
  const {
    reference,
    nbFacture,
    roles,
    name,
    payment_mode,
    balance_amount,
    served_address
  } = props;

  const dispatch = useDispatch();
  const { openModal } = AppActions;
  const { setContract } = ContractActions;
  const hideInPreprodProd = hideInEnv('prod', 'preprod', 'development');
  const { t } = useTranslation('components');
  const isCbEnabled = GetRoleInfo(roles, 'CB').enabled;
  const isPrelevEnabled = GetRoleInfo(roles, 'PRELEVEMENT').enabled;
  const isMensuEnabled = GetRoleInfo(roles, 'MENSUALISATION').enabled;
  const classes = classnames(
    'd-none d-md-block col pl-md-4 billing-collapse-header-contract-name',
    {
      'border-left': isBrowser
    }
  );

  const defaultText = t('billing.collapse.defaultName');
  const contractName = name ? string_truncate(name, 30) : defaultText;
  const address = served_address || defaultText;

  const captionProps: SzTypographieProps = {
    variant: 'caption',
    size: 'M',
    weight: 'medium',
    color: 'disabled'
  };

  const RefClient = () => (
    <div className="col-8 col-md-3">
      <SzTypographie className="refClient mb-1" weight="bold">
        {isMobile ? reference : t('billing.collapse.refClient', { reference })}
      </SzTypographie>
      <SzTypographie {...captionProps} className="address">
        {address}
      </SzTypographie>
    </div>
  );

  const ContractName = () => (
    <div className={classes}>
      <SzTypographie {...captionProps} className="mb-1">
        {t('billing.collapse.contractName')}
      </SzTypographie>
      <SzTypographie className="contractName" variant="text" weight="medium">
        {contractName}
      </SzTypographie>
    </div>
  );

  const paymentText = !payment_mode ? 'defaultName' : 'paiementMode';

  const PaiementMode = () => (
    <div className="billing-collapse-header-payment-mode d-none d-md-block col-md-2">
      <SzTypographie {...captionProps} className="mb-1">
        {t('billing.collapse.paiementModeTitle')}
      </SzTypographie>
      <SzTypographie variant="text" weight="medium">
        {t(`billing.collapse.${paymentText}`, { context: payment_mode })}
      </SzTypographie>
    </div>
  );

  const amountText = !payment_mode ? 'defaultName' : 'amount';
  const amount = getInvoiceBillingAmount(balance_amount, payment_mode);

  const Amount = () => (
    <div className="billing-collapse-header-amount-sold d-none d-md-block col-md-2">
      <SzTypographie {...captionProps} className="mb-1">
        {t(`billing.collapse.${amountText}`, { context: payment_mode })}
      </SzTypographie>
      <SzTypographie variant="text" weight="medium">
        {t(`billing.collapse.balance_amount`, { balance_amount: amount })}
      </SzTypographie>
    </div>
  );

  const infoRolePrelevement = GetRoleInfo(roles, 'PRELEVEMENT');

  const isPrelevInternalAdviser =
    !isPrelevEnabled && infoRolePrelevement?.reason_code === 'internal_adviser';

  const displayPaymentBtn = isPrelevInternalAdviser || isPrelevEnabled;

  const isPaymentMode = !isCbEnabled && displayPaymentBtn;

  const Action = () => (
    <div className="col-2 col-md-3 text-center">
      {/* {isCbEnabled && (
        <SzButton
          icon={isMobile ? 'currency-euro-circle' : undefined}
          variant="secondary"
        >
          {isBrowser && t('billing.collapse.settleBalance')}
        </SzButton>
      )} */}
      {isPaymentMode && (
        <SzButton
          onClick={onClick}
          variant="tertiary"
          icon="cog"
          alignIcon="right"
          isDisabled={isPrelevInternalAdviser}
          className="cy-payment-mode-btn"
        >
          {isBrowser && t('billing.collapse.paymentMeans')}
        </SzButton>
      )}
    </div>
  );

  const onClick = () => {
    if (isPrelevInternalAdviser) {
      return;
    }

    dispatch(openModal('billing_paiement'));
    dispatch(setContract(props));
  };

  return (
    <div className="row">
      <RefClient />
      <ContractName />
      <PaiementMode />
      <Amount />
      <Action />
    </div>
  );
};

export default Header;
