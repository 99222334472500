import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SzAlert, SzButton } from '@suezenv/react-theme-components';
import { AddContractModel } from '../../models';
import { ContractActions } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import FormConstructor, { InputConstructor } from '../FormConstructor';
import useCheckTypeUser from '../../hooks/useCheckTypeUser';

export default function AddContractForm(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addContract, contractFormResetError } = ContractActions;
  const [error, isLoading] = useSelector(({ contract }) => [
    contract.form.error,
    contract.form.loading
  ]);

  const isAdviserSimpleUser = useCheckTypeUser('internal_adviser_simple');

  const matchRoute = useRouteMatch('/creation-de-compte');
  const context = { context: 'update' };
  const onSubmit = (form) => dispatch(addContract(form, matchRoute));
  const { reference, identifier, zipcode } = AddContractModel;

  useEffect(() => {
    return () => {
      dispatch(contractFormResetError());
    };
  }, []);

  return (
    <FormConstructor model={AddContractModel} onSubmit={onSubmit}>
      {({ isValid, ...props }) => (
        <>
          <InputConstructor name="reference" {...reference} />
          <InputConstructor name="identifier" {...identifier} />
          <InputConstructor name="zipcode" {...zipcode} />
          <div className="mt-4 row no-gutters justify-content-end">
            {error && <SzAlert type="danger">{error}</SzAlert>}
            <SzButton
              type="submit"
              isDisabled={isAdviserSimpleUser || !isValid || isLoading}
              loader={isLoading}
            >
              {t('models:addContract.button', !matchRoute && context)}
            </SzButton>
          </div>
        </>
      )}
    </FormConstructor>
  );
}
