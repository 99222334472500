export const setActiveBTn = (payload) => ({
  type: "setActiveBTn",
  payload,
});
export const setCurrentTitleObject = (payload) => ({
  type: "setCurrentTitleObject",
  payload,
});

export const setCurrentCodeObject = (payload) => ({
  type: "setCurrentCodeObject",
  payload,
});

export const setRequirements = (payload) => ({
  type: "setRequirements",
  payload,
});
export const setRequirementSelected = (payload) => ({
  type: "setRequirementSelected",
  payload,
});

export const setHasSuccessSubmit = (payload) => ({
  type: "setHasSuccessSubmit",
  payload,
});

export const reset = () => ({
  type: "reset",
});

export default {
  setActiveBTn,
  setCurrentTitleObject,
  setCurrentCodeObject,
  setRequirementSelected,
  setRequirements,
  setHasSuccessSubmit,
  reset,
};
