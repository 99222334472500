import * as Yup from "yup";
import "./validators";
import { ModelConstructor } from "@suezenv/tsme-react-components/";

const phoneMatch = /^(0)([0-9 ]{9}$)/;

export default {
  last_name: {
    label: "",
    icon: "pencil-write",
    className: "col-md-12 px-0",
    placeholder: "Saisissez votre nom",
    schema: Yup.string()
      .matches(/^\D+$/, "Le champ nom doit être rempli.")
      .required("Le champ nom doit être rempli.")
  },
  first_name: {
    label: "",
    icon: "pencil-write",
    className: "col-md-12 px-0",
    placeholder: "Saisissez votre prénom",
    schema: Yup.string()
      .min(1, "Le champ prénom doit être rempli.")
      .matches(/^\D+$/, "Ce champ n'accepte pas le format numérique")
      .required("Le champ prénom doit être rempli.")
  },
  phone: {
    label: "",
    icon: "pencil-write",
    className: "col-md-12 px-0",
    placeholder: "Saisissez votre n° de téléphone",
    schema: Yup.string().required()
      .matches(phoneMatch, "Le numéro de téléphone n'est pas valide")
  },
  location: {
    label: "",
    icon: "pencil-write",
    className: "col-md-12 px-0",
    placeholder: "Saisissez votre commune",
    schema: Yup.string().required("Saisissez votre commune")
  },
  message: {
    type: "textarea",
    schema: Yup.string().required("Saisissez votre message")
  },
  references: {
    schema: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required()
        })
      )
  },
  files: {}
};
