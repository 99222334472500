import { SIGNUP } from '../types';
import {
  login,
  startLoading,
  stopLoading,
  setTokenValid,
  setError,
  resetError
} from './app';
import axios from '../../services/Request';

export const signupNextStep = () => ({
  type: SIGNUP.ACTIVESTEP
});

export const createdUser = user_created => ({
  type: SIGNUP.USER_CREATED,
  user_created
});

export const signup = form => {
  const url = '/user/registration';
  const body = { ...form };
  delete body.cgu;
  return async function (dispatch) {
    dispatch(startLoading());
    try {
      const { data } = await axios.post(url, body);
      dispatch(stopLoading());
      dispatch(signupNextStep());
      dispatch(setTokenValid());
      dispatch(resetError());
      localStorage.setItem('token', data.jwt);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.jwt}`;
    } catch ({ response }) {
      dispatch(stopLoading());
      dispatch(setError(response.data.message));
    }
  };
};

export default { signupNextStep, signup, createdUser };
