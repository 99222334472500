import { useState, useEffect } from "react";
import fetchJsonp from "fetch-jsonp";
import { parseData } from "../services/Request";

export default function useCapaddress(type, inputOutput, address) {
  const [zipCodeList, setZipCodeList] = useState([]);
  const [addressList, setAddressList] = useState([]);

  useEffect(() => {
    const endpoint = `https://ws.capadresse.com:2814/?`;
    const searchAddress = () => {
      const data = {
        request: "SearchAddress",
        inputOutput,
        step: "SearchLocality",
        countryCode: "FRA",
        languageCode: "fr",
        jsoncallback: "SearchAddress",
      };
      wrapperRequest(data, setZipCodeList);
    };
    const searchStreet = () => {
      const { locality, localityId, postalCode } = address;
      const data = {
        request: "SearchAddress",
        inputOutput,
        step: "SearchStreet",
        countryCode: "FRA",
        languageCode: "fr",
        qualityCode: 42,
        localityId,
        postalCode,
        locality,
        jsoncallback: "SearchAddress",
      };
      wrapperRequest(data, setAddressList);
    };
    const wrapperRequest = async (data, callback) => {
      const url = endpoint + parseData(data);
      try {
        const request = await fetchJsonp(url, {
          jsonpCallbackFunction: "SearchAddress",
        });
        const { addresses } = await request.json();
        callback(addresses.address);
      } catch (e) {
        return e;
      }
    };
    if (type === "street" && address) searchStreet();
    else if (type === "town") searchAddress();
  }, [inputOutput, type]);

  return type === "town" ? zipCodeList : addressList;
}
