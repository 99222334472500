import React from "react";
import { useSelector } from "react-redux";
import { SearchContainer } from "../../components/SearchArea";
import { BillingSearchForm } from "../../forms";

export default function Searchbox() {
  const { error } = useSelector(({ billing }) => billing);
  return (
    <SearchContainer error={error}>
      <BillingSearchForm />
    </SearchContainer>
  );
}
