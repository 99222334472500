import { push } from "connected-react-router";
import axios from "../../services/Request";
import { setListContract, setListContractMemory } from "./contract";
import { APP } from "../types";
import { CustomerActions } from "../../redux/actions";

export const setFacturesPro = (payload) => ({
  type: APP.SET_BILLING,
  payload,
});

export const login = () => ({
  type: APP.LOGIN,
});

export const logout = () => ({
  type: APP.LOGOUT,
});
export const setError = (error) => ({
  type: APP.ERROR.SET,
  error,
});
export const resetError = () => ({
  type: APP.ERROR.RESET,
});
export const setUser = (user) => ({
  type: APP.USER,
  user,
});
export const startLoading = () => ({
  type: APP.LOADING.START,
});
export const stopLoading = () => ({
  type: APP.LOADING.STOP,
});
export const formStartLoading = () => ({
  type: APP.FORM.LOADING.START,
});
export const formStopLoading = () => ({
  type: APP.FORM.LOADING.STOP,
});
export const formSetError = (error) => ({
  type: APP.FORM.ERROR.SET,
  error,
});
export const formResetError = () => ({
  type: APP.FORM.ERROR.RESET,
});
export const openModal = (modalName) => ({
  type: APP.MODAL.OPEN,
  modalName,
});
export const closeModal = (goTo) => {
  const action = { type: APP.MODAL.CLOSE, modalName: null };
  if (goTo) {
    return function (dispatch) {
      dispatch(action);
      goTo && dispatch(push(goTo));
    };
  }
  return action;
};
export const setTokenValid = () => ({
  type: APP.TOKEN_VALID,
  token_valid: true,
});
export const getProfile = () => {
  const url = "/api/user";
  return async (dispatch) => {
    dispatch(startLoading());
    const { data } = await axios.get(url);
    dispatch(setUser(data));
    dispatch(setListContract(data.contracts));
    dispatch(setListContractMemory(data.contracts));
    dispatch(stopLoading());
  };
};
export const updateName = (form) => {
  const url = "/api/user/civility";
  return async (dispatch, getState) => {
    const { app } = getState();
    dispatch(formStartLoading());
    try {
      const { department_code, ...dataTemp } = form;

      if (department_code) {
        if (typeof department_code === "object") {
          form["department_code"] = department_code.value;
        } else {
          form["department_code"] = department_code;
        }
      }

      const response = await axios.post(url, form);
      dispatch(setUser({ ...app.currentUser, ...form }));
      dispatch(formStopLoading());
      dispatch(closeModal());
      return response;
    } catch (error) {
      const { response } = error;
      dispatch(formStopLoading());
      dispatch(formSetError(response.data.message || "Erreur"));
      throw error;
    }
  };
};
export const updateEmail = (form) => {
  const url = "/api/user/email";
  return async (dispatch, getState) => {
    dispatch(formStartLoading());
    const { app } = getState();
    try {
      const { data } = await axios.post(url, form);
      localStorage.setItem("token", data.jwt);
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.jwt}`;
      dispatch(setUser({ ...app.currentUser, ...form }));
      dispatch(closeModal());
      dispatch(formStopLoading());
    } catch (error) {
      dispatch(formStopLoading());
      dispatch(formSetError(error.response.data.message || "Erreur"));
    }
  };
};
export const updatePassword = (form) => {
  const url = "/api/user/password";
  return async (dispatch) => {
    dispatch(formStartLoading());
    try {
      await axios.post(url, form);
      dispatch(closeModal());
      dispatch(formStopLoading());
    } catch ({ response }) {
      dispatch(formSetError(response.data.message));
    }
  };
};
export const tokenCheck = () => {
  const url = "/api/token-check";
  return async (dispatch) => {
    try {
      const request = await axios.get(url);
      dispatch(login());
      dispatch(setTokenValid());
      dispatch(getProfile());
      return request;
    } catch (error) {
      dispatch(push("/erreur/redirection"));
      dispatch(logout());
    }
  };
};

export const updateUserClientService = (paramsCivility, paramsRequestFiles) => {
  return async (dispatch, getState) => {
    try {
      const { app } = getState();
      const urlCivility = "/api/user/civility";

      await axios.post(urlCivility, paramsCivility);
      dispatch(setUser({ ...app.currentUser, ...paramsCivility }));

      return dispatch(requestFiles(paramsRequestFiles));
    } catch (error) {
      const { response } = error;

      dispatch(formSetError(response.data.message || "Erreur"));

      return { isSuccess: false };
    }
  };
};

export const requestFiles = (params) => {
  return async (dispatch) => {
    try {
      const url = "/api/contact/request/files";

      await axios.post(url, params, {
        headers: { post: { "Content-Type": "multipart/form-data" } }
      });

      return { isSuccess: true };
    } catch (error) {
      dispatch(formSetError(error.response.data.message));

      return { isSuccess: false };
    }
  };
};

export const clientServiceFiles = (form, hasCivility) => {
  return async (dispatch, getState) => {
    dispatch(formStartLoading());
    let isSuccess = false;
    const paramsRequestFile = { ...form };

    if (paramsRequestFile.first_name) {
      delete paramsRequestFile.first_name;
    }

    if (paramsRequestFile.last_name) {
      delete paramsRequestFile.last_name;
    }

    if (hasCivility) {
      const paramsUpdateUser = {
        first_name: form.first_name,
        last_name: form.last_name
      };

      const response = await dispatch(
        updateUserClientService(paramsUpdateUser, paramsRequestFile)
      );

      isSuccess = response?.isSuccess;
    } else {
      const response = await dispatch(requestFiles(paramsRequestFile));

      isSuccess = response?.isSuccess;
    }

    dispatch(formStopLoading());

    if (isSuccess) {
      dispatch(CustomerActions.setHasSuccessSubmit(true));
    }
  };
};

export default {
  login,
  logout,
  setError,
  resetError,
  setUser,
  startLoading,
  stopLoading,
  openModal,
  closeModal,
  setTokenValid,
  getProfile,
  updateName,
  updateEmail,
  updatePassword,
  tokenCheck,
  formResetError,
  clientServiceFiles
};
