import React from 'react';
import { SzTooltip, SzDropDown, SzButton } from '@suezenv/react-theme-components';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { BillingActions } from '../../../redux/actions/';
import { useTranslation } from 'react-i18next';

export default function Dropdown(props) {
  const { value, numeroFacture, dateFacture, reference } = props;
  const { t } = useTranslation('components');
  const dispatch = useDispatch();
  const downloading = useSelector(({ billing }) =>
    billing.download.includes(numeroFacture)
  );
  const { downloadBill, getListEncart } = BillingActions;
  const invoiceDate = dateFacture.split('/').join('-');

  const text = {
    downloadBill: t('billing.table.content.tooltip.downloadBill'),
    linkedDocs: t('billing.table.content.tooltip.linkedDocs'),
    justifConso: t(
      'billing.table.content.tooltip.justifConso',
      isMobile && { context: 'mobile' }
    )
  };

  const onClickBill = () => dispatch(downloadBill(value, numeroFacture));
  const onClickModal = () =>
    dispatch(
      getListEncart({
        reference,
        invoiceNumber: numeroFacture,
        invoiceDate
      })
    );

  const IconButton = ({ children, icon, ...props }) =>
    isMobile ? (
      <SzButton
        {...props}
        variant="tertiary"
        className="p-0 w-100 justify-content-start"
      >
        {children}
      </SzButton>
    ) : (
      <SzButton
        {...props}
        className={downloading && 'p-0'}
        variant="tertiary"
        icon={icon}
      />
    );

  const DownloadBill = () => (
    <IconButton
      variant="tertiary"
      loader={downloading}
      onClick={onClickBill}
      icon="download-bottom"
    >
      {text.downloadBill}
    </IconButton>
  );

  const LinkedDocs = () => (
    <IconButton icon="folder-file-1" onClick={onClickModal}>
      {text.linkedDocs}
    </IconButton>
  );

  const JustifConsoMobile = () => (
    <Link to="/espace-facturation">
      <IconButton icon="performance-increase-2">{text.justifConso}</IconButton>
    </Link>
  );

  if (isMobile) {
    return (
      <SzDropDown alignRight className="billing-dropDown">
        <SzDropDown.Item>
          <DownloadBill />
        </SzDropDown.Item>
        <SzDropDown.Item>
          <LinkedDocs />
        </SzDropDown.Item>
        {!['preprod', 'prod'].includes(process.env.REACT_APP_ENV) && (
          <SzDropDown.Item>
            <JustifConsoMobile />
          </SzDropDown.Item>
        )}
      </SzDropDown>
    );
  }

  return (
    <div className="d-flex">
      <SzTooltip content={text.downloadBill}>
        <DownloadBill />
      </SzTooltip>
      <SzTooltip content={text.linkedDocs}>
        <LinkedDocs />
      </SzTooltip>
      {!['preprod', 'prod'].includes(process.env.REACT_APP_ENV) && (
        <SzTooltip content={text.justifConso}>
          <JustifConsoMobile />
        </SzTooltip>
      )}
    </div>
  );
}
