export default {
  SET_BILLING: 'SET_BILLING',
  LOGIN: 'APP_LOGIN',
  LOGOUT: 'APP_LOGOUT',
  TOKEN_VALID: 'APP_TOKEN_VALID',
  USER: 'APP_USER',
  MODAL: {
    OPEN: 'APP_OPEN_MODAL',
    CLOSE: 'APP_CLOSE_MODAL'
  },
  LOADING: {
    START: 'APP_START_LOADING',
    STOP: 'APP_STOP_LOADING'
  },
  ERROR: {
    SET: 'APP_SET_ERROR',
    RESET: 'APP_RESET_ERROR'
  },
  FORM: {
    LOADING: {
      START: 'APP_FORM_START_LOADING',
      STOP: 'APP_FORM_STOP_LOADING'
    },
    ERROR: {
      SET: 'APP_FORM_SET_ERROR',
      INIT: 'APP_FORM_INIT_ERROR',
      RESET: 'APP_FORM_RESET_ERROR'
    }
  }
};
