import React, { useState, useEffect } from 'react';
import { useTheme, ThemeSelector } from '@suezenv/react-theme-components';

export default function TSMEThemeSelector() {
  const { updateTheme } = useTheme();
  const [value, setValue] = useState('');
  const onChange = option => {
    setValue(option);
    updateTheme(option.label);
  };

  return <ThemeSelector onChange={onChange} className="col-2" value={value} />;
}
