import React from 'react';
import { SzTypographie } from '@suezenv/react-theme-components';

export default function Text({ label, children, className }) {
  return (
    <div className={className}>
      <SzTypographie variant="caption" color="inactive">
        {label}
      </SzTypographie>
      <SzTypographie weight="medium">{children}</SzTypographie>
    </div>
  );
}
