import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SzTypographie,
  SzButton,
  SzAlert
} from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { ContractActions } from '../../redux/actions';
import clsx from 'clsx';
import {
  Form,
  IbanWithMandatPaymentAuto,
  Input
} from '@suezenv/tsme-react-components';
import checkHasErrorIban from '@suezenv/tsme-react-components/build/utils/checkHasErrorIban';
import { isMobile } from 'react-device-detect';
import useCheckTypeUser from '../../hooks/useCheckTypeUser';

export default function IbanContractForm() {
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();
  const { updateIban, contractFormResetError } = ContractActions;

  const { item: contract, form } = useSelector(({ contract }) => contract);
  const isAdviserSimpleUser = useCheckTypeUser('internal_adviser_simple');
  const isInternAdminUSer = useCheckTypeUser('internal_adviser_admin');

  const isLoading = form.loading;

  const { holder, ...iban_props } = IbanWithMandatPaymentAuto;

  useEffect(() => {
    return () => {
      dispatch(contractFormResetError());
    };
  }, []);

  delete IbanWithMandatPaymentAuto.mobile_phone;
  delete IbanWithMandatPaymentAuto.last_name;
  delete IbanWithMandatPaymentAuto.first_name;

  const modelForm = { ...IbanWithMandatPaymentAuto };

  const refsInput = {};
  for (const name of Object.keys(iban_props)) {
    refsInput[name] = React.createRef();
  }

  const onchangeIbanFields = (e) => {
    const { value, name } = e.target;

    const reg = /^.{4}$/;
    const nextIndex = Object.keys(iban_props).indexOf(name) + 2;
    const inputLength = Object.keys(iban_props).length;
    const nextRefIndex = `iban_field_${nextIndex}`;

    if (reg.test(value)) {
      const nextValue = refsInput[nextRefIndex].current.value;

      if (nextIndex < inputLength + 1 && value.length > 0 && !nextValue) {
        refsInput[nextRefIndex].current.focus();
      }
    }
  };

  const displayIbanFields = Object.keys(iban_props).map((name) => {
    return (
      <Input
        key={name}
        showSpecificError={false}
        changeCase="upperCase"
        ref={refsInput[name]}
        valid={false}
        name={name}
        onChange={onchangeIbanFields}
        {...iban_props[name]}
        className="col-3 col-md-1 px-1 mx-2"
      />
    );
  });

  const labelIban = 'Veuillez saisir vos coordonnées de compte IBAN';

  const onSubmit = async (dataForm) => {
    let newIban = '';

    for (let index = 1; index < 8; index++) {
      if (dataForm[`iban_field_${index}`]) {
        newIban += dataForm[`iban_field_${index}`];
      }
    }

    const formData = {
      reference: contract.reference,
      holder: dataForm.holder,
      iban: newIban
    };

    dispatch(updateIban(formData));
  };

  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={modelForm}
      initialValues={{}}
      className={'m-0'}
    >
      {({ values, isValid, errors, setFieldValue, ...props }) => {
        return (
          <>
            <div className="row mt-2 mb-4">
              <Input
                name={'holder'}
                {...holder}
                changeCase="upperCase"
                className="col-md-4"
              />
            </div>

            <div className="row mb-5">
              <SzTypographie
                weight="regular"
                className={`col-md-12 title-page mb-3`}
              >
                {labelIban}
              </SzTypographie>
              {displayIbanFields}
              <div
                className={clsx('col-12', {
                  'd-none': !checkHasErrorIban(values)
                })}
              >
                <SzAlert variant="danger">{t('iban.wrongIban')}</SzAlert>
              </div>
            </div>

            {form.error && (
              <div className="row">
                <SzAlert type="danger" className={'col-12 ml-2'}>
                  {form.error}
                </SzAlert>
              </div>
            )}

            <div className="row">
              <SzButton
                className={clsx(isMobile ? 'm-auto' : 'ml-auto')}
                loader={isLoading}
                isDisabled={
                  isAdviserSimpleUser ||
                  isInternAdminUSer ||
                  Object.keys(errors).length
                    ? true
                    : isLoading
                }
                type="submit"
              >
                {t('models:editContract.button')}
              </SzButton>
            </div>
          </>
        );
      }}
    </Form>
  );
}
