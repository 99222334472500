import React from "react";
import {
  SzButton,
  SzHeader,
  SzTypographie
} from "@suezenv/react-theme-components";
import LogoLink from "./Logo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../redux/hooks";
import { CustomerActions } from "../../redux/actions";

export const ConnectedCustomHeader = (props) => {
  const { t } = useTranslation("pages");
  const dispatch = useAppDispatch();
  const history = useHistory();

  const btnClose = () => {
    if ( props.title === "customerService") {
      dispatch(CustomerActions.reset())
    }
    
    return history.goBack();
  };

  return (
    <SzHeader className="tsme-header unlogged d-flex align-items-stretch justify-content-between row no-gutters">
      <LogoLink className={"col-4 col-md-2"} />

      <div className="col-4 col-md-8 text-center my-auto">
        {props.title.title && (
          <SzTypographie variant="h1" color="dark">
            {t(`${props.title}.title`)}
          </SzTypographie>
        )}
      </div>
      <div className="col-4 col-md-2 my-auto text-right">
        <SzButton
          variant="tertiary"
          className="mr-3"
          icon="close"
          alignIcon="right"
          onClick={btnClose}
        >
          {t(`${props.title}.close`)}
        </SzButton>
      </div>
    </SzHeader>
  );
};

export default ConnectedCustomHeader;
