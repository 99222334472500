import React, { useEffect, useState } from 'react';
import * as themesList from './configs';

export const useTheme = () => {
  const href = 'https://www.toutsurmoneau.fr';
  const defaultTheme = {
    logo: `${href}/var/ezwebin_site/storage/images/sites/suez/header/14548-15-fre-FR/Header.svg`,
    title: 'SUEZ - Tout Sur Mon Eau',
    favicon: `${href}/bundles/app/images/global/icons/favicon_suez.ico`,
    brand: 'SUEZ'
  };

  const { hostname } = window.location;
  const themes = themesList[process.env.REACT_APP_ENV];
  const active = themes[hostname];

  return active || defaultTheme;
};
