import React from "react";
import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import classnames from "classnames";
import "./index.scss";

interface LoaderProps extends SkeletonProps {
  children: any;
  loading: boolean;
}

export default function Loading(props: LoaderProps) {
  const { children, className, loading, ...restProps } = props;
  const classes = classnames(
    "tsme-skeleton-wrapper d-flex align-items-center h-100 w-100",
    className
  );

  if (loading) {
    return (
      <div className={classes}>
        <Skeleton {...restProps} />
      </div>
    );
  }

  return children;
}
