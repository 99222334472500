import React, { FC } from 'react';
import { SzTypographie } from '@suezenv/react-theme-components';

export type DashboardCardProps = {
  onClick: () => any;
  link: string;
  linkTitle: string;
  title: string;
  subtitle: string;
};

export const DashboardCard: FC<DashboardCardProps> = ({ children, title }) => {
  return (
    <div className="d-flex flex-column bg-white h-100 pb-4">
      <div className="mb-4">
        <SzTypographie
          variant="h2"
          className="text-center bg-blue-sky-light py-2"
        >
          {title}
        </SzTypographie>
      </div>
      {children}
    </div>
  );
};

export default DashboardCard;
