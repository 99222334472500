import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { t } from '../i18n';

export const string_truncate = (str, length = 80, ending = '...') =>
  str.length > length ? str.substring(0, length - ending.length) + ending : str;

export function checkPassRules(pwd) {
  const small = /[a-z]/.test(pwd);
  const upper = /[A-Z]/.test(pwd);
  const digit = /[\d]/.test(pwd);
  const other = /[\W]/.test(pwd);
  const length = pwd.length >= 8;
  return { small, upper, digit, other, length };
}

export function downloadPdf(data, filename) {
  const blobUrl = URL.createObjectURL(data);
  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  downloadLink.download = filename;
  downloadLink.click();
  URL.revokeObjectURL(blobUrl);
}

export function formatAmount(amount) {
  if (!/[\.|,]/g.test(amount)) return `${amount},00`;
  if (/^-?\d+[\.,]\d{0}$/g.test(amount)) return `${amount}00`;
  if (/^-?\d+[\.,]\d{1}$/g.test(amount)) return `${amount}0`;
  return amount;
}

export function makeScrollTo(className, index) {
  const container = document.querySelector('.tsme-container');
  const el = document.querySelectorAll(className)[index];
  const top = el ? el.offsetTop : 0;

  if (container && el) {
    container.scrollTo({
      top,
      behavior: 'smooth'
    });
  }
}

export function displayedContentPerPage(
  page,
  nbOfPages,
  nbTotalElement,
  perPage = 5
) {
  let end, start;
  if (page === 1) {
    start = 0;
    end = perPage;
  } else if (page === nbOfPages) {
    const prevpage = page - 1;
    start = prevpage * perPage;
    end = nbTotalElement;
  } else {
    start = page * perPage - perPage;
    end = page * perPage;
  }
  return [start, end];
}

export function hideInEnv(...args) {
  return [...args].includes(process.env.REACT_APP_ENV);
}

export function isAdminType(userType) {
  const allowTypes = ['owner', 'admin', 'internal_adviser_admin'];

  return allowTypes.includes(userType);
}

export const sortByDate = (a, b, order, dataField) => {
  const dateA = a && new Date(a.split('/').reverse().join('-'));
  const dateB = b && new Date(b.split('/').reverse().join('-'));
  return order === 'asc' ? dateB - dateA : dateA - dateB;
};

export const getFactureLength = (list) => {
  return list.reduce((total, { nbFacture }) => nbFacture + total, 0);
};

export const getExportTrad = (contractToExport) => {
  const {
    reference,
    matriculeCompteur,
    adresseDesserte1,
    adresseDesserte2,
    adresseDesserte3,
    villeDesserte,
    cpDesserte
  } = contractToExport;
  const now = moment(new Date()).format('DD/MM/YYYY');
  const address = adresseDesserte1 + adresseDesserte2 + adresseDesserte3;

  const trad = (trad) => t(`components:consumption.histogram.excel.${trad}`);
  const arrayHeader = [
    [trad(`export_date`), now],
    [trad(`matricule`), matriculeCompteur],
    [trad(`refClient`), reference],
    [trad(`address`), address],
    [trad(`postal_code`), cpDesserte],
    [trad(`city`), villeDesserte]
  ];

  const labelTR = [trad(`months`), trad('index'), trad('conso')];
  const labelTRDaily = [trad('date'), trad('index'), trad('conso')];
  const labelNonTR = [
    trad('labelNonTR_date'),
    trad('labelNonTR_type'),
    trad('index'),
    trad('conso')
  ];

  const infoTRdaily = [trad('infoTRdaily')];
  const infoTRmonthly = [trad('infoTRmonthly')];
  const infoNonTR = [trad('infoNonTR')];

  return {
    arrayHeader,
    labelTR,
    labelTRDaily,
    labelNonTR,
    infoTRdaily,
    infoTRmonthly,
    infoNonTR
  };
};

export const exportToXlsx = (contractToExport, data, isTR, mode) => {
  const { matriculeCompteur } = contractToExport;
  const exportDate = moment(new Date()).format('DD-MM-YYYY');
  const fileName = `Export ${exportDate} Matricule ${matriculeCompteur}${
    mode ? ' - ' + mode : ''
  }`;
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const dateFormat = mode !== 'daily' ? 'MM-YYYY' : 'DD/MM/YYYY';

  const formatTR = ({ date, index, volume }) => [
    moment(date).format(dateFormat),
    index,
    volume
  ];
  const formatNonTR = ({ date, index, volume, type }) => [
    date,
    t('components:consumption.histogram.excel.type', { context: type }),
    index,
    volume
  ];

  const {
    arrayHeader,
    labelTR,
    labelTRDaily,
    infoTRmonthly,
    infoTRdaily,
    labelNonTR,
    infoNonTR
  } = getExportTrad(contractToExport);

  const formatedData = data.map(isTR ? formatTR : formatNonTR);
  const backToLine = [];

  const labelInfoTR = mode !== 'daily' ? infoTRmonthly : infoTRdaily;

  const dataTRToXlsx = [
    ...arrayHeader,
    backToLine,
    isTR ? (mode !== 'daily' ? labelTR : labelTRDaily) : labelNonTR,
    ...formatedData,
    backToLine,
    isTR ? labelInfoTR : infoNonTR
  ];

  const toDeleteTR = ['A1', 'B1', 'C1'];
  const toDeleteNonTR = ['A1', 'B1', 'C1', 'D1'];

  const sizeTR = [{ width: 30 }, { width: 30 }, { width: 30 }];
  const sizeNonTR = [
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 }
  ];

  const ws = XLSX.utils.json_to_sheet(dataTRToXlsx);
  for (const colName of isTR ? toDeleteTR : toDeleteNonTR) {
    delete ws[colName];
  }
  ws['!cols'] = isTR ? sizeTR : sizeNonTR;
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blobFile = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobFile, fileName + fileExtension);
};

export const formatSold = (sold) => {
  const formatedSold = (
    typeof sold === 'string' ? parseFloat(sold) : sold
  ).toFixed(2);

  return String(formatedSold)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
};

export const getInvoiceBillingAmount = (amount, payment_mode) => {
  switch (payment_mode) {
    case 'M':
      return '0,00';
    default:
      return formatSold(amount);
  }
};
