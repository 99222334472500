import React from 'react';
import Error from '../../components/Error/ErrorModel';
import { SzButton } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';

export default function NotFound(props) {
  const { t } = useTranslation('components');
  return (
    <div className="bg-white h-100 pt-5">
      <Error title={t('errors.notFound')} errorType={'not-found'}>
        <SzButton
          onClick={() => window.history.back()}
          variant="secondary"
        >
          Retour
        </SzButton>
      </Error>
    </div>
  );
}
