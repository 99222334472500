import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { SzAlert, SzButton, SzInput } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { AppActions } from '../../redux/actions';
import { PasswordModel, getFormatedForm } from '../../models';
import PasswordRules from '../../components/PasswordRules';
import {
  checkPassRules
} from '../../services/Utils';
import FormConstructor, { InputConstructor } from '../FormConstructor';
import useCheckTypeUser from '../../hooks/useCheckTypeUser';

export default function PasswordForm(props) {
  const [passwordValidation, setPasswordValidation] = useState(
    checkPassRules('')
  );
  const dispatch = useDispatch();
  const { t } = useTranslation('models');
  const { updatePassword, formResetError } = AppActions;
  const [error, isLoading] = useSelector(({ app }) => [
    app.form.error,
    app.form.loading
  ]);
  const isAdviserSimpleUser = useCheckTypeUser('internal_adviser_simple');
  const isInternAdminUSer = useCheckTypeUser('internal_adviser_admin');

  const onSubmit = (form) => dispatch(updatePassword(form));
  const { old_password, password, repeated_password } = PasswordModel;

  useEffect(() => {
    return () => {
      dispatch(formResetError());
    };
  }, []);

  return (
    <FormConstructor model={PasswordModel} onSubmit={onSubmit}>
      {({ isValid, values, errors, ...props }) => {
        const onBlur = () => {
          setPasswordValidation(checkPassRules(values['password']));
        };

        return (
          <>
            <InputConstructor name="old_password" {...old_password} />
            <div className="col-12 col-md-6">
              <InputConstructor
                name="password"
                onBlur={onBlur}
                {...password}
                className="col-md-12 p-0"
                showPassword
              />
              <InputConstructor
                name="repeated_password"
                {...repeated_password}
                className="col-md-12 p-0"
                showPassword
              />
            </div>
            <PasswordRules passwordValidation={passwordValidation} />
            <div className="col-12 mt-4 text-right">
              {error && <SzAlert type="danger">{error}</SzAlert>}
              <SzButton
                type="submit"
                loader={isLoading}
                isDisabled={
                  isInternAdminUSer ||
                  isAdviserSimpleUser ||
                  !isValid ||
                  isLoading
                }
                className={isLoading && 'col-4'}
              >
                {t('profile.button')}
              </SzButton>
            </div>
          </>
        );
      }}
    </FormConstructor>
  );
}
