import React, { FC, useEffect, useState } from "react";
import ReactTags, { ReactTagsProps } from "react-tag-autocomplete";
import {
  SzAlert,
  SzButton,
  SzTypographie,
} from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { setNextStep } from "./Modal/Invite";
import axios from "../../services/Request";
import "./style.scss";

export type AddUserForm = ReactTagsProps & {
  setNextStep: setNextStep;
  innerRef: React.RefObject<ReactTags>;
  errorMessage: string | null;
};

export const AddUserForm: FC<AddUserForm> = ({
  setNextStep,
  errorMessage,
  innerRef,
  ...props
}) => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { tags } = props;

  const onClick = async () => {
    setLoading(true);

    try {
      const formData = { emails: Array.from(tags || [], ({ name }) => name) };
      const { data } = await axios.post(
        "/api/management/user/emails",
        formData
      );
      const userList = Object.keys(data.emails).map((email) => {
        const user = data.emails[email];
        return {
          email,
          action: user.userStatus,
        };
      });
      setLoading(false);
      setNextStep(userList);
    } catch (error : any) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, []);

  return (
    <div className="d-flex flex-column">
      <SzTypographie variant="h1" weight="bold" className="mb-4">
        {t("adminSpace.tagsModal.title", { count: tags?.length })}
      </SzTypographie>
      <SzTypographie className="mb-3">
        {t("adminSpace.tagsModal.subtitle")}
        <SzTypographie tag="span" weight="bold">
          {t("adminSpace.tagsModal.subtitle_bold")}
        </SzTypographie>
      </SzTypographie>
      <SzTypographie className="m-0" weight="bold" color="disabled">
        {t("adminSpace.tagsModal.tagsLabel")}
      </SzTypographie>
      {loaded && (
        <ReactTags
          ref={innerRef}
          placeholderText={t("adminSpace.tagsModal.placeholder")}
          delimiters={["Enter", "Tab", " ", ",", ";"]}
          addOnBlur
          allowNew
          {...props}
        />
      )}
      {error && <SzAlert className="mt-3">{error}</SzAlert>}
      {errorMessage && <SzAlert className="mt-3">{errorMessage}</SzAlert>}
      <SzButton
        isDisabled={tags && tags.length === 0}
        className="mt-3 ml-auto"
        onClick={onClick}
        loader={loading}
      >
        {t("adminSpace.tagsModal.button")}
      </SzButton>
    </div>
  );
};

export default AddUserForm;
