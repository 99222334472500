import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SzIcon,
  SzTypographie,
  SzButton,
  SzAccordion
} from '@suezenv/react-theme-components';
import { useDispatch } from 'react-redux';
import { AppActions, ContractActions } from '../../redux/actions';
import { string_truncate } from '../../services/Utils';
import GetRoleInfo from '../../services/Role';

export default function CollapseContract({ item, eventKey, onClick }) {
  const { t } = useTranslation('pages', 'models', 'components');
  const defaultText = t('components:consumption.collapse.defaultName');
  const dispatch = useDispatch();
  const { openModal } = AppActions;
  const { setContract, contractModalSetActiveForm } = ContractActions;
  const selectContract = (active) => {
    dispatch(openModal());
    dispatch(setContract(item));
    dispatch(contractModalSetActiveForm(active));
  };

  const {
    reference,
    roles,
    iban,
    rib_holder,
    name,
    served_address,
    company_name,
    siret,
    billing_address_1,
    billing_address_2,
    billing_zip_code,
    billing_town,
    email,
    naf_code,
    mobile_phone,
    phone
  } = item;

  const isIbanEnabled = GetRoleInfo(roles, 'RIB').enabled;

  const contractName = name || defaultText;
  const address = served_address
    ? string_truncate(served_address, 67)
    : defaultText;
  const Header = () => (
    <div className="row align-items-center">
      <div className="col-md-4 col-12">
        <SzTypographie weight="bold" className="mb-1">
          {t('components:collapse.review.refClient', { reference })}
        </SzTypographie>
        <SzTypographie
          variant="caption"
          size="M"
          weight="medium"
          color="disabled"
        >
          {address}
        </SzTypographie>
      </div>
      <div className="col-md-4 col-12 d-flex align-items-center border-left pl-4">
        <SzIcon
          variant="line"
          icon="business-contract-handshake-sign d-none d-sm-block"
        />
        <div className="ml-3 d-none d-sm-block">
          <SzTypographie
            variant="caption"
            size="M"
            weight="medium"
            color="disabled"
            className="mb-1"
          >
            {t('review.contractNumb')}
          </SzTypographie>
          <SzTypographie tag="span" weight="medium">
            {contractName}
          </SzTypographie>
        </div>
      </div>
    </div>
  );

  const addressText = `${billing_address_1 !== null ? billing_address_1 : ''} ${
    billing_address_2 !== null ? billing_address_2 : ''
  } ${billing_zip_code !== null ? billing_zip_code : ''}
          ${billing_town !== null ? billing_town : ''}`;

  const Entity = () => (
    <div className="col-12 p-3 pl-4 bg-light">
      <SzTypographie
        variant="h2"
        weight="bold"
        className="mb-3"
        color="disabled"
      >
        {t('review.entite')}
      </SzTypographie>
      <div className="row no-gutters pl-2">
        <div className="social-reason col-md-3 col-12">
          <SzTypographie
            variant="caption"
            size="M"
            weight="medium"
            color="disabled"
            className="m-0"
          >
            {t('review.social')}
          </SzTypographie>
          <SzTypographie weight="medium">{company_name}</SzTypographie>
        </div>
        <div className="siret col-md-2 col-12">
          <SzTypographie
            variant="caption"
            size="M"
            weight="medium"
            color="disabled"
            className="m-0"
          >
            {t('review.siret')}
          </SzTypographie>
          <SzTypographie weight="medium">{siret}</SzTypographie>
        </div>
        <div className="code-naff col-md-2 col-12">
          <SzTypographie
            variant="caption"
            size="M"
            weight="medium"
            color="disabled"
            className="m-0"
          >
            {t('review.naf')}
          </SzTypographie>
          <SzTypographie weight="medium">{naf_code}</SzTypographie>
        </div>
        <div className="facture-address col-md-3 col-12">
          <SzTypographie
            variant="caption"
            size="M"
            weight="medium"
            color="disabled"
            className="m-0"
          >
            {t('review.billAddress')}
          </SzTypographie>
          <SzTypographie weight="medium">{addressText}</SzTypographie>
        </div>
        <div className="ml-auto">
          <SzButton
            variant="tertiary"
            icon="pencil-write"
            alignIcon="right"
            onClick={() => selectContract('billing')}
          >
            {t('models:links.edit')}
          </SzButton>
        </div>
      </div>
    </div>
  );

  const Privilege = () => (
    <div className="col-12 p-3 pl-4">
      <SzTypographie
        variant="h2"
        weight="bold"
        className="mb-3"
        color="disabled"
      >
        {t('review.privilegiee')}
      </SzTypographie>
      <div className="row no-gutters pl-2">
        <div className="mail col-md-3 col-12">
          <SzTypographie
            variant="caption"
            size="M"
            weight="medium"
            color="disabled"
            className="m-0"
          >
            {t('review.mail')}
          </SzTypographie>
          <SzTypographie weight="medium">{email}</SzTypographie>
        </div>
        <div className="tel-fixe col-md-2 col-12">
          <SzTypographie
            variant="caption"
            size="M"
            weight="medium"
            color="disabled"
            className="m-0"
          >
            {t('review.telFixe')}
          </SzTypographie>
          {phone && <SzTypographie weight="medium">{phone}</SzTypographie>}
        </div>
        <div className="tel-mobile col-md-3 col-12">
          <SzTypographie
            variant="caption"
            size="M"
            weight="medium"
            color="disabled"
            className="m-0"
          >
            {t('review.telMob')}
          </SzTypographie>
          {mobile_phone && (
            <SzTypographie weight="medium">{mobile_phone}</SzTypographie>
          )}
        </div>
        <div className="ml-auto">
          <SzButton
            variant="tertiary"
            icon="pencil-write"
            alignIcon="right"
            onClick={() => selectContract('contact')}
          >
            {t('models:links.edit')}
          </SzButton>
        </div>
      </div>
    </div>
  );

  const Iban = () => (
    <div className="row no-gutters align-items-center p-3 pl-4">
      <SzTypographie
        variant="h2"
        weight="bold"
        className={!isIbanEnabled || (iban && 'mb-3')}
        color="disabled"
      >
        {t('review.bank')}
      </SzTypographie>
      {isIbanEnabled && !iban && (
        <SzButton
          variant="secondary"
          className="ml-auto"
          icon="saving-bank"
          onClick={() => selectContract('iban')}
        >
          {t('iban.titleAddIban')}
        </SzButton>
      )}
      <div className="row no-gutters pl-2">
        {iban && (
          <>
            <div className="mail col-md-3 col-12">
              <SzTypographie
                variant="caption"
                size="M"
                weight="medium"
                color="disabled"
                className="m-0"
              >
                {t('iban.holder')}
              </SzTypographie>
              <SzTypographie weight="medium">{rib_holder}</SzTypographie>
            </div>
            <div className="col-md-2 col-12">
              <SzTypographie
                variant="caption"
                size="M"
                weight="medium"
                color="disabled"
                className="m-0"
              >
                {t('iban.title')}
              </SzTypographie>
              <SzTypographie weight="medium">{iban}</SzTypographie>
            </div>
          </>
        )}

        {isIbanEnabled && iban && (
          <div className="ml-auto">
            <SzButton
              variant="tertiary"
              icon="pencil-write"
              alignIcon="right"
              onClick={() => selectContract('iban')}
            >
              {t('iban.titleUpdate')}
            </SzButton>
          </div>
        )}
      </div>
    </div>
  );

  const Body = () => (
    <>
      <Entity />
      <Privilege />
    </>
  );

  const Footer = () => (iban || isIbanEnabled) && <Iban />;

  return (
    <SzAccordion.SzCollapse
      header={<Header />}
      body={<Body />}
      footer={<Footer />}
      eventKey={eventKey}
      onClick={onClick}
    />
  );
}
