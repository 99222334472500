import React, { useEffect, useState, useRef } from "react";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import { SzCheckbox, SzTypographie } from "@suezenv/react-theme-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CustomerActions } from "../../redux/actions";
import ContactFormImage from "../../assets/img/contact-form.svg";
import useScrollTo from "../../hooks/useScrollTo";

export default function Requirements() {
  const { t } = useTranslation();
  const { requirements, currentCodeObject } = useAppSelector(({ customer }) => customer);

  const dispatch = useAppDispatch();
  const defautValues = new Array(requirements.length).fill(false);

  const [checked, setChecked] = useState(defautValues);
  const containerRef = useRef<HTMLDivElement>(null);

  const scroll = useScrollTo();

  useEffect(() => {
    setChecked(defautValues);
    const { current } = containerRef;
    scroll(current?.offsetTop);

    if (currentCodeObject === 'AUTRE') {
      const dataOtherRequest = t(`customerService.objects.otherRequest`, {
        returnObjects: true
      });

      dispatch(CustomerActions.setRequirementSelected(dataOtherRequest['requirements'][0]));
    }
  }, [requirements]);

  const onChange = (key, requirement) => {
    setChecked((prev) => {
      const newChecked = [...defautValues];
      newChecked[key] = !prev[key];
      return newChecked;
    });
    dispatch(CustomerActions.setRequirementSelected(requirement));
  };

  if (requirements.length === 0) {
    return (
      <div className="row align-items-center mt-4">
        <div className="col-3 offset-2">
          <img src={ContactFormImage} alt="contact-form-img" />
        </div>
        <div className="col-5">
          <SzTypographie color="disabled">
            Sélectionnez un motif de contact pour obtenir un traitement plus
            rapide de votre demande.
          </SzTypographie>
        </div>
      </div>
    );
  }

  if (currentCodeObject === 'AUTRE') {
    return null;
  }

  return (
    <div ref={containerRef} className="row no-gutters cy-requirements">
      <SzTypographie weight="bold" className="col-12 mt-4 text-center">
        {t(`customerService.subtileRequirements`)}
      </SzTypographie>
      <div className="col-12 bg-white py-4 px-4 shadow-sm">
        {requirements.map((item, index) => {
          const key = new Date().getTime() + index;
          
          return (
            <SzCheckbox
              key={key}
              id={key}
              name="radioRequirements"
              label={item.requirement}
              type="radio"
              className={clsx("col-12", {
                "mb-3": index + 1 < requirements.length,
                "cy-requirements-item": true
              })}
              checked={checked[index]}
              onChange={() => onChange(index, item)}
            />
          );
        })}
      </div>
    </div>
  );
}
