import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

const {
  REACT_APP_ENV,
  REACT_APP_GTM_AUTH,
  REACT_APP_GTM_PREVIEW,
  REACT_APP_GTM_ID
} = process.env;

const enabled = !['development', 'inte', 'test'].includes(REACT_APP_ENV);

export default Analytics({
  app: 'tsme-pro',
  plugins: [
    googleTagManager({
      containerId: REACT_APP_GTM_ID,
      preview: REACT_APP_GTM_PREVIEW,
      auth: REACT_APP_GTM_AUTH,
      enabled
    })
  ]
});
