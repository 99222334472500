import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../redux/actions";
import {
  ConnectedContainer,
  ConnectedCustomContainer
} from "../components/Container";

export default function PrivateRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();
  const isValid = useSelector(({ app }: any) => app.token_valid);
  const { tokenCheck } = AppActions;
  localStorage.setItem("targetUrl", rest.location.pathname);

  useEffect(() => {
    if (!isValid) dispatch(tokenCheck());
  }, []);

  if (!isValid) {
    return null;
  }

  const isCustom = rest?.displayType && rest.displayType === "FullScreenNoHeader" 

  const Container =
      isCustom
      ? ConnectedCustomContainer
      : ConnectedContainer;


  return (
    <Route
      {...rest}
      render={(props) => (
        <Container {...rest}>
          <Component {...props} />
        </Container>
      )}
    />
  );
}
