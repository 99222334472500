import React, { useState } from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import BillingCard from "./BillingCard";
import Carousel from "./Carousel";
import ProfileModal from "./ProfileModal";
import Joyride, { Styles } from "react-joyride";

export default function Dashboard() {
  const { t } = useTranslation();
  const [run, setRun] = useState(false);
  const steps = [
    {
      target: ".tsme-header-dropdown > .btn",
      content: t("dashboard.joyride.step_1"),
      disableBeacon: true,
    },
  ];

  const styles: Styles = {
    options: {
      arrowColor: "#C65BAB",
      backgroundColor: "#C65BAB",
      width: 260,
      textColor: "#ffffff",
    },
    tooltip: {
      padding: "0.5rem 1rem",
      borderRadius: 4,
      fontFamily: "DINPro-Regular"
    },
    tooltipContainer: { textAlign: "left" },
    tooltipContent: { padding: "0" },
    buttonClose: { display: "none" },
    buttonNext: { display: "none" },
  };

  return (
    <main>
      <ProfileModal setRun={setRun} />
      <Joyride steps={steps} run={run} styles={styles} />
      <SzTypographie variant="h1" className="mb-3">
        {t("dashboard.title")}
      </SzTypographie>
      <div className="row no-gutters">
        <div className="col-12 col-lg-4 mb-3">
          <BillingCard />
        </div>
        <div className="col-12 col-lg-8 mb-3">
          <Carousel />
        </div>
      </div>
    </main>
  );
}
