import React from 'react';
import { useTranslation } from 'react-i18next';
import { SzButton, SzTypographie } from '@suezenv/react-theme-components';
import EmailSuccess from '../../assets/img/e-mail-success.svg';
import { useAppDispatch } from '../../redux/hooks';
import { CustomerActions } from '../../redux/actions';

export default function Confirmation() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const newRequest = () => {
    dispatch(CustomerActions.reset());
  };
  return (
    <div className="cy-confirmation row no-gutters bg-white shadow-sm px-4 px-md-0">
      <div className="col-12 col-md-5 offset-md-1 py-5">
        <SzTypographie weight="bold" variant="huge">
          {t(`customerService.confirmation.title`)}
        </SzTypographie>

        <SzTypographie
          weight="bold"
          variant="caption"
          size="M"
          color="disabled"
        >
          {t(`customerService.confirmation.desc`)}
        </SzTypographie>

        <div className="d-block d-sm-none text-center">
          <img src={EmailSuccess} alt="EmailSuccess" className="w-75" />
        </div>

        <SzButton
          variant="tertiary"
          className="mt-5"
          icon="messages-bubble-question"
          onClick={newRequest}
        >
          {t(`customerService.confirmation.btnNewRequest`)}
        </SzButton>
      </div>
      <div className="d-none d-md-block col-6 my-auto text-right">
        <img src={EmailSuccess} alt="EmailSuccess" className="w-50 mr-5" />
      </div>
    </div>
  );
}
