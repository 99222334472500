import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SzTable, SzIcon, SzButton } from '@suezenv/react-theme-components';
import { ConsumptionActions } from '../../redux/actions';
import { isMobile } from 'react-device-detect';
import { hideInEnv } from '../../services/Utils';
import { hasRoleEnable, roleList } from '../../services/Role';
import { isTR } from '../../utils/Consumption';

export default function Consumption({ data, item }) {
  const { t } = useTranslation('components');
  const dispatch = useDispatch();
  const { setItem } = ConsumptionActions;
  const selectItem = e => dispatch(setItem(item));
  const { reference, roles } = item;
  const hideInPreprodProd = hideInEnv('prod', 'preprod');
  const columns = [
    {
      dataField: 'matriculeCompteur',
      text: t('table.consumption.matriculeCompteur')
    },
    {
      dataField: 'adresseDesserte',
      text: t('table.consumption.adresseDesserte'),
      headerFormatter: ({ text }) => (
        <span className="d-flex align-items-center">
          <SzIcon icon="maps-pin-1" variant="line" className="mr-2 normal" />
          {text}
        </span>
      )
    },
    !hideInPreprodProd && {
      dataField: 'usage',
      text: t('table.consumption.usage'),
      headerFormatter: ({ text }) => (
        <span className="d-flex align-items-center">
          <SzIcon
            icon="color-drop-pick"
            variant="line"
            className="mr-2 normal"
          />
          {text}
        </span>
      ),
      formatter: value => (
        <span className="d-flex mr-2">
          {t('table.consumption.usage', { context: value })}
        </span>
      )
    },
    {
      dataField: 'codeEquipement',
      text: t('table.consumption.codeEquipement'),
      headerFormatter: ({ text }) => (
        <span className="d-flex align-items-center">
          <SzIcon icon="wifi-signal-2" variant="line" className="mr-2 normal" />
          {text}
        </span>
      ),
      formatter: value => (
        <span className="d-flex mr-2">
          {t('table.consumption.codeEquipement', { context: value })}
        </span>
      )
    },
    !hideInPreprodProd && {
      dataField: 'etatPDS',
      text: t('table.consumption.etatPDS'),
      headerFormatter: ({ text }) => (
        <span className="d-flex align-items-center">
          <SzIcon icon="wifi-signal-2" variant="line" className="mr-2 normal" />
          {text}
        </span>
      )
    },
    {
      dataField: 'details',
      text: t('table.consumption.details', isMobile && { context: 'mobile' }),
      headerFormatter: ({ text }) => (
        <span className="d-flex align-items-center">
          <SzIcon
            icon="analytics-bars-3d"
            variant="line"
            className="mr-2 normal"
          />
          {text}
        </span>
      ),
      formatter: (value, { idPDS, codeEquipement, matriculeCompteur }) => {

        const computedToolCode = isTR(
          codeEquipement,
          hasRoleEnable(roles, 'TELERELEVE')
        )
          ? 'TR'
          : 'SE';

        return (
          <Link
            to={`/espace-consommation/${computedToolCode}/${reference}/${idPDS}`}
            className="d-flex align-items-center"
            id={matriculeCompteur}
            onClick={selectItem}
          >
            <SzButton
              variant="tertiary"
              alignIcon="right"
              icon="keyboard-arrow-right"
            >
              {!isMobile && t('table.consumption.watchDetails')}
            </SzButton>
          </Link>
        );
      }
    }
  ];

  return (
    <SzTable
      keyField="matriculeCompteur"
      className="w-100 m-0"
      data={data}
      columns={columns}
      sticky={isMobile}
    />
  );
}
