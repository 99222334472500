import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../themes';
import clsx from 'clsx';
interface LogoLinkProps {
  className?: string;
}

export const LogoLink: FC<LogoLinkProps> = ({ className }) => {
  const { logo } = useTheme();
  const classes = clsx('tsme-header_logo my-auto px-md-3 px-2 py-3', className);
  return (
    <Link className={classes} to="/">
      <img src={logo} alt="Logo" height={32} />
    </Link>
  );
};

export default LogoLink;
