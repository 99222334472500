import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  SzButton,
  SzSearchBar,
  SzTypographie
} from '@suezenv/react-theme-components';
import { Formik } from 'formik';
import axios from '../../services/Request';
import { InviteModal } from './Modal';
import UserList from './UserList';
import usePagination from '../../hooks/usePagination';
import Skeleton from 'react-loading-skeleton';
import { useAdminServices } from '../../hooks';
import ActionModal from './Modal/Actions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const pageRangeDisplayed = 8;

export type Modals =
  | 'invite'
  | 'delete_user'
  | 'send_invitation'
  | 'update_to_admin';
export type Message =
  | 'invite_success'
  | 'invite_danger'
  | 'delete_success'
  | 'delete_danger'
  | 'update_to_admin_success'
  | 'update_to_admin_danger';
type UserInfos = { userId: string; email: string };

export default function Admin() {
  const { t } = useTranslation();
  const [show, setShow] = useState<Modals | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [adminsFilter, setAdminsFilter] = useState<any[] | null>(null);
  const [userInfos, setUserInfos] = useState<UserInfos | null>();

  const keyActionsModal = ['delete_user', 'send_invitation', 'update_to_admin'];

  const { type } = useSelector(({ app }: any) => app.currentUser);

  const getData = async () => {
    const { data } = await axios.get('/api/management/user/list');
    return data;
  };

  const queryClient = useQueryClient();
  const { data: queryData } = useQuery('admin_manage', getData, {
    initialData: { managed_users: [] },
    refetchOnWindowFocus: false
  });

  const pagination = usePagination(
    adminsFilter ? adminsFilter : queryData.managed_users,
    pageRangeDisplayed
  );

  const { data } = pagination;

  const onSubmit = (form: any) => {
    const { filter_field } = form;
    const toSearch = new RegExp(filter_field, 'gi');

    const adminsFilter = data.filter(
      ({ firstName, lastName, email }) =>
        (email && email.match(toSearch)) ||
        (firstName && firstName.match(toSearch)) ||
        (lastName && lastName.match(toSearch)) ||
        (firstName && lastName && `${firstName} ${lastName}`.match(toSearch))
    );

    setAdminsFilter(adminsFilter);
  };

  const handleClear = () => {
    setAdminsFilter(null);
  };

  const displayModal = (name: Modals) => {
    setShow(name);
  };

  const handleClose = (reset: boolean) => {
    setShow(null);
    if (reset) {
      queryClient.invalidateQueries('admin_manage');
    }
  };
  const count = (adminsFilter || queryData.managed_users).length;

  const [showMessage, setMessage] = useState<Message | null>(null);
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const onClose = () => {
    setMessage(null);
  };

  const onClickDelete = async () => {
    const { userId } = userInfos || {};
    setLoader(true);
    try {
      await axios.delete(`/api/management/user/${userId}`);
      queryClient.invalidateQueries('admin_manage');
      setLoader(false);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
        setMessage(null);
      }
      handleClose(true);

      setMessage('delete_success');
    } catch ({ response }) {
      handleClose(false);
      setLoader(false);
      setMessage('delete_danger');
    }
    const newTimeoutId = setTimeout(() => {
      setMessage(null);
    }, 5000);
    setTimeoutId(newTimeoutId);
  };

  const onClickSend = async () => {
    const { email } = userInfos || {};
    setLoader(true);
    try {
      await axios.post('/api/management/user/invitation', { email });
      queryClient.invalidateQueries('admin_manage');
      setLoader(false);
      handleClose(true);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
        setMessage(null);
      }
      setMessage('invite_success');
    } catch (error) {
      handleClose(false);
      setLoader(false);
      setMessage('invite_danger');
    }
    const newTimeoutId = setTimeout(() => {
      setMessage(null);
    }, 5000);
    setTimeoutId(newTimeoutId);
  };

  const onClickUpdateToAdmin = async () => {
    const { userId } = userInfos || {};

    setLoader(true);
    try {
      await axios.post('/api/management/user/type', { type: 'admin', userId });
      queryClient.invalidateQueries('admin_manage');
      setLoader(false);
      handleClose(true);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
        setMessage(null);
      }
      setMessage('update_to_admin_success');
    } catch (error) {
      handleClose(false);
      setLoader(false);
      setMessage('update_to_admin_danger');
    }
    const newTimeoutId = setTimeout(() => {
      setMessage(null);
    }, 5000);
    setTimeoutId(newTimeoutId);
  };

  const onSubmitModal = {
    see_profile: '',
    delete_user: onClickDelete,
    update_to_admin: onClickUpdateToAdmin,
    update_to_simple_user: '',
    send_invitation: onClickSend,
    leave_ownership: ''
  };

  const history = useHistory();

  const allowTypes = ['owner', 'admin', 'internal_adviser_admin'];

  if (type && !allowTypes.includes(type)) {
    history.push('/');
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">
          <SzTypographie variant="h1">{t('adminSpace.title')}</SzTypographie>
          <SzTypographie color="disabled">
            {t('adminSpace.subtitle', { count })}
          </SzTypographie>
        </div>
        <div className="col-12 col-md-4">
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue }) => {
              const onClick = (inputValue) => {
                setFieldValue('filter_field', inputValue);
              };

              return (
                <form className="tsme-search-form" onSubmit={handleSubmit}>
                  <SzSearchBar
                    placeholder="Rechercher un collaborateur"
                    onClick={onClick}
                    handleClear={handleClear}
                    icon
                  />
                </form>
              );
            }}
          </Formik>
        </div>
        <div className="col-12 col-md-2 text-right my-3 my-md-0">
          <SzButton
            className="btn-admin-modal"
            icon="add-circle"
            onClick={() => displayModal('invite')}
          >
            Ajouter
          </SzButton>
        </div>
      </div>

      {queryData.code ? (
        <>
          <UserList
            {...pagination}
            userInfos={userInfos}
            setUserInfos={setUserInfos}
            displayModal={displayModal}
            showMessage={showMessage}
            onClose={onClose}
          />
          <InviteModal show={show === 'invite'} handleClose={handleClose} />

          <ActionModal
            type={show}
            loader={loader}
            onClick={show && onSubmitModal[show]}
            userInfos={userInfos}
            show={show && keyActionsModal.includes(show)}
            handleClose={handleClose}
          />
        </>
      ) : (
        <div
          style={{ fontSize: 40, lineHeight: 1.4 }}
          className="border p-5 bg-white"
        >
          <Skeleton count={10} duration={2} />
        </div>
      )}
    </div>
  );
}
