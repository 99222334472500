import * as Yup from "yup";

export const AssistanceModel = {
  location: {
    name: "location",
    schema: Yup.string(),
  },
  address: {
    name: "address",
    schema: Yup.string(),
  },
};
