import React, { useState, useEffect } from "react";
import {
  SzDatePicker,
  SzChart,
  SzCheckbox,
  SzSpinner,
  SzButton,
  SzTypographie,
  SzIcon,
} from "@suezenv/react-theme-components";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { ConsumptionActions } from "../../redux/actions";
import { useParams } from "react-router-dom";
import "./counter.scss";
import { useTranslation } from "react-i18next";
import ExportModalTR from "./exportModalTR";
import useHistogram from "../../hooks/useHistogram";
import { releveeSort } from "../../utils/Consumption";

export default function HistogramTr() {
  const dispatch = useDispatch();
  const { t } = useTranslation("components");
  const [startDate, setStartDate] = useState(new Date());
  const [checkedDaily, setValue] = useState(false);
  const [checkedMonthly, setValueMonthly] = useState(true);
  const { mode, error, loading } = useSelector(
    ({ consumption }) => consumption
  );
  const { reference, idPDS } = useParams();
  const { FetchTelemetry } = ConsumptionActions;
  const [show, setShow] = useState(false);

  const releveeSortLastYear = ({ volume, date, index }) => {
    const formatedDate = moment(date).format("MM-YYYY");
    const formatedStartDate = moment(startDate)
      .subtract(1, "years")
      .format("MM-YYYY");

    if (formatedDate === formatedStartDate) {
      return {
        y: volume,
        name: date,
        lastIndex: index,
      };
    }
  };

  const onChangeStartDate = (date) => {
    setStartDate(date);
    const start_date = moment(date).startOf("month").format("YYYY-MM-DD");
    const end_date = moment(date).endOf("month").format("YYYY-MM-DD");
    dispatch(
      FetchTelemetry({
        reference,
        id_PDS: idPDS,
        mode,
        end_date,
        start_date,
      })
    );
  };

  const onChange = ({ target }) => {
    const { value, checked, name } = target;
    const isDaily = name === "daily";
    const isMonthly = name === "monthly";
    const format = "YYYY-MM-DD";

    const start_date_monthly = moment().subtract(2, "years").format(format);
    const start_date_daily = moment(startDate).startOf("month").format(format);
    const start_date = isMonthly ? start_date_monthly : start_date_daily;

    const end_date_daily = moment(startDate).endOf("month").format(format);
    const end_date_monthly = moment().format(format);
    const end_date = isMonthly ? end_date_monthly : end_date_daily;

    const data = {
      reference,
      id_PDS: idPDS,
      mode: value,
      end_date,
    };
    if (isDaily) data.start_date = start_date;

    setValue(isDaily ? checked : false);
    setValueMonthly(isMonthly ? checked : false);
    dispatch(FetchTelemetry(data));
  };

  const categoriesSort = ({ date }) => date && moment(date).format("MMM");

  const [dataListRelevee, dataListLastYear, categoriesList] = useSelector(
    ({ consumption }) => {
      const measures = consumption.measures.filter(({ date }) => date);

      const relevee_monthly_list = measures.slice(13).map(releveeSort);
      const relevee_daily_list = measures
        .slice(0, moment(startDate).daysInMonth())
        .map(releveeSort);

      const last_year_monthly = measures.slice(1, 13).map(releveeSort);
      const last_year_daily = measures
        .filter(releveeSortLastYear)
        .map(releveeSortLastYear);

      const daysInMonth = Array(moment(startDate).daysInMonth());
      const category_list_daily = Array.from(daysInMonth, (_, i) => i + 1);
      const category_list_monthly = measures.slice(13).map(categoriesSort);

      const dataListRelevee =
        mode === "daily" ? relevee_daily_list : relevee_monthly_list;
      const dataListLastYear =
        mode === "daily" ? last_year_daily : last_year_monthly;
      const categoriesList =
        mode === "daily" ? category_list_daily : category_list_monthly;
      return [dataListRelevee, dataListLastYear, categoriesList];
    }
  );
  const minDate = new Date();
  minDate.setMonth(minDate.getMonth() - 24);
  const SelectMonth = () => (
    <SzDatePicker
      startDate={startDate}
      onChangeStartDate={onChangeStartDate}
      maxDetail="year"
      format="MM/yyyy"
      classNameStartDate="w-100 ml-3"
      startMinDate={minDate}
      maxDate={new Date()}
    />
  );

  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const SelectMode = () => (
    <div className="d-flex">
      <SzCheckbox
        id="monthly"
        name="monthly"
        label="Année en cours"
        value="monthly"
        type="radio"
        checked={checkedMonthly}
        onChange={onChange}
        className="mx-2"
      />
      <SzCheckbox
        id="daily"
        name="daily"
        label="Mois"
        value="daily"
        type="radio"
        checked={checkedDaily}
        onChange={onChange}
        className="mx-2"
      />
    </div>
  );

  const seriesData = [
    {
      name: t("consumption.histogram.seriesLastYear"),
      data: loading ? [] : dataListLastYear,
      color: "#2E88FF",
      pointPadding: 0,
    },
    {
      name: t("consumption.histogram.seriesConso"),
      data: loading ? [] : dataListRelevee,
      color: "#84DFFF",
      pointPadding: 0,
    },
  ];

  const hookOption = {
    chart: {
      events: {
        render: function () {
          const { series } = this;
          const { data } = series.length > 1 ? series[1] : series[0];

          const myData = data.map(({ y }) => y);
          const reducer = (a, b) => a + b;
          const sumValue = myData.reduce(reducer, 0);
          const options = {
            text: t("consumption.histogram.total", {
              value: sumValue > 0 ? parseFloat(sumValue).toFixed(3) : 0,
            }),
          };
          this.setTitle(options, false, false);
        },
      },
    },
    tooltip: {
      formatter: function () {
        const format = mode === "monthly" ? "MM-YYYY" : "DD-MM-YYYY";
        const options = {
          date: moment(this.point.name).format(format),
          name: this.series.name,
          y: this.y,
          lastIndex: this.point.lastIndex,
        };
        return t("consumption.histogram.tooltip", options);
      },
    },
    xAxis: {
      categories: categoriesList,
    },
    series: mode === "daily" ? seriesData[1] : seriesData,
  };

  const options = useHistogram(hookOption);

  return (
    <>
      <div className="period-selector row no-gutters justify-content-center align-items-center bg-white mb-3 shadow-sm py-2 px-3">
        <SzTypographie className="mb-0 mx-3" color="inactive" weight="bold">
          {t("consumption.histogram.select_period")}
        </SzTypographie>
        <SelectMode />
        {checkedDaily && (
          <div className="col-3 d-block ml-3">
            <SelectMonth />
          </div>
        )}
        <SzButton className="ml-auto" onClick={handleOpen} variant="tertiary">
          <SzIcon icon="office-file-xls-1" variant="bold" />
        </SzButton>
      </div>
      <div className="shadow-sm bg-white p-3 position-relative">
        {error && (
          <div className="position-absolute chart-error text-center d-flex">
            <div className="justify-content-center align-items-center row">
              {error}
            </div>
          </div>
        )}
        {loading && (
          <div className="position-absolute chart-error text-center d-flex">
            <div className="justify-content-center align-items-center row">
              <SzSpinner variant={"secondary"} />
            </div>
          </div>
        )}
        <SzChart options={options} />
      </div>
      {show && <ExportModalTR show={show} handleClose={handleClose} />}
    </>
  );
}
