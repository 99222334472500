import React from 'react';
import { Link } from 'react-router-dom';
import { SzCarousel, SzTypographie, SzButton } from '@suezenv/react-theme-components';

export default function Carousel() {
  // const description =
  //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vitae lorem ac dolor sollicitudin porta. Donec vel felis laoreet, venenatis arcu in, tristique ligula. Morbi a elit est. Vestibulum ultrices euismod bibendum. Duis maximus justo metus, sit amet cursus ipsum vestibulum non. Duis aliquet placerat vehicula. Curabitur venenatis gravida sapien.';
  const label = 'En savoir plus';

  const images = [
    {
      id: 'switch.jpg',
      title: 'ON’connect™ switch',
      caption: 'Pilotez à distance l’alimentation en eau de vos bâtiments et maitrisez vos dépenses.',
      type: 'switch'
    },
    {
      id: 'fluids.jpg',
      title: 'ON’connect™ fluids',
      caption: 'Suivez vos consommations d’eau et d’énergie sur un seul et même outil et améliorez la performance environnementale de vos bâtiments.',
      type: 'fluids'
    }
  ];

  const data = images.map(({ id, title, type, caption }) => ({
    title,
    className: 'px-5 py-4 d-flex flex-column justify-content-center',
    src: `/img/dashboard/${id}`,
    to: `/espace-produit/${type}`,
    type,
    caption,
    alt: title
  }));

  return (
    <SzCarousel itemsToShow={1} showArrows={false}>
      {data.map(({ title, to, type, caption, ...props }, key) => (
        <SzCarousel.SzFrame key={key} {...props} carouselHeight={'300'}>
          <SzTypographie color="light" weight="bold">
            {title}
          </SzTypographie>
          <SzTypographie color="light" weight="regular">
            {caption}
          </SzTypographie>
          {to && (
            <Link to={to}>
              <SzButton
                variant="tertiary"
                className={`btn-product-${type} mr-auto border text-white`}
                icon="keyboard-arrow-right"
                alignIcon="right"
              >
                {label}
              </SzButton>
            </Link>
          )}
        </SzCarousel.SzFrame>
      ))}
    </SzCarousel>
  );
}
