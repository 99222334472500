import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export type UserType =
  | "CB"
  | "CONSULT_CONSO"
  | "INFOS_CONTRAT"
  | "RIB"
  | "FACTURE"
  | "MENSUALISATION"
  | "PRELEVEMENT"
  | "TELERELEVE"
  | "DEPOSE_RELEVE"
  | "ETIP"
  | "TIPI"
  | "ADD_CONTRACT"
  | "REMOVE_CONTRACT"
  | "FUITE"
  | "SURCONSO"
  | "GRAND_COMPTE";

export default function useAdminServices(
  userType: UserType,
  returnTo?: string
) {
  const history = useHistory();
  const [allowed, setAllowed] = useState(false);
  const [allowed_services, isLoading] = useSelector(({ app }: any) => [
    app.currentUser.allowed_services,
    app.loading,
  ]);

  useEffect(() => {
    if (!allowed_services.includes(userType) && !isLoading && returnTo) {
      history.push(returnTo);
    }
    if (allowed_services.includes(userType) && !isLoading) {
      setAllowed(true);
    }
  }, [allowed_services, isLoading]);

  return allowed;
}
