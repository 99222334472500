import React from 'react';
import { SzButton } from '@suezenv/react-theme-components';
import Error from '../../components/Error/ErrorModel';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

export default function NoConnection(props) {
  const { t } = useTranslation('components');
  const history = useHistory();
  return (
    <div className="bg-white h-100 pt-5">
      <Error title={t('errors.serverError')} errorType={'server-error'}>
        <Link to="/">
          <SzButton onClick={() => history.goBack()} variant="secondary">
            {t('errors.tryAgain')}
          </SzButton>
        </Link>
      </Error>
    </div>
  );
}
