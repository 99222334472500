import { CONSUMPTION } from '../types';
export const defaultSearched = {
  currentPage: 1,
  itemsPerPage: 5
};
const defaultList = {
  nbCompteurTotal: 0,
  nombreCompteurRr: 0,
  nombreCompteurSe: 0,
  nombreCompteurTr: 0
};
export const initialState = {
  memoryList: defaultList,
  item: { compteursPro: [{}] },
  currentPage: 1,
  totalPage: 1,
  nbCodeRefFull: 0,
  nbCompteurFull: 0,
  nbMeters: 0,
  clientCompteursPro: [],
  form: {
    searched: defaultSearched,
    loading: false,
    error: null
  },
  measures: [],
  measurescsv: [],
  mode: 'monthly',
  exportDone: false
};

export default function consumption(state = { ...initialState }, action) {
  const {
    type,
    list,
    item,
    error,
    nbMeters,
    nbCodeRefFull,
    nbCompteurFull,
    clientCompteursPro,
    searched,
    totalPage,
    currentPage,
    measures,
    measurescsv,
    mode,
    exportDone
  } = action;
  switch (type) {
    case CONSUMPTION.LIST.SET:
      return { ...state, list };
    case CONSUMPTION.LIST.MEMORY:
      return { ...state, memoryList: list };
    case CONSUMPTION.ITEM.SET:
      return { ...state, item };
    case CONSUMPTION.LOADING.START:
      return { ...state, loading: true };
    case CONSUMPTION.LOADING.STOP:
      return { ...state, loading: false };
    case CONSUMPTION.ERROR.SET:
      return { ...state, error };
    case CONSUMPTION.ERROR.RESET:
      return { ...state, error: null };
    case CONSUMPTION.FORM.LOADING.START:
      return { ...state, form: { ...state.form, loading: true } };
    case CONSUMPTION.FORM.LOADING.STOP:
      return { ...state, form: { ...state.form, loading: false } };
    case CONSUMPTION.FORM.ERROR.SET:
      return { ...state, form: { ...state.form, error } };
    case CONSUMPTION.FORM.ERROR.RESET:
      return { ...state, form: { ...state.form, error: null } };
    case CONSUMPTION.FACTURES.SET:
      return { ...state, clientCompteursPro };
    case CONSUMPTION.NB_METERS.SET:
      return { ...state, nbMeters };
    case CONSUMPTION.CODEREF.SET:
      return { ...state, nbCodeRefFull };
    case CONSUMPTION.COMPTEUR.SET:
      return { ...state, nbCompteurFull };
    case CONSUMPTION.FORM.SEARCHED.SET:
      return { ...state, form: { ...state.form, searched } };
    case CONSUMPTION.FORM.SEARCHED.RESET:
      return { ...state, form: { ...state.form, searched: {} } };
    case CONSUMPTION.CURRENT_PAGE.SET:
      return { ...state, currentPage };
    case CONSUMPTION.TOTAL_PAGE.SET:
      return { ...state, totalPage };
    case CONSUMPTION.MEASURES.SET:
      return { ...state, measures };
    case CONSUMPTION.MEASURESCSV.SET:
      return { ...state, measurescsv };
    case CONSUMPTION.MODE.SET:
      return { ...state, mode };
    case CONSUMPTION.EXPORTDONE.SET:
      return { ...state, exportDone };
    default:
      return state;
  }
}
