import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SzModal,
  SzButton,
  SzDateRangePicker,
  SzAlert,
  SzTypographie,
} from "@suezenv/react-theme-components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchNonTelemetry,
  setExportDone,
} from "../../redux/actions/consumption";
import { useParams } from "react-router-dom";

export const ExportModal = (props) => {
  const { t } = useTranslation("components");
  const title = t("consumption.histogram.modal.title");

  const now = new Date();
  const defaultMinDate = new Date(now.setMonth(now.getMonth() - 24));
  const [currentDate, setCurrentDate] = useState(new Date());
  const [minDate, setMinDate] = useState(defaultMinDate);
  const [dates, setDates] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dates;

  useEffect(() => {
    return () => {
      dispatch(setExportDone(false));
    };
  }, []);

  const onChange = (value) => {
    setDates(value);
  };
  const dispatch = useDispatch();
  const { reference, idPDS } = useParams();

  const {
    error,
    loading,
    measures,
    measurescsv,
    clientCompteursPro,
    exportDone,
  } = useSelector(({ consumption }) => consumption);

  const onClick = (e) => {
    const defaultData = {
      reference,
      id_PDS: idPDS,
    };
    const currentConpteurPro = clientCompteursPro.filter(
      (x) => x.reference === reference
    );
    const list = currentConpteurPro[0] || { compteursPro: [{}] };

    const contractToExport = {
      ...list.compteursPro[0],
      reference,
    };
    const data = {
      ...defaultData,
      end_date: moment(endDate).format("YYYY-MM-DD"),
      start_date: moment(startDate).format("YYYY-MM-DD"),
    };
    dispatch(FetchNonTelemetry(data, contractToExport));
  };

  return (
    <SzModal title={title} size="xl" {...props} className="mx-3 ">
      <div className="row">
        <div className="col-12 col-lg-7">
          <SzDateRangePicker
            value={dates}
            onChange={onChange}
            minDate={minDate}
            selectRange
          />
        </div>
        <div className="col-12 col-lg-5 d-flex flex-column pt-4">
          <SzAlert
            variant="info"
            transparent
            className="align-items-start mt-xs-5"
          >
            <SzTypographie variant="body" weight="bold" className="py-2 m-0">
              {t("consumption.histogram.modal.info_period", {
                context: "monthly",
              })}
            </SzTypographie>
          </SzAlert>
          <SzTypographie variant="body" className="mt-auto">
            {t("consumption.histogram.modal.export_period", {
              startDate: moment(startDate).format("DD/MM/YYYY"),
              endDate: moment(endDate).format("DD/MM/YYYY"),
            })}
          </SzTypographie>
          <SzButton
            onClick={onClick}
            loader={loading}
            className="mr-auto mb-3"
            icon="common-file-text-download"
          >
            {t("consumption.histogram.modal.button")}
          </SzButton>
          {error && (
            <SzAlert className="mt-4">
              <SzTypographie variant="body" weight="bold" className="py-2 m-0">
                {error}
              </SzTypographie>
            </SzAlert>
          )}
          {exportDone && (
            <SzAlert variant="success" className="mt-4">
              <SzTypographie variant="body" weight="bold" className="py-2 m-0">
                {t("consumption.histogram.modal.export_done")}
              </SzTypographie>
            </SzAlert>
          )}
        </div>
      </div>
    </SzModal>
  );
};

export default ExportModal;
