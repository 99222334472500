import * as Yup from 'yup';

Yup.string.prototype.isPassword = function(message) {
  return this.test('isPassword', message, function(value) {
    const small = /[a-z]/.test(value);
    const upper = /[A-Z]/.test(value);
    const digit = /[\d]/.test(value);
    const other = /[\W]/.test(value);
    return small && upper && digit && other;
  });
};

Yup.string.prototype.match = function(field, message) {
  return this.test('match', message, function(value) {
    if (this.parent) {
      return this.parent[field] === value;
    }
  });
};

Yup.string.prototype.isRef = function(message) {
  return this.test('reference', message, function(value) {
    const isLength = value && value.replace(/(,|-|\.)/gi, '').length === 12;
    const startW98 = /^98/.test(value);
    return isLength && startW98;
  });
};
