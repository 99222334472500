import * as Yup from 'yup';
const phoneMatch = /^(0)([0-9 ]{9}$)/;

export default {
  last_name: {
    label: 'models:profile.last_name.label',
    placeholder: 'models:productSpace.last_name.placeholder',
    type: 'text',
    icon: 'pencil-write',
    schema: Yup.string()
      .matches(/^\D+$/, 'models:profile.last_name.match')
      .required('models:profile.last_name.required')
  },
  first_name: {
    label: 'models:profile.first_name.label',
    type: 'text',
    required: true,
    icon: 'pencil-write',
    placeholder: 'models:productSpace.first_name.placeholder',
    schema: Yup.string()
      .min(1, 'models:profile.first_name.required')
      .matches(/^\D+$/, 'models:profile.first_name.match')
      .required('models:profile.first_name.required')
  },
  email: {
    label: 'models:profile.email.label',
    placeholder: 'models:productSpace.email.placeholder',
    type: 'email',
    icon: 'envelope',
    schema: Yup.string()
      .min(1, 'models:profile.repeated_email.required')
      .email('models:profile.email.error')
      .required('models:profile.email.required')
  },
  phone: {
    className: 'col-5 pr-md-3 align-top',
    label: 'models:productSpace.phone.label',
    placeholder: 'models:productSpace.phone.placeholder',
    type: 'text',
    icon: 'phone-actions-voice-mail',
    schema: Yup.lazy((value) => {
      if (!value) return Yup.string();

      return Yup.string().matches(
        phoneMatch,
        'models:productSpace.phone.error'
      );
    })
  },
  company: {
    className: 'col-5 pr-md-3 align-top',
    label: 'models:productSpace.company.label',
    placeholder: 'models:productSpace.company.placeholder',
    type: 'text',
    icon: 'products-briefcase',
    schema: Yup.string()
      .min(1, 'models:profile.first_name.required')
      .required('models:productSpace.company.required')
  },
  function: {
    className: 'col-5 pr-md-3 align-top',
    label: 'models:productSpace.function.label',
    placeholder: 'models:productSpace.function.placeholder',
    type: 'text',
    icon: 'single-neutral-actions-information',
    schema: Yup.string()
      .min(1, 'models:profile.first_name.required')
      .required('models:productSpace.function.required')
  },
  reference: {
    className: 'col-5 pr-md-3 align-top',
    label: 'models:productSpace.reference.label',
    placeholder: 'models:productSpace.reference.placeholder',
    type: 'text',
    icon: 'business-contract-handshake-sign',
    schema: Yup.string()
      .max(12, 'models:productSpace.reference.error')
      .matches(/^$|^98([0-9]{10}$)/, 'models:productSpace.reference.error')
  },
  subject: {
    className: 'col-12 pt-3',
    placeholder: 'models:productSpace.subject.placeholder',
    schema: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().nullable(),
          label: Yup.string().nullable()
        })
      )
      .min(1, 'models:productSpace.subject.required'),
    options: [
      {
        label: "Demande d'information sur ON’connect™ switch",
        value: "ON'connect switch"
      },
      {
        label: "Demande d'information sur ON'connect™ fluids",
        value: "ON'connect fluids"
      },
      {
        label: "Demande d'information sur SUEZ Water Data Transfer",
        value: 'SUEZ Water Data Transfer'
      }
    ]
  },
  message: {
    label: 'models:productSpace.reference.label',
    type: 'textarea'
  }
};
