interface ProductButton {
  icon: string;
  to: string;
  title: string;
  id: string;
}

const WATER_TYPE = 'water';

const resolveProducts = (allButtons: ProductButton[], activeType: string) => {
  // Two uses cases:
  // 1) we're viewing 'water' type, so we want to see water type and only water type
  // 2) we're viewing something else, we want to see everything *excpet* water type

  switch (activeType) {
    case WATER_TYPE:
      return allButtons.filter(
        (button: ProductButton) => button.id === WATER_TYPE
      );
    default:
      return allButtons.filter(
        (button: ProductButton) => button.id !== WATER_TYPE
      );
  }
};

export default resolveProducts;
