export default {
  CONTACT_REQUEST: 'CONTACT_REQUEST',
  SHOW_SUCCESS: 'SHOW_SUCCESS',
  HIDE_SUCCESS: 'HIDE_SUCCESS',
  SHOW_FORM: 'SHOW_FORM',
  HIDE_FORM: 'HIDE_FORM'
};

enum Information {
  switch,
  water,
  fluids,
}

export interface ContactRequestPayload {
    contact_type: string,
    first_name: string,
    last_name: string,
    email: string,
    phone: string|null,
    company: string,
    position: string,
    reference: string,
    subject: Information[],
    message: string|null
}

export interface ContactRequestAction {
  type: string
  payload: ContactRequestPayload
}

export interface ContactSuccessPayload {
    show:  boolean
}

export interface ContactSuccessAction {
  type: string
  payload: ContactSuccessPayload
}

export interface ContactFormPayload {
    show:  boolean
}

export interface ContactFormAction {
  type: string
  payload: ContactSuccessPayload
}

export type ProductActionTypes = ContactRequestAction | ContactSuccessAction | ContactFormAction