import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SzTypographie, SzStepper } from "@suezenv/react-theme-components";
import { SignupActions } from "../../redux/actions";
import SignUpForm from "../../forms/Signup";
import AddContractForm from "../../forms/ContractForm/AddContractForm";
import { useTranslation } from "react-i18next";
import "./signup.scss";

export default function Signup(props) {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();
  const activeStep = useSelector(({ signup }) => signup.step);
  const { signupNextStep } = SignupActions;

  useEffect(() => {
    localStorage.getItem("token") && dispatch(signupNextStep());
  }, []);

  const header = [
    t("signup.step.header.first"),
    t("signup.step.header.second"),
  ];
  return (
    <div className="tsme-form m-auto">
      <SzTypographie variant="h1">{t("signup.title")}</SzTypographie>
      <div className="tsme-stepper-container bg-white">
        <SzStepper variant="empty" header={header} activeStep={activeStep}>
          <SzStepper.SzHeader />
          <SzStepper.SzStep index={0}>
            <SzTypographie variant="h2" weight="bold" className="mb-3">
              {t("signup.step.title.first")}
            </SzTypographie>
            <SignUpForm />
          </SzStepper.SzStep>
          <SzStepper.SzStep index={1}>
            <SzTypographie variant="h2" weight="bold" className="mb-3">
              {t("signup.step.title.second")}
            </SzTypographie>
            <SzTypographie className="mb-4">
              {t("signup.step.content")}
            </SzTypographie>
            <AddContractForm />
          </SzStepper.SzStep>
        </SzStepper>
      </div>
    </div>
  );
}
