import React, { useEffect, useRef, useState } from 'react';
import { SzButton, SzTypographie } from '@suezenv/react-theme-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';

export const SearchContainer = ({ children, error }) => {
  const containerRef = useRef();
  const { t } = useTranslation('components');
  const [toggle, setToggle] = useState(true);
  const [height, setHeight] = useState(0);
  const [alertHeight, setAlertHeight] = useState(0);

  const [styles, animate] = useSpring(() => ({
    from: { position: 'relative', opacity: 0 }
  }));

  useEffect(() => {
    const ref = containerRef.current;
    const errorAlert = document.querySelector('.tsme-search-form .sz-alert');

    const newHeight = error
      ? errorAlert.parentElement.offsetHeight + ref.clientHeight
      : ref.clientHeight - alertHeight;

    if (errorAlert) {
      setAlertHeight(errorAlert.parentElement.offsetHeight);
    }
    if (ref) {
      setHeight((prev) => {
        animate({
          from: {
            position: 'relative',
            opacity: 1,
            height: newHeight
          },
          to: {
            position: 'relative',
            opacity: 1,
            height: newHeight
          },
          reset: true
        });
        return newHeight;
      });
    }
  }, [containerRef, error]);

  const onClick = () => {
    setToggle((prev) => {
      animate.start({
        position: 'relative',
        opacity: prev ? 0 : 1,
        height: prev ? 0 : height
      });
      return !prev;
    });
  };

  const animatedDivClasses = classnames('border-top mb-3', {
    'py-2 pb-2': toggle
  });

  const iconBtn = classnames({
    'view-off': toggle,
    'view-1': !toggle
  });

  return (
    <div className="bg-white px-4">
      <div className="d-flex align-items-center py-1">
        <SzTypographie
          variant="h2"
          tag="span"
          className="text-placeholder mt-1"
          weight="bold"
        >
          {t('searchBlock.title')}
        </SzTypographie>
        <SzButton
          className="ml-auto"
          variant="tertiary"
          icon={iconBtn}
          onClick={onClick}
          alignIcon="right"
        >
          {t('searchBlock.display', { context: toggle ? 'hide' : 'show' })}
        </SzButton>
      </div>
      <div className="tsme-search-form-container" ref={containerRef}>
        <animated.div style={{ ...styles }} className={animatedDivClasses}>
          {toggle && children}
        </animated.div>
      </div>
    </div>
  );
};
