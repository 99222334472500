import * as Yup from 'yup';

const values = ['A', 'C', 'I', 'N', 'T', 'V'];
const options = values.map(value => ({
  label: `models:searchBlock.usage.${value}`,
  value
}));

const reference = {
  placeholder: 'models:searchBlock.reference.placeholder',
  icon: 'single-neutral-id-card-3',
  name: 'reference',
  info: 'models:searchBlock.reference.info',
  type: 'text',
  schema: Yup.string()
};

const siteAddress = {
  placeholder: 'models:searchBlock.siteAddress.placeholder',
  icon: 'maps-pin-1',
  name: 'siteAddress',
  type: 'text',
  schema: Yup.string()
};

export const conso = {
  reference,
  matricule: {
    placeholder: 'models:searchBlock.matricule.placeholder',
    icon: 'gauge-dashboard-1',
    name: 'matricule',
    type: 'text',
    info: 'models:searchBlock.matricule.info',
    schema: Yup.string().max(16, '16 caratères maximum')
  },
  siteAddress,
  // typeUsage: {
  //   name: 'typeUsage',
  //   placeholder: "Type d'usage",
  //   options,
  //   schema: Yup.string()
  // }
};

export const billing = {
  reference,
  invoiceNumber: {
    placeholder: 'models:searchBlock.invoiceNumber.placeholder',
    icon: 'common-file-text',
    name: 'invoiceNumber',
    type: 'text',
    info: 'models:searchBlock.invoiceNumber.info',
    schema: Yup.string()
  },
  siteAddress,
  amount: {
    placeholder: 'models:searchBlock.amount.placeholder',
    icon: 'currency-euro-circle',
    name: 'amount',
    type: 'text',
    info: 'models:searchBlock.amount.info',
    schema: Yup.string()
  },
  dates: Yup.array(Yup.date(), Yup.date())
};
