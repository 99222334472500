import Review from '../pages/Review';
import Logout from '../pages/Logout';
import Signup from '../pages/Signup';
import Dashboard from '../pages/Dashboard';
import Authenticate from '../services/Authenticate';
import Payment from '../pages/PaymentArea';
import NoConnection from '../pages/Errors/NoConnection';
import Redirection from '../pages/Errors/Redirection';
import NotFound from '../pages/Errors/NotFound';
import Product from '../pages/Product';
import Cgu from '../pages/Cgu';
import LegalNotice from '../pages/LegalNotice';

import Billing from '../pages/Billing';
// import DebitCheck from '../pages/Billing/debitCheck';
// import DebitModal from '../components/Modal/Billing/Debit';

import Consumption from '../pages/Consumption';
import Details from '../pages/Consumption/Details';
import TechSheetModal from '../components/Modal/Consumption/TechSheet';
import Admin from '../pages/Admin';
import CustomerService from '../pages/CustomerService';
import { InitPaymentAuto } from '../pages/PaymentAuto/InitPaymentAuto';
import { ConfirmationPage } from '../pages/PaymentAuto/ConfirmationPage';
import { DownloadMandat } from '../pages/PaymentAuto/DownloadMandat';

const exact = true;

export const privateRoutes = [
    {
      path: '/',
      component: Dashboard,
      exact
    },
    {
      path: '/tableau-de-bord',
      component: Dashboard,
      exact
    },
    {
      path: '/download/contract/mandate/:reference/:type',
      component: DownloadMandat
    },
    {
      path: '/renseignement-profil',
      component: Dashboard,
      exact
    },
    {
      path: '/espace-facturation',
      component: Billing
    },
    {
      path: '/espace-consommation',
      component: Consumption,
      exact
    },
    {
      path: '/espace-consommation/:codeEquipement/:reference/:idPDS',
      component: Details,
      exact
    },
    {
      path:
        '/espace-consommation/:codeEquipement/:reference/:idPDS/fiche-technique',
      component: TechSheetModal
    },
    {
      path: '/espace-personnel',
      component: Review
    },
    {
      path: '/espace-de-paiement',
      component: Payment,
      exact
    },
    {
      path: '/espace-admin',
      component: Admin,
      exact
    },
    {
      path: '/service-client',
      component: CustomerService,
      displayType: "FullScreen",
      title: 'customerService',
      exact
    },
    {
      path: '/prelevement-automatique',
      component: InitPaymentAuto,
      displayType: "FullScreenNoHeader",
      title: 'paymentAuto',
      exact
    },
    {
      path: '/prelevement-automatique/:status?/:fullRef?/:transactionid?',
      component: ConfirmationPage,
      displayType: "FullScreenNoHeader",
      title: 'paymentAuto',
      exact
    }
  ];

  export const publicRoutes = [
    { path: '/logout', component: Logout, exact },
    { path: '/creation-de-compte', component: Signup, exact },
    { path: '/authenticate', component: Authenticate, exact },
    { path: '/cgu', component: Cgu, exact },
    { path: '/mentions-legales', component: LegalNotice, exact },
    { path: '/espace-produit/:type?', component: Product },
    {
      path: '/erreur/connexion',
      component: NoConnection
    },
    { path: '/erreur/redirection', component: Redirection, exact },
    { component: NotFound }
  ];
