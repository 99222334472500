import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: ['pages', 'models', 'components'],
    defaultNS: 'pages',
    lng: 'fr',
    fallbackLng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      requestOptions: {
          cache: 'no-store',
      },
    }
  });

const t = i18next.t.bind(i18next);
export { t };
export default i18next;
