import React, { useEffect, useState } from "react";
import {
  SzModal,
  SzButton,
  SzAlert,
  SzTypographie,
  SzRadioButton,
  SzDateRangePicker,
} from "@suezenv/react-theme-components";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { FetchTelemetry, setExportDone } from "../../redux/actions/consumption";
import { useTranslation } from "react-i18next";

export const ExportModalTR = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("components");
  const { reference, idPDS } = useParams();
  const { error, loading, clientCompteursPro, exportDone } = useSelector(
    ({ consumption }) => consumption
  );

  useEffect(() => {
    return () => {
      dispatch(setExportDone(false));
    };
  }, []);

  const choices = ["monthly", "daily"].map((type) => ({
    label: t("consumption.histogram.modal.select_period", {
      context: type,
    }),
    value: type,
  }));

  const defaultData = {
    reference,
    id_PDS: idPDS,
  };

  const now = new Date();
  const defaultMinDate = new Date(now.setMonth(now.getMonth() - 24));
  const [selecting, setSelecting] = useState(false);
  const [maxDate, setMaxdate] = useState(new Date());
  const [minDate, setMinDate] = useState(defaultMinDate);
  const [mode, setMode] = useState("monthly");
  const [dates, setDates] = useState([new Date(), new Date()]);

  const [startDate, endDate] = dates;
  const isDaily = mode === "daily";
  const maxDetail = mode === "monthly" ? "year" : "month";

  const onChange = (value) => {
    setDates(value);
  };

  const onRadioChange = (value) => {
    setMode(value);
    setDates([new Date(), new Date()]);
  };

  const onClick = () => {
    const data = {
      ...defaultData,
      mode,
      end_date: moment(endDate).format("YYYY-MM-DD"),
      start_date: moment(startDate).format("YYYY-MM-DD"),
    };
    const currentConpteurPro = clientCompteursPro.filter(
      (x) => x.reference === reference
    );
    const list = currentConpteurPro[0] || { compteursPro: [{}] };
    const contractToExport = {
      ...list.compteursPro[0],
      reference,
    };
    dispatch(FetchTelemetry(data, contractToExport));
  };

  const onClickDay = (value, e) => {
    if (isDaily && !selecting) {
      const now = new Date();
      const newMinDate = new Date(value);
      const newMaxDate = new Date(value);
      setMinDate(new Date(newMinDate.setDate(value.getDate() - 90)));
      const calcDay = new Date(newMaxDate.setDate(value.getDate() + 90));
      if (now > calcDay) {
        setMaxdate(calcDay);
      }
    } else {
      setMinDate(defaultMinDate);
    }
    setSelecting(!selecting);
  };

  const title = t("consumption.histogram.modal.title");

  return (
    <SzModal title={title} size="xl" {...props} className="mx-3 ">
      <div className="row">
        <div className="col-12 col-lg-7">
          <SzRadioButton
            name="export-type"
            choices={choices}
            value={mode}
            onChange={onRadioChange}
          />
          <SzDateRangePicker
            value={dates}
            onChange={onChange}
            maxDetail={maxDetail}
            minDate={minDate}
            maxDate={maxDate}
            onClickDay={onClickDay}
            selectRange
          />
        </div>
        <div className="col-12 col-lg-5 d-flex flex-column pt-4">
          <SzAlert
            variant="info"
            transparent
            className="align-items-start mt-xs-5"
          >
            <SzTypographie variant="body" weight="bold" className="py-2 m-0">
              {t("consumption.histogram.modal.info_period", { context: mode })}
            </SzTypographie>
          </SzAlert>
          <SzTypographie variant="body" className="mt-auto">
            {t("consumption.histogram.modal.export_period", {
              context: mode,
              startDate: moment(startDate).format("DD/MM/YYYY"),
              endDate: moment(endDate).format("DD/MM/YYYY"),
            })}
          </SzTypographie>
          <SzButton
            onClick={onClick}
            loader={loading}
            className="mr-auto mb-3"
            icon="common-file-text-download"
          >
            {t("consumption.histogram.modal.button")}
          </SzButton>
          {error && (
            <SzAlert className="mt-4">
              <SzTypographie variant="body" weight="bold" className="py-2 m-0">
                {error}
              </SzTypographie>
            </SzAlert>
          )}
          {exportDone && (
            <SzAlert variant="success" className="mt-4">
              <SzTypographie variant="body" weight="bold" className="py-2 m-0">
                {t("consumption.histogram.modal.export_done")}
              </SzTypographie>
            </SzAlert>
          )}
        </div>
      </div>
    </SzModal>
  );
};

export default ExportModalTR;
