export default {
  CURRENT_PAGE: {
    SET: 'BILLING_SET_CURRENT_PAGE'
  },
  TOTAL_PAGE: {
    SET: 'BILLING_SET_TOTAL_PAGE'
  },
  LIST: {
    DISPLAY: 'BILLING_FILTERED',
    MEMORY: 'BILLING_MEMORY',
    LIMIT: 'BILLING_LIST_LIMIT',
    ENCART: 'BILLING_LIST_ENCART'
  },
  ENCART: {
    INFOS: 'BILLING_ENCART_INFOS',
    LOADING: {
      START: 'BILLING_ENCART_START_LOADING',
      STOP: 'BILLING_ENCART_STOP_LOADING'
    },
  },
  LOADING: {
    START: 'BILLING_START_LOADING',
    STOP: 'BILLING_STOP_LOADING'
  },
  ERROR: {
    SET: 'BILLING_SET_ERROR',
    RESET: 'BILLING_RESET_ERROR'
  },
  FACTURES: {
    SET: 'BILLING_SET_CLIENTFACTURESPRO'
  },
  NB_FACTURES: {
    SET: 'BILLING_SET_NB_FACTURES'
  },
  CODEREF: {
    SET: 'BILLING_SET_CODEREF'
  },
  FORM: {
    SEARCHED: {
      SET: 'BILLING_FORM_SET_SEARCH',
      RESET: 'BILLING_FORM_RESET_SEARCH'
    },
    LOADING: {
      START: 'BILLING_FORM_START_LOADING',
      STOP: 'BILLING_FORM_STOP_LOADING'
    },
    ERROR: {
      SET: 'BILLING_FORM_SET_ERROR',
      RESET: 'BILLING_FORM_RESET_ERROR'
    }
  },
  DOWNLOAD: {
    START: 'BILLING_DOWNLOAD_START',
    STOP: 'BILLING_DOWNLOAD_STOP'
  }
};
