import React, { FC } from 'react';
import classnames from 'classnames';
import { UnconnectedHeader as Header } from '../Header';
import './container.scss';

export const Unconnected: FC = ({ children }) => {
  const containerClasses = classnames('tsme-container col overflow-auto p-3');

  return (
    <>
      <Header />
      <div
        className="row no-gutters h-100 overflow-auto"
        style={{
          height: 'calc(100% - 64px)'
        }}
      >
        <div className={containerClasses}>{children}</div>
      </div>
    </>
  );
};

export default Unconnected;
