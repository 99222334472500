import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  SzAlert,
  SzButton,
  SzCheckbox,
  SzDateRangePicker,
} from "@suezenv/react-theme-components";
import { BillingSearchModel } from "../../models";
import { BillingActions } from "../../redux/actions";
import { form as initialValues } from "../../redux/reducers/billing";
import { Form, Input } from "@suezenv/tsme-react-components";

export default function BillingSearchForm(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation("models");
  const { FetchBilling } = BillingActions;
  const { form, error } = useSelector(({ billing }) => billing);
  const { dates: formDates, ...model } = BillingSearchModel;
  const onSubmit = (form) => dispatch(FetchBilling(form));
  const [checked, setChecked] = useState(false);

  return (
    <Form
      initialValues={form.searched}
      validationSchema={BillingSearchModel}
      onSubmit={onSubmit}
      className="tsme-search-form py-2"
    >
      {({ resetForm, setFieldValue, values }) => {
        const defaultSearched = {
          ...initialValues.searched,
          reference: "",
          amount: "",
          invoiceNumber: "",
          siteAddress: "",
        };

        const classes = {
          reference: "col-12 col-md-3 mb-2",
          invoiceNumber: "col-12 col-md-4 mb-2",
          siteAddress: "col-12 col-md-5 mb-2",
          amount: "col-12 col-md-3",
        };

        const onChange = (dates) => {
          setFieldValue("dates", dates);
        };

        const onChangeCheckBox = (e) => {
          setChecked((prev) => !prev);
        };

        return (
          <>
            {Object.keys(model).map((name) => {
              const { label, placeholder, info, ...restModel } =
                BillingSearchModel[name];
              return (
                <Input
                  key={name}
                  {...restModel}
                  valid={false}
                  placeholder={t(placeholder)}
                  label={t(label)}
                  info={t(info)}
                  handleClear={() => setFieldValue(name, "")}
                  className={classes[name]}
                />
              );
            })}
            <div className="col-12 col-md-4">
              <SzDateRangePicker
                onChange={onChange}
                value={values.dates}
                toggle
                selectRange
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center justify-content-between">
              <SzCheckbox
                id="pending_bill"
                name="pending_bill"
                checked={checked}
                onChange={onChangeCheckBox}
                label={t("searchBlock.pending_bill.label")}
              />
              <SzButton
                icon="synchronize-arrow-1"
                variant="tertiary"
                className="ml-auto mr-4 cy-search-reset-btn"
                onClick={() => resetForm({ values: defaultSearched })}
              />
              <SzButton
                type="submit"
                isDisabled={form.loading}
                loader={form.loading}
              >
                {t("searchBlock.button")}
              </SzButton>
            </div>
            {error && (
              <div className="col-12 mt-3">
                <SzAlert type="danger">{error}</SzAlert>
              </div>
            )}
          </>
        );
      }}
    </Form>
  );
}
