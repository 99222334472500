import React from 'react';
import './index.scss';
import PartA from './PartA';
import PartB from './PartB';

export default function Cgu() {
  return (
    <div className="col-12 col-md-8 bg-white p-4 mt-3 mx-auto">
      <PartA />
      <PartB />
    </div>
  );
}
