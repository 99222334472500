import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SzTypographie, SzButton } from '@suezenv/react-theme-components';
import { formatSold } from '../../services/Utils';
import DashboardCard from '../../components/Card/Dashboard';

export default function BillingCard() {
  const { t } = useTranslation('pages');
  const { soldeGlobal } = useSelector(({ app }) => app.currentUser);
  const sold = (soldeGlobal || 0) === 0 ? 0 : formatSold(soldeGlobal);

  return (
    <DashboardCard title={t('dashboard.facture.title')}>
      <SzTypographie
        tag="span"
        variant="caption"
        size="M"
        weight="regular"
        color="inactive"
        className="text-center"
      >
        {t('dashboard.reglement.sold')}
      </SzTypographie>
      <SzTypographie
        variant="large"
        weight="medium"
        className="text-center pt-2"
      >
        {`${soldeGlobal ? sold : '-'} €`}
        <SzTypographie tag="span" className="small">
          {' '}
          TTC
        </SzTypographie>
      </SzTypographie>
      <Link to={'/espace-facturation'} className="m-auto text-center d-block">
        <SzButton variant="secondary">
          {t('dashboard.reglement.button')}
        </SzButton>
      </Link>
    </DashboardCard>
  );
}
