import axios from '../../services/Request';
import { history } from '../../redux';
import { CONSUMPTION } from '../types';
import moment from 'moment';
import { exportToXlsx } from '../../services/Utils';
const {
  LOADING,
  ERROR,
  FORM,
  LIST,
  ITEM,
  MEASURES,
  MEASURESCSV,
  FACTURES,
  NB_METERS,
  CODEREF,
  CURRENT_PAGE,
  TOTAL_PAGE,
  MODE,
  COMPTEUR,
  EXPORTDONE
} = CONSUMPTION;

export const startLoading = () => ({
  type: LOADING.START
});
export const stopLoading = () => ({
  type: LOADING.STOP
});
export const setError = error => ({
  type: ERROR.SET,
  error
});
export const resetError = () => ({
  type: ERROR.RESET
});
export const comsumptionFormStartLoading = () => ({
  type: FORM.LOADING.START
});
export const comsumptionFormStopLoading = () => ({
  type: FORM.LOADING.STOP
});
export const comsumptionFormSetError = error => ({
  type: FORM.ERROR.SET,
  error
});
export const comsumptionFormResetError = () => ({
  type: FORM.ERROR.RESET
});
export const setList = list => ({
  type: LIST.SET,
  list
});
export const setCurrentPage = currentPage => ({
  type: CURRENT_PAGE.SET,
  currentPage
});
export const setTotalPage = totalPage => ({
  type: TOTAL_PAGE.SET,
  totalPage
});
export const setMemoryList = list => ({
  type: LIST.MEMORY,
  list
});
export const setItem = item => ({
  type: ITEM.SET,
  item
});
export const setClientCompteursPro = clientCompteursPro => ({
  type: FACTURES.SET,
  clientCompteursPro
});
export const setNbCodeRef = nbCodeRefFull => ({
  type: CODEREF.SET,
  nbCodeRefFull
});
export const setNbCompteurFull = nbCompteurFull => ({
  type: COMPTEUR.SET,
  nbCompteurFull
});
export const setNbMeters = nbMeters => ({
  type: NB_METERS.SET,
  nbMeters
});
export const consumptionFormSetSearched = searched => ({
  type: FORM.SEARCHED.SET,
  searched
});
export const setMeasures = measures => ({
  type: MEASURES.SET,
  measures
});
export const setMeasuresCsv = measurescsv => ({
  type: MEASURESCSV.SET,
  measurescsv
});
export const setMode = mode => ({
  type: MODE.SET,
  mode
});
export const setExportDone = exportDone => ({
  type: EXPORTDONE.SET,
  exportDone
});

export const fetchConsumption = form => {
  const url = '/api/consumption/meters_list';
  return async (dispatch, getState) => {
    const { consumption } = getState();
    const toSearch = form ? form : consumption.form.searched;
    const body = {
      ...toSearch
    };
    try {
      dispatch(setClientCompteursPro([]));
      dispatch(comsumptionFormStartLoading());
      const { data, headers } = await axios.post(url, body);
      const {
        nbCodeRefFull,
        clientCompteursPro,
        nbMeters,
        nbCompteurFull
      } = data;
      const totalPage = parseInt(headers['x-pagination-page-count']);
      dispatch(setTotalPage(totalPage));
      dispatch(setCurrentPage(1));
      dispatch(setClientCompteursPro(clientCompteursPro));
      dispatch(setNbCodeRef(nbCodeRefFull));
      dispatch(setNbCompteurFull(nbCompteurFull));
      dispatch(setNbMeters(nbMeters));
      dispatch(setMemoryList(data));
      dispatch(comsumptionFormStopLoading());
    } catch ({ response }) {
      dispatch(comsumptionFormSetError(response.data.message));
      if (
        Number(response.data.code) >= 10 &&
        Number(response.data.code) <= 13
      ) {
        history.push({
          pathname: '/espace-consommation',
          state: {
            prevPath: history.location.pathname
          }
        });
      }
      dispatch(stopLoading());
    }
  };
};

export const SearchConsumption = ({ ...form }) => {
  const url = '/api/consumption/meters_list';
  const body = {
    ...form,
    currentPage: 1,
    itemsPerPage: 5
  };
  return async dispatch => {
    try {
      dispatch(setClientCompteursPro([]));
      dispatch(comsumptionFormSetError(null));
      dispatch(comsumptionFormStartLoading());
      const { data } = await axios.post(url, body);
      const { nbMeters, nbCodeRefFull, clientCompteursPro } = data;
      dispatch(consumptionFormSetSearched(body));
      dispatch(setNbCodeRef(nbCodeRefFull));
      dispatch(setClientCompteursPro(clientCompteursPro));
      dispatch(setNbMeters(nbMeters));
      dispatch(comsumptionFormStopLoading());
    } catch ({ response }) {
      dispatch(comsumptionFormSetError(response.data.message));
      dispatch(comsumptionFormStopLoading());
    }
  };
};
export const showMoreList = () => {
  const url = '/api/consumption/meters_list';
  return async (dispatch, getState) => {
    const { consumption } = getState();
    const { form, currentPage, clientCompteursPro: prevCompteur } = consumption;
    const newPage = currentPage + 1;
    const body = {
      ...form.searched,
      itemsPerPage: 5,
      currentPage: newPage
    };
    try {
      dispatch(startLoading());
      const { data } = await axios.post(url, body);
      const { clientCompteursPro } = data;
      const mergeCompteur = [...prevCompteur, ...clientCompteursPro];
      dispatch(setCurrentPage(newPage));
      dispatch(setClientCompteursPro(mergeCompteur));
      dispatch(setNbMeters(mergeCompteur.length));
      dispatch(stopLoading());
    } catch ({ response }) {
      dispatch(comsumptionFormSetError(response.data.message));
      dispatch(stopLoading());
    }
  };
};
export const FetchNonTelemetry = (
  { start_date, end_date, ...form },
  contractToExport
) => {
  const url = '/api/consumption/nontelemetry';
  const body = {
    ...form,
    start_date:
      start_date || moment().subtract(5, 'years').format('YYYY-MM-DD'),
    end_date: end_date || moment().format('YYYY-MM-DD')
  };

  return async dispatch => {
    try {
      dispatch(startLoading());
      dispatch(comsumptionFormResetError());
      dispatch(resetError());
      const { data } = await axios.post(url, body);
      const { measures } = data;
      if (!contractToExport) {
        dispatch(setMeasures(measures));
      } else {
        exportToXlsx(contractToExport, measures, false);
        dispatch(setExportDone(true));
      }
      dispatch(stopLoading());
    } catch ({ response }) {
      dispatch(setError(response.data.message));
      dispatch(stopLoading());
    }
  };
};

export const FetchTelemetry = (
  { mode = 'monthly', ...form },
  contractToExport
) => {
  const url = '/api/consumption/telemetry';

  const start_date_daily = moment().startOf('month').format('YYYY-MM-DD');
  const start_date_monthly = moment().subtract(2, 'years').format('YYYY-MM-DD');

  const end_date_daily = moment().endOf('month').format('YYYY-MM-DD');
  const end_date_monthly = moment().format('YYYY-MM-DD');

  const body = {
    ...form,
    mode
  };

  if (!body.start_date) {
    body.start_date = mode === 'daily' ? start_date_daily : start_date_monthly;
  }
  if (!body.end_date) {
    body.end_date = mode === 'daily' ? end_date_daily : end_date_monthly;
  }

  return async dispatch => {
    try {
      dispatch(startLoading());
      dispatch(comsumptionFormResetError());
      dispatch(resetError());
      const { data } = await axios.post(url, body);
      const { measures } = data;
      if (!contractToExport) {
        dispatch(setMeasures(measures));
        dispatch(setMode(mode));
      } else {
        exportToXlsx(contractToExport, measures, true, mode);
        dispatch(setExportDone(true));
      }
      dispatch(stopLoading());
    } catch ({ response }) {
      dispatch(setError(response.data.message));
      dispatch(stopLoading());
    }
  };
};

export const findByRefAndMatricule = (ref, matr) => {
  return async (dispatch, getState) => {
    const store = getState();
    const { memoryList } = store.consumption;
    memoryList.clientFacturesPro.filter(bill => {
      console.log(bill);
    });
  };
};

export default {
  startLoading,
  stopLoading,
  setError,
  resetError,
  comsumptionFormStartLoading,
  comsumptionFormStopLoading,
  comsumptionFormSetError,
  comsumptionFormResetError,
  setList,
  setMemoryList,
  setItem,
  fetchConsumption,
  findByRefAndMatricule,
  setClientCompteursPro,
  setNbCodeRef,
  setNbMeters,
  SearchConsumption,
  FetchNonTelemetry,
  FetchTelemetry,
  showMoreList
};
