import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchBox from './searchbox';
import { SzTypographie } from '@suezenv/react-theme-components';
import CollapseConsumption from '../../components/Collapse/Consumption';
import { ConsumptionActions } from '../../redux/actions';
import {
  fetchConsumption,
  comsumptionFormSetError
} from '../../redux/actions/consumption';
import ContainerCollapse from '../../components/CollapseContainer';

export default function Consumption({ history }) {
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();
  const { showMoreList } = ConsumptionActions;
  const showMore = () => dispatch(showMoreList());
  const {
    clientCompteursPro,
    nbMeters,
    loading,
    totalPage,
    form,
    currentPage,
    nbCompteurFull
  } = useSelector(({ consumption }) => consumption);
  const hasMorePages = currentPage < totalPage;
  const state = history && history.location.state;
  const title = t('consumption.metterSet', { count: nbCompteurFull });

  useEffect(() => {
    if (!state || !state.prevPath.includes('TR' || 'RR' || 'SE')) {
      dispatch(comsumptionFormSetError(null));
    }
  }, []);

  return (
    <>
      <SzTypographie variant="h1" className="mb-3">
        <span dangerouslySetInnerHTML={{ __html: t('consumption.title') }} />
      </SzTypographie>
      <div className="conso-header">
        <SearchBox />
      </div>

      <ContainerCollapse
        title={title}
        Collapse={CollapseConsumption}
        formLoading={form.loading}
        loading={loading}
        currentPage={currentPage}
        totalPage={totalPage}
        list={clientCompteursPro}
        showMore={showMore}
        displayShowMore={hasMorePages}
        onInit={fetchConsumption}
      />
    </>
  );
}
