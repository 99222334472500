import { APP } from "../types";
import BillingNlzr, { billing as billingSchema } from "../normalize/billing";

export const initialState = {
  billing: {},
  currentUser: {
    contracts_status: "OK",
    civility: "",
    contracts: [],
    allowed_services: [],
    email: "",
    first_name: "",
    last_name: "",
    detailContract: {},
  },
  modal: false,
  modalName: null,
  error: null,
  loading: false,
  logged: false,
  token_valid: false,
  form: {
    loading: false,
    error: null,
  },
};

export default function app(state = initialState, action) {
  const { user, error, token_valid, type, payload, modalName } = action;
  switch (type) {
    case APP.SET_BILLING:
      const billing = BillingNlzr(payload, billingSchema);
      return (state = { ...state, billing });
    case APP.LOGIN:
      return (state = { ...state, logged: true });
    case APP.LOGOUT:
      return (state = { ...state, logged: false });
    case APP.TOKEN_VALID:
      return (state = { ...state, token_valid });
    case APP.USER:
      return (state = { ...state, currentUser: user });
    case APP.MODAL.OPEN:
      return (state = { ...state, modal: true, modalName });
    case APP.MODAL.CLOSE:
      return (state = { ...state, modal: false, modalName: null });
    case APP.LOADING.START:
      return (state = { ...state, loading: true });
    case APP.LOADING.STOP:
      return (state = { ...state, loading: false });
    case APP.ERROR.SET:
      return (state = { ...state, error });
    case APP.ERROR.RESET:
      return (state = { ...state, error: null });
    case APP.FORM.LOADING.START:
      return (state = { ...state, form: { ...state.form, loading: true } });
    case APP.FORM.LOADING.STOP:
      return (state = { ...state, form: { ...state.form, loading: false } });
    case APP.FORM.ERROR.SET:
      return (state = { ...state, form: { ...state.form, error } });
    case APP.FORM.ERROR.RESET:
      return (state = { ...state, form: { ...state.form, error: null } });
    default:
      return state;
  }
}
