import * as Yup from 'yup';

export default {
  reference: {
    label: 'models:addContract.reference.label',
    className: 'pr-md-3',
    placeholder: 'models:addContract.reference.placeholder',
    type: 'text',
    info: 'models:addContract.reference.info',
    schema: Yup.string()
      .isRef('models:addContract.reference.ref')
      .required('models:addContract.reference.require')
  },
  identifier: {
    label: 'models:addContract.identifier.label',
    className: 'pl-md-3',
    placeholder: 'models:addContract.identifier.placeholder',
    type: 'text',
    info: 'models:addContract.identifier.info',
    schema: Yup.string()
      .length(4, 'models:addContract.identifier.length')
      .required('models:addContract.identifier.require')
  },
  zipcode: {
    label: 'models:addContract.zipcode.label',
    className: 'pr-md-3',
    placeholder: 'models:addContract.zipcode.placeholder',
    type: 'text',
    schema: Yup.string()
      .length(5, 'models:addContract.zipcode.length')
      .required('models:addContract.zipcode.require')
  }
};
