import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cards as switchCards, banner as switchBanner } from './switch';
import { cards as fluidCards, banner as fluidBanner } from './fluids';
import { cards as waterCards, banner as waterBanner } from './water';
import {
  SzButton,
  SzModal,
  SzTypographie
} from '@suezenv/react-theme-components';
import FadeSlide from '../../utils/Transition';
import ProductForm from './form';
import Banner from './Banner';
import Card, { CardType } from './Card';
import ButtonLink from './ButtonLink';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ProductActions } from '../../redux/actions';
import SendingMessage from '../../assets/img/message-sending.svg';

export type ProductType = 'switch' | 'fluids' | 'water';
export type ProductProps = RouteComponentProps<{ type: ProductType }>;

export const Product: FC<ProductProps> = ({ location, match }) => {
  const dispatch = useDispatch();
  const { hideSuccess, hideFormAction, showFormAction } = ProductActions;
  const [animateSlide, setAnimateSlide] = useState(false);
  const { pathname, type } = { ...location, ...match.params };
  const { t } = useTranslation();

  useEffect(() => {
    setAnimateSlide(true);
  }, [pathname]);

  const { showSuccess, showForm } = useSelector((state: any) => state.product);

  const showContactClick = () => {
    dispatch(showFormAction());
  };

  const hideContactClick = () => {
    dispatch(hideFormAction());
  };

  const hideSuccessClick = () => {
    dispatch(hideSuccess());
  };

  const headersList = [fluidBanner, switchBanner, waterBanner];
  const header = headersList.filter(
    ({ type: headerType }) => type === headerType
  );

  const cardsList = {
    switch: switchCards,
    fluids: fluidCards,
    water: waterCards
  };
  const cards = cardsList[type] as CardType[];

  return (
    <div className="row overflow-hidden">
      <ButtonLink setAnimateSlide={setAnimateSlide} type={type} />
      <div className="col-12 border border-blue-clear" />
      <div className="col-12 bg-blue-clear-light">
        <FadeSlide inProp={animateSlide}>
          <Banner {...header[0]} />
        </FadeSlide>
      </div>
      <div className="col-12 mt-5">
        <FadeSlide inProp={animateSlide}>
          {cards.map((props, key) => (
            <Card key={key} {...props} />
          ))}
        </FadeSlide>
      </div>
      <div className="col-12 text-center">
        <SzButton onClick={showContactClick}>{t('product.contact')}</SzButton>
      </div>
      <ProductForm show={showForm} handleClose={hideContactClick} type={type} />
      <SzModal
        show={showSuccess}
        title=""
        handleClose={hideSuccessClick}
        className="successPanel"
        size="xl"
      >
        <div className="row">
          <div className="col-5 offset-2">
            <SzTypographie variant="huge" weight="bold">
              {t('product.successTitle')}
            </SzTypographie>
            <SzTypographie variant="caption" size="M" className="text-disabled">
              {t('product.successBody')}
            </SzTypographie>
          </div>
          <div className="col-4">
            <img src={SendingMessage} alt="" />
          </div>
        </div>
      </SzModal>
    </div>
  );
};

export default Product;
