export const banner = {
  type: 'switch',
  title: 'ON’connect™ switch',
  subtitle: "PILOTEZ A DISTANCE L'ALIMENTATION EN EAU DE VOS BATIMENTS",
  text:
    "Vos installations accueillent du public et vous êtes garants de leur sécurité&nbsp;? Avec ON’connect™ switch, pilotez à distance l'alimentation en eau de vos bâtiments. La coupure automatique en cas de fuites vous permet de vous prémunir de tous risques de dégâts des eaux et de garantir la continuité de vos activités.",
  video: 'https://youtube.com/embed/2QS32xLKNwA',
  poster:
    'https://www.suezsmartsolutions.com/fr/content/inlineview/174/blue_square_booklet/SUEZ_OCS_web-fr%281%29.pdf'
};

export const cards = [
  {
    title: 'Vos Enjeux',
    list: [
      "• Maîtriser vos dépenses en eau sur l'ensemble de vos sites",
      '• Sécuriser vos installations et locaux contre les fuites et les dégâts des eaux',
      '• Assurer la continuité de l’activité hébergée dans le bâtiment',
      '• Prévenir en temps réel des éventuelles fuites sur les sites supervisés',
      '• Être conformes aux normes environnementales (BREEAM, HQE)'
    ],
    image: '/img/products/switch.jpg'
  },
  {
    title: 'Nos Réponses',
    list: [
      '• Suivi des consommations d’eau de différents bâtiments',
      '• Identification des schémas de consommations selon les effets calendaires (soirs, week-ends, vacances)',
      '• Alertes fuites selon un seuil paramétré (emails, sms)',
      "• Fermeture automatique de l'arrivée d'eau en cas de fuite importante détectée",
      "• Programmation d'horaires d'ouverture/fermeture de l'alimentation en eau du bâtiment en fonction de son occupation "
    ]
  }
];

export default {
  cards,
  banner
};
