export default function useScrollTo(
  options: ScrollToOptions = {},
  keySelector: string = ".tsme-container"
) {
  return (offsetTop) => {
    const scrollOptions: ScrollToOptions = {
      top: offsetTop,
      behavior: "smooth",
      ...options
    };

    const position = document.querySelector(keySelector);

    position?.scrollTo(scrollOptions);
  };
}
