import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { SzAlert, SzButton, SzInput } from '@suezenv/react-theme-components';
import { AppActions } from '../../redux/actions';
import { EmailModel, getFormatedForm } from '../../models';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import FormConstructor, { InputConstructor } from '../FormConstructor';
import useCheckTypeUser from '../../hooks/useCheckTypeUser';

export default function EmailForm(props) {
  const { t } = useTranslation('models');
  const dispatch = useDispatch();
  const { updateEmail, formResetError } = AppActions;
  const [error, isLoading] = useSelector(({ app }) => [
    app.form.error,
    app.form.loading
  ]);
  const onSubmit = (form) => dispatch(updateEmail(form));
  const { email, repeated_email } = EmailModel;

  const isAdviserSimpleUser = useCheckTypeUser('internal_adviser_simple');

  useEffect(() => {
    return () => {
      dispatch(formResetError());
    };
  }, []);

  return (
    <FormConstructor model={EmailModel} onSubmit={onSubmit}>
      {({ isValid, ...props }) => (
        <>
          <InputConstructor name={'email'} {...email} />
          <InputConstructor name={'repeated_email'} {...repeated_email} />
          <div className="col-12 mt-4 text-right">
            {error && <SzAlert type="danger">{error}</SzAlert>}
            <SzButton
              type="submit"
              loader={isLoading}
              isDisabled={
                isAdviserSimpleUser ||
                !isValid ||
                isLoading
              }
              className={isLoading && 'col-4'}
            >
              {t('profile.button')}
            </SzButton>
          </div>
        </>
      )}
    </FormConstructor>
  );
}
