import { useTranslation } from "react-i18next";

export default function useHistogram(options) {
  const { series } = options;
  const { t } = useTranslation("components");

  const title = Object.assign(
    {
      align: "left",
      useHTML: true,
      style: {
        fontFamily: "DINPro-Medium",
      },
    },
    options.title || {}
  );

  const chart = Object.assign(
    {
      type: "column",
    },
    options.chart || {}
  );

  const tooltip = Object.assign(
    {
      backgroundColor: "rgba(255,255,255,0)",
      borderWidth: 0,
      borderRadius: 1,
      shadow: false,
      useHTML: true,
      style: {
        zIndex: 9999,
        lineHeight: 1.8,
      },
    },
    options.tooltip || {}
  );

  const xAxis = Object.assign(
    {
      title: {
        useHTML: true,
        text: "",
      },
      labels: {
        useHTML: true,
        format: '<span class="small">{value}</span>',
        style: {
          color: "",
        },
      },
      crosshair: true,
    },
    options.xAxis || {}
  );

  const yAxis = Object.assign(
    // Default values
    {
      min: 0,
      title: {
        text: t("consumption.histogram.yaxis"),
        useHTML: true,
        style: {
          zIndex: 0,
        },
      },
    },
    // Props options
    options.yAxis || {}
  );

  const column = Object.assign(
    {
      minPointLength: 5,
      pointPadding: 0,
      borderWidth: 0,
      colors: "#F0F0F0",
    },
    options.plotOptions ? options.plotOptions.column : {}
  );

  const events = Object.assign(
    {
      show: () => {
        const { series } = this.chart;
        series.forEach((item) => {
          if (item != this && item.visible) {
            item.hide();
          }
        });
      },
      legendItemClick: () => this.visible && false,
    },
    options.plotOptions ? options.plotOptions.events : {}
  );

  const plotOptions = {
    column,
    events,
    series: {
      minPointLength: 5,
    },
    legend: {
      enabled: true,
    },
  };

  return {
    series,
    title,
    chart,
    tooltip,
    xAxis,
    yAxis,
    plotOptions,
    rangeSelector: {
      selected: 2,
    },
    exporting: {
      enabled: false,
    },
  };
}
