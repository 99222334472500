import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  SzAlert,
  SzButton,
  SzInput,
  SzTypographie
} from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { ContractActions } from '../../redux/actions';
import { ContractsModel, getFormatedForm } from '../../models';
import Capaddress from '../../components/Capaddress';
import useCheckTypeUser from '../../hooks/useCheckTypeUser';

export default function EditContractForm({ active }) {
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();
  const [selectedField, setSelectedField] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { item: contract, form } = useSelector(({ contract }) => contract);

  const isAdviserSimpleUser = useCheckTypeUser('internal_adviser_simple');
  const isInternAdminUSer = useCheckTypeUser('internal_adviser_admin');

  const isLoading = form.loading;
  const { updateContract, contractFormResetError } = ContractActions;

  const model = ContractsModel[active];

  const [schema, initialValues] = getFormatedForm(model, contract);
  const onSubmit = (form) => {
    const data = { ...form };
    data.form = active;
    data.reference = contract.reference;

    dispatch(updateContract(data));
  };

  const {
    name,
    siret,
    naf_code,
    billing_zip_code,
    billing_town,
    billing_address_1,
    billing_address_2,
    email,
    phone,
    mobile_phone
  } = model;

  useEffect(() => {
    return () => {
      dispatch(contractFormResetError());
    };
  }, []);

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        validateField,
        setFieldValue,
        setErrors,
        setFieldError,
        setFieldTouched,
        isValid,
        touched
      }) => {
        const handleError = (name) => {
          const newErrors = { ...errors };
          delete newErrors[name];
          setErrors(newErrors);
        };
        const customHandleBlur = (e) => {
          const { name, value } = e.target;
          setFieldTouched(name, true);
          setSelectedField(null);
          schema
            .validateAt(name, { ...values, [name]: value })
            .then(() => {
              handleError(name);
            })
            .catch((error) => {
              const { path, message } = error;
              setFieldError(path, message);
            });
        };
        const customHandleChange = (e) => {
          const { name } = e.target;
          if (errors[name]) handleError(name);
          if (['naf_code', 'billing_address_2'].includes(name)) {
            e.target.value = e.target.value.toUpperCase();
          }
          setSelectedField(name);
          handleChange(e);
        };
        const capAddressClick = (address, type) => {
          const { postalCode, locality, inputOutput, localityInfo_2 } = address;
          setSelectedAddress(address);
          if (type === 'town') {
            const fields = {
              billing_zip_code: postalCode,
              billing_town: localityInfo_2
                ? `${locality} ${localityInfo_2}`
                : locality
            };
            for (const name in fields) {
              const value = fields[name];
              setFieldValue(name, value);
              setFieldTouched(name, true);
            }
          }
          if (type === 'street') {
            setFieldTouched('billing_address_1', true);
            setFieldValue('billing_address_1', inputOutput);
          }
          setSelectedField(null);
        };
        const checkValue = (name) => {
          return values[name] !== undefined && values[name].length > 0;
        };
        const checkShowError = (name) => {
          return checkValue(name) && errors[name];
        };
        const checkShowValid = (name) => {
          return selectedField !== name && !errors[name] && checkValue(name);
        };
        return (
          <form onSubmit={handleSubmit}>
            {active === 'billing' ? (
              <div className="row no-gutters">
                <div className={name.className}>
                  <SzInput
                    label={t(name.label)}
                    name={'name'}
                    onChange={customHandleChange}
                    onBlur={customHandleBlur}
                    error={errors['name']}
                    icon={name.icon}
                    value={values['name']}
                    info={name.info}
                    placeholder={t(name.placeholder)}
                    type={name.type}
                  />
                  {checkShowError('name') && (
                    <SzAlert type="danger">{errors['name']}</SzAlert>
                  )}
                </div>
                <SzTypographie
                  weight="medium"
                  className="col-12 d-block mt-4 mb-3"
                >
                  {t('review.social')}: {contract.company_name}
                </SzTypographie>
                <div className={siret.className}>
                  <SzInput
                    label={t(siret.label)}
                    name={'siret'}
                    onChange={customHandleChange}
                    onBlur={customHandleBlur}
                    icon={siret.icon}
                    valid={checkShowValid('siret')}
                    error={errors['siret']}
                    value={values['siret']}
                    info={siret.info}
                    placeholder={t(siret.placeholder)}
                    type={siret.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                  {checkShowError('siret') && (
                    <SzAlert type="danger">{t(errors['siret'])}</SzAlert>
                  )}
                </div>
                <div className={naf_code.className}>
                  <SzInput
                    label={t(naf_code.label)}
                    name={'naf_code'}
                    onChange={customHandleChange}
                    onBlur={customHandleBlur}
                    icon={naf_code.icon}
                    valid={checkShowValid('naf_code')}
                    error={errors['naf_code']}
                    value={values['naf_code']}
                    info={naf_code.info}
                    placeholder={t(naf_code.placeholder)}
                    type={naf_code.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                  {checkShowError('naf_code') && (
                    <SzAlert type="danger">{t(errors['naf_code'])}</SzAlert>
                  )}
                </div>
                <SzTypographie weight="medium" className="col-12 d-block mb-3">
                  {t('models:editContract.billing_address')}
                </SzTypographie>
                <div className={billing_zip_code.className}>
                  <SzInput
                    label={t(billing_zip_code.label)}
                    name={'billing_zip_code'}
                    onChange={customHandleChange}
                    valid={checkShowValid('billing_zip_code')}
                    autocomplete={false}
                    error={errors['billing_zip_code']}
                    value={values['billing_zip_code']}
                    info={billing_zip_code.info}
                    placeholder={t(billing_zip_code.placeholder)}
                    type={billing_zip_code.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                  {values['billing_zip_code'].length >= 3 &&
                    selectedField === 'billing_zip_code' && (
                      <Capaddress
                        type="town"
                        value={values['billing_zip_code']}
                        onClick={capAddressClick}
                      />
                    )}
                </div>
                <div className={billing_town.className}>
                  <SzInput
                    label={t(billing_town.label)}
                    name={'billing_town'}
                    onChange={customHandleChange}
                    valid={checkShowValid('billing_town')}
                    autocomplete="off"
                    value={values['billing_town']}
                    info={billing_town.info}
                    placeholder={t(billing_town.placeholder)}
                    type={billing_town.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                  {values['billing_town'].length >= 3 &&
                    selectedField === 'billing_town' && (
                      <Capaddress
                        type="town"
                        value={values['billing_town']}
                        onClick={capAddressClick}
                      />
                    )}
                </div>
                <div className={billing_address_1.className}>
                  <SzInput
                    label={t(billing_address_1.label)}
                    name={'billing_address_1'}
                    onChange={customHandleChange}
                    valid={checkShowValid('billing_address_1')}
                    autocomplete="off"
                    value={values['billing_address_1']}
                    info={billing_address_1.info}
                    placeholder={t(billing_address_1.placeholder)}
                    type={billing_address_1.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                  {values['billing_address_1'].length >= 3 &&
                    selectedField === 'billing_address_1' && (
                      <Capaddress
                        type="street"
                        address={selectedAddress}
                        value={values['billing_address_1']}
                        onClick={capAddressClick}
                      />
                    )}
                </div>
                <div className={billing_address_2.className}>
                  <SzInput
                    name={'billing_address_2'}
                    onChange={customHandleChange}
                    valid={checkShowValid('billing_address_2')}
                    value={values['billing_address_2']}
                    info={billing_address_2.info}
                    placeholder={t(billing_address_2.placeholder)}
                    type={billing_address_2.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                </div>
              </div>
            ) : (
              <div className="row no-gutters justify-start">
                <div className={email.className}>
                  <SzInput
                    label={t(email.label)}
                    name={'email'}
                    onChange={customHandleChange}
                    onBlur={customHandleBlur}
                    valid={checkShowValid('email')}
                    icon={email.icon}
                    error={errors['email']}
                    value={values['email']}
                    info={email.info}
                    placeholder={t(email.placeholder)}
                    type={email.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                  {checkShowError('email') && (
                    <SzAlert type="danger">{errors['email']}</SzAlert>
                  )}
                </div>
                <div className="w-100 d-none d-md-block"></div>
                <div className={phone.className}>
                  <SzInput
                    label={t(phone.label)}
                    name={'phone'}
                    onChange={customHandleChange}
                    onBlur={customHandleBlur}
                    valid={checkShowValid('phone')}
                    icon={phone.icon}
                    error={errors['phone']}
                    value={values['phone']}
                    info={phone.info}
                    placeholder={t(phone.placeholder)}
                    type={phone.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                  {checkShowError('phone') && (
                    <SzAlert type="danger">{errors['phone']}</SzAlert>
                  )}
                </div>
                <div className={mobile_phone.className}>
                  <SzInput
                    label={t(mobile_phone.label)}
                    name={'mobile_phone'}
                    onChange={customHandleChange}
                    onBlur={customHandleBlur}
                    valid={checkShowValid('mobile_phone')}
                    icon={mobile_phone.icon}
                    error={errors['mobile_phone']}
                    value={values['mobile_phone']}
                    info={mobile_phone.info}
                    placeholder={t(mobile_phone.placeholder)}
                    type={mobile_phone.type}
                    disabled={active === 'billing' && isInternAdminUSer}
                  />
                  {checkShowError('mobile_phone') && (
                    <SzAlert type="danger">{errors['mobile_phone']}</SzAlert>
                  )}
                </div>
              </div>
            )}
            <div className="mt-4 text-right">
              {form.error && <SzAlert type="danger">{form.error}</SzAlert>}
              <SzButton
                className="m-0"
                type="submit"
                loader={isLoading}
                isDisabled={
                  (active === 'billing' && isAdviserSimpleUser) ||
                  (active === 'contact' &&
                    (isInternAdminUSer || isAdviserSimpleUser)) ||
                  Object.keys(errors).length
                    ? true
                    : isLoading
                }
              >
                {t('models:editContract.button')}
              </SzButton>
            </div>
          </form>
        );
      }}
    />
  );
}
