import React from 'react';
import { SzTypographie } from '@suezenv/react-theme-components';

const { hostname } = window.location;

export default function LegalNotice() {
  return (
    <div className="col-12 col-md-8 bg-white p-4 mt-3 mx-auto">
      <SzTypographie weight="medium" tag="span">
        Mentions légales
      </SzTypographie>
      <Editor />
      <Host />
      <PersonnalData />
    </div>
  );
}

function Editor() {
  return (
    <>
      <SzTypographie variant="h2">1.Editeur:</SzTypographie>
      <SzTypographie>
        Le site <a href="/">{hostname}</a> est édité par SUEZ Eau France, SAS au
        capital de 422.224.040 €, dont le siège social est sis Tour CB 21 - 16
        place de l'Iris, 92040 PARIS LA DEFENSE CEDEX, immatriculée au RCS de
        Nanterre sous le numéro B 410 034 607. Tél: +33 1 58 81 40 00 Numéro de
        TVA intracommunautaire: FR 79 410 034 607 Directeur de Publication:
        Philippe LE GREVES Rédacteur en chef: Pascale OLARD Conception -
        Réalisation: ICI BARBES / USABILIS / SMILE
      </SzTypographie>
    </>
  );
}

function Host() {
  return (
    <>
      <SzTypographie variant="h2">2.Hébergeur:</SzTypographie>
      <SzTypographie>
        Le site <a href="/">{hostname}</a> est hébergé par LINKBYNET, Société
        par Actions Simplifiée au capital de 373.000 Euros, ayant son siège
        social 5-9 rue de l'Industrie, 93200 SAINT-DENIS, immatriculée au
        Registre du Commerce et des Sociétés de Bobigny sous le numéro RCS
        430359 927 et numéro intracommunautaire FR 93430359927000 26
      </SzTypographie>

      <SzTypographie>
        Tél: +33 (0) 1 48 13 00 00 Numéro de TVA intracommunautaire: FR
        93430359927 0004
      </SzTypographie>
    </>
  );
}

function PersonnalData() {
  const data_protection_link =
    'http://www.suez.com/-/media/SUEZ-GLOBAL/Files/Publication-Docs/PDF-Francais/SUEZ-Politique-Protection-Donnees-Personnelles-mai2018-FR.pdf';
  return (
    <div>
      <SzTypographie variant="h2">3. Données Personnelles</SzTypographie>
      <SzTypographie variant="h3">3.1 Base légale du traitement.</SzTypographie>
      <SzTypographie>
        Nous collectons directement auprès de nos clients et utilisateurs du
        site ou indirectement auprès de la collectivité leurs données
        personnelles nécessaires à l’exécution de leur contrat d’abonnement au
        service d’eau et/ou assainissement dans le cadre du contrat de
        délégation du service public signé avec la collectivité.
      </SzTypographie>
      <SzTypographie>
        Vous pouvez consulter notre politique de protection des données
        personnelles mise à jour en cliquant sur 
        <strong>
          <a href={data_protection_link}>ce lien</a>.
        </strong>
      </SzTypographie>
      <SzTypographie variant="h3">3.2 Catégories de données.</SzTypographie>
      <SzTypographie>
        Les catégories de données susceptibles d’être collectées et traitées
        sont les données relatives à l’identité et aux coordonnées de
        l’utilisateur, aux moyens de paiement, à l’abonnement et à la
        facturation.
      </SzTypographie>
      <SzTypographie variant="h3">
        3.3 Finalités et destinataires.
      </SzTypographie>
      <SzTypographie>
        Ces données sont destinées à la gestion du contrat d’abonnement par :
      </SzTypographie>
      <ul className="font-family-regular">
        <li>
          nos équipes de relation client, administratives et techniques afin de
          gérer le contrat (départ, arrivée, alertes, facturation, recouvrement
          et interventions)   et d’assurer le suivi de la relation client
          (demandes, communication, enquêtes de satisfaction);
        </li>
        <li>
          nos sous-traitants pour la réalisation de ces mêmes services de
          gestion du contrat d’abonnement, sous-traitants situés en France ou à
          l’étranger; dans ce dernier cas, les données font l’objet d’un
          transfert vers des pays tiers faisant l’objet d’une protection
          adéquate (la liste de ces pays est disponible sur le site web de la
          commission européenne) ; concernant les autres pays, nous nous
          engageons à signer des clauses contractuelles types pour garantir un
          niveau de protection suffisant;
        </li>
        <li>
          les organismes et auxiliaires de justice dans le cadre de leur mission
          de recouvrement des créances ainsi que les services sociaux, dans la
          limite des obligations réglementaires.
        </li>
      </ul>
      <SzTypographie>
        Dans le cas où l’utilisateur dispose d’un compteur équipé d’un
        dispositif de relève à distance, ses données de consommation sont
        relevées par ce compteur dans le cadre de l’exécution du contrat de
        fourniture d’eau, pour permettre sa facturation et l’alerter en cas de
        surconsommation et suspicion de fuite. Ce compteur communicant collecte
        un index de consommation journalier destiné exclusivement à l’Exploitant
        du service. Le client peut le consulter sur son compte en ligne sur une
        durée de 2 années glissantes, pour suivre sa consommation au jour le
        jour.
      </SzTypographie>
      <SzTypographie>
        Les données personnelles ne sont transmises à la collectivité signataire
        du contrat de délégation de service public d’eau et/ou d’assainissement
        qu’à des fins conformes à la réglementation en vigueur (transfert de
        contrat à un autre délégataire par exemple).
      </SzTypographie>
      <SzTypographie>
        Les données personnelles ne sont transmises à la collectivité signataire
        du contrat de délégation de service public d’eau et/ou d’assainissement
        qu’à son échéance, conformément au Code général des collectivités
        territoriales ou qu’à des fins conformes à la réglementation en vigueur.
      </SzTypographie>
      <SzTypographie>
        De même, lorsque les services d’eau et d’assainissement sont assurés par
        deux exploitants différents, les données personnelles recueillies par
        l’un des exploitants ne peuvent être transmises à l’autre exploitant
        qu’aux fins de l’exécution des contrats de délégation de service public.
      </SzTypographie>
      <SzTypographie variant="h3">3.4 Durée de conservation.</SzTypographie>
      <SzTypographie>
        Elles sont conservées pendant toute la durée du contrat d’abonnement
        prolongée des délais de prescription légale.
      </SzTypographie>
      <SzTypographie variant="h3">
        3.5 Modalités d’exercice de vos droits.
      </SzTypographie>
      <SzTypographie>
        Conformément à la réglementation applicable en matière de données à
        caractère personnel, le client ou l’utilisateur du site dispose d’un
        droit d’accès, de rectification, d’opposition, de limitation du
        traitement, d’effacement et de portabilité de ses données qu’il peut
        exercer :
      </SzTypographie>
      <ul className="font-family-regular">
        <li>par connexion au Compte en Ligne du présent site</li>
        <li>
          ou par email à l’adresse{' '}
          <a href="mailto:privacy.france@suez.com">
            <u>Privacy.france@suez.com</u>{' '}
          </a>
          ou par courrier à l’adresse du Délégué à la Protection des Données
          Personnelles - Suez Eau France SAS – Tour CB 21, 16 place de l’Iris –
          92040 La Défense en précisant ses nom, prénom, adresse et en joignant
          une copie recto-verso de sa pièce d’identité.
        </li>
      </ul>
      <SzTypographie>
        En cas de difficulté persistante malgré l’intervention du Délégué à la
        Protection des Données Personnelles, l’utilisateur peut s’adresser à la
        Commission Nationale Informatique et Libertés (CNIL).
      </SzTypographie>
      <SzTypographie>
        Par ailleurs, nous vous rappelons que lorsqu'un utilisateur communique
        ses données téléphoniques, il dispose du droit de s’inscrire sur la
        liste d’opposition au démarchage téléphonique. Pour plus de
        renseignements, il peut consulter le site 
        <a href="www.bloctel.gouv.fr">www.bloctel.gouv.fr</a>.
      </SzTypographie>
    </div>
  );
}
