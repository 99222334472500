import React from "react";
import { SzButton, SzAlert } from "@suezenv/react-theme-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Input } from "@suezenv/tsme-react-components";
import { ConsumptionSearchModel, getFormatedForm } from "../../models";
import { ConsumptionActions } from "../../redux/actions";

export default function ConsumptionSearchForm(props) {
  const { t } = useTranslation("models");
  const dispatch = useDispatch();
  const { SearchConsumption } = ConsumptionActions;
  const [schema, initialValues] = getFormatedForm(ConsumptionSearchModel);
  const { form } = useSelector(({ consumption }) => consumption);
  const { error, loading } = form;
  const onSubmit = (form) => {
    dispatch(SearchConsumption(form))
  };

  const classes = {
    reference: "col-3",
    matricule: "col-3",
    siteAddress: "col-4",
  };

  return (
    <Form
      initialValues={form.searched}
      validationSchema={ConsumptionSearchModel}
      onSubmit={onSubmit}
      className="tsme-search-form"
    >
      {({ setFieldValue, values, resetForm }) => {
        const defaultSearched = {
          ...initialValues,
        };
        return (
          <>
            {Object.keys(ConsumptionSearchModel).map((name, key) => (
              <Input
                {...ConsumptionSearchModel[name]}
                key={name}
                className={classes[name]}
                info={t(ConsumptionSearchModel[name].info)}
                placeholder={t(ConsumptionSearchModel[name].placeholder)}
                label={t(ConsumptionSearchModel[name].label)}
                handleClear={() => setFieldValue(name, "")}
              />
            ))}
            <div className="col-12 col-md-2 d-flex align-items-center justify-content-between">
              <SzButton
                icon="synchronize-arrow-1"
                variant="tertiary"
                className="ml-auto mr-4 cy-search-reset-btn"
                onClick={() => resetForm({ values: defaultSearched })}
              />
              <SzButton
                icon="search"
                type="submit"
                isDisabled={loading}
                loader={loading}
              />
            </div>
            {error && (
              <div className="col-12 mt-3">
                <SzAlert type="danger">{error}</SzAlert>
              </div>
            )}
          </>
        );
      }}
    </Form>
  );
}
