import React from "react";
import { useSelector } from "react-redux";

interface optionsRefsProps {
  label: string;
  value: string;
}

export function useFilterByInseeCode(selectedAddress) {
  const { currentUser } = useSelector((state: any) => state.app);
  const currentRefs: optionsRefsProps[] = currentUser?.contracts
    .filter(
      ({ insee_code }) =>
        selectedAddress && selectedAddress.localityId === insee_code
    )
    .map(({ reference, served_address }) => {
      const label = [...reference];
      label.splice(2, 0, "-");
      return {
        label: label.join(""),
        value: reference,
        text: served_address,
      };
    });
  return currentRefs;
}
