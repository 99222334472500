export default {
  ITEM: {
    SET: 'CONSUMPTION_ITEM_SET'
  },
  LIST: {
    SET: 'CONSUMPTION_LIST_SET',
    MEMORY: 'CONSUMPTION_MEMORY',
    LIMIT: 'CONSUMPTION_LIST_LIMIT',
    ENCART: 'CONSUMPTION_LIST_ENCART'
  },
  FACTURES: {
    SET: 'CONSUMPTION_SET_CLIENTFACTURESPRO'
  },
  NB_METERS: {
    SET: 'CONSUMPTION_SET_NB_METERS'
  },
  COMPTEUR: {
    SET: 'CONSUMPTION_SET_COMPTEUR'
  },
  CURRENT_PAGE: {
    SET: 'CONSUMPTION_SET_CURRENT_PAGE'
  },
  TOTAL_PAGE: {
    SET: 'CONSUMPTION_SET_TOTAL_PAGE'
  },
  CODEREF: {
    SET: 'CONSUMPTION_SET_CODEREF'
  },
  LOADING: {
    START: 'CONSUMPTION_START_LOADING',
    STOP: 'CONSUMPTION_STOP_LOADING'
  },
  ERROR: {
    SET: 'CONSUMPTION_SET_ERROR',
    RESET: 'CONSUMPTION_RESET_ERROR'
  },
  FORM: {
    SEARCHED: {
      SET: 'CONSUMPTION_FORM_SET_SEARCH',
      RESET: 'CONSUMPTION_FORM_RESET_SEARCH'
    },
    LOADING: {
      START: 'CONSUMPTION_LOADING_START',
      STOP: 'CONSUMPTION_LOADING_STOP'
    },
    ERROR: {
      SET: 'CONSUMPTION_FORM_SET_ERROR',
      RESET: 'CONSUMPTION_FORM_RESET_ERROR'
    }
  },
  MEASURES: {
    SET: 'CONSUMPTION_MEASURES_SET'
  },
  MEASURESCSV: {
    SET: 'CONSUMPTION_MEASURESCV_SET'
  },
  MODE: {
    SET: 'CONSUMPTION_MODE_SET'
  },
  EXPORTDONE: {
    SET: 'EXPORT_DONE_SET'
  }
};
