import axios from '../../services/Request';
import { ContactRequestPayload } from '../types/product';
import { startLoading, stopLoading, setError, resetError } from './app';
import { PRODUCT } from '../types';

export const showFormAction = () => ({
  type: PRODUCT.SHOW_FORM
});

export const hideFormAction = () => ({
  type: PRODUCT.HIDE_FORM
});

export const showSuccess = () => ({
  type: PRODUCT.SHOW_SUCCESS
});

export const hideSuccess = () => ({
  type: PRODUCT.HIDE_SUCCESS
});

export const contactRequest = (payload: ContactRequestPayload) => {
  const url = '/p/contact/request';

  return async function (dispatch: any) {
    dispatch(startLoading());
    try {
      await axios.post(url, payload);
      dispatch(stopLoading());
      dispatch(resetError());
      dispatch(hideFormAction());
      dispatch(showSuccess());
    } catch (error: any) {
      const { response } = error;
      dispatch(stopLoading());
      dispatch(setError(response.data.message));
    }
  };
};

export default {
  showSuccess,
  hideSuccess,
  showFormAction,
  hideFormAction,
  contactRequest
};
