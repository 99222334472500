import React from 'react';
import { SzModal, SzTypographie } from '@suezenv/react-theme-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppActions } from '../../../redux/actions';
import { useTranslation } from 'react-i18next';
import { hideInEnv } from '../../../services/Utils';

export default function TechSheetModal() {
  const { t } = useTranslation(['components', 'pages']);
  const dispatch = useDispatch();
  const { clientCompteursPro } = useSelector(({ consumption }) => consumption);

  const { closeModal } = AppActions;
  const show = useSelector(({ app }) => app && app.modal);

  const { codeEquipement, reference } = useParams();
  const item =
    clientCompteursPro &&
    clientCompteursPro.find(item => item.reference === reference);
  const { compteursPro } = item || [{}];
  const hideInPreprodProd = hideInEnv('prod', 'preprod');
  const {
    usage,
    etatPDS,
    etatCompteur,
    matriculeCompteur,
    fluide,
    anneeFabrication,
    calibre,
    typeRaccordement,
    libelleCodeEmplacement
  } = compteursPro && compteursPro[0] !== undefined ? compteursPro[0] : [];

  const handleClose = () => dispatch(closeModal());

  const UsageMode = () => {
    return (
      <SzTypographie type="label">
        {t('pages:consumption.details.usage', { context: usage })}
      </SzTypographie>
    );
  };

  const ReleveMode = () => {
    return (
      <SzTypographie type="label">
        {t('pages:consumption.details.releveMode', { context: codeEquipement })}
      </SzTypographie>
    );
  };
  const TypeRaccordement = () => {
    const context = typeRaccordement
      ? typeRaccordement.replace(/\s/g, '')
      : null;
    return (
      <SzTypographie variant="text">
        {t('pages:consumption.details.typeRaccordement', {
          context
        })}
      </SzTypographie>
    );
  };
  return (
    <SzModal
      title={t('components:consumption.modals.title.counter')}
      show={show}
      handleClose={handleClose}
      size="xl"
    >
      <div className="d-flex mt-2">
        <div className="d-flex border-right flex-column col p-0">
          <div className="d-flex flex-column ">
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.regisNumb')}
            </SzTypographie>
            <SzTypographie type="label">{matriculeCompteur}</SzTypographie>
          </div>
          <div className="d-flex flex-column ">
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.position')}
            </SzTypographie>
            <SzTypographie type="label">
              {libelleCodeEmplacement ? libelleCodeEmplacement : '-'}
            </SzTypographie>
          </div>
        </div>
        <div className="d-flex border-right flex-column ml-2 col">
          <div className="d-flex flex-column ">
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.reportsMode')}
            </SzTypographie>
            <ReleveMode />
          </div>
          <div>
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.wireState')}
            </SzTypographie>
            <SzTypographie type="label">{etatPDS}</SzTypographie>
          </div>
        </div>
        <div className="d-flex border-right flex-column ml-2 col">
          <div>
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.state')}
            </SzTypographie>
            <SzTypographie type="label">{etatCompteur}</SzTypographie>
          </div>
          <div>
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.fluid')}
            </SzTypographie>
            <SzTypographie type="label">
              {' '}
              {t('components:consumption.modals.content.fluid', {
                context: fluide
              })}
            </SzTypographie>
          </div>
        </div>
        <div className="d-flex border-right flex-column ml-2 col">
          <div>
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.wireType')}
            </SzTypographie>
            <TypeRaccordement />
          </div>
          <div>
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.manufactureYear')}
            </SzTypographie>
            <SzTypographie type="label">{anneeFabrication}</SzTypographie>
          </div>
        </div>
        <div className="d-flex flex-column ml-2 col">
          {!hideInPreprodProd && (
            <div>
              <SzTypographie type="description" className="m-0" weight="medium">
                {t('components:consumption.modals.content.purpose')}
              </SzTypographie>
              <UsageMode />
            </div>
          )}
          <div>
            <SzTypographie type="description" className="m-0" weight="medium">
              {t('components:consumption.modals.content.diameter')}
            </SzTypographie>
            <SzTypographie type="label">{calibre}</SzTypographie>
          </div>
        </div>
      </div>
    </SzModal>
  );
}
