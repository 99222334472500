import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { SzTypographie } from "@suezenv/react-theme-components";
import { BillingActions } from "../../redux/actions";
import CollapseBilling from "../../components/Collapse/Billing";
import ModalEncart from "../../components/Modal/Billing/BillingEncartModal";
import ContainerCollapse from "../../components/CollapseContainer";
import AutoPaiementModal from "../../components/Modal/Billing/AutoPaiement";
import SearchBox from "./searchbox";
import "./billing.scss";

export const Billing = React.memo((props) => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();
  const {
    loading,
    totalPage,
    currentPage,
    clientFacturesPro,
    form,
    nbFactureFull,
  } = useSelector(({ billing }) => billing);
  const { FetchBilling } = BillingActions;
  const showMore = () => dispatch(FetchBilling(form.searched, true));
  const title = t("billing.contractLength", { count: nbFactureFull });
  const hasMorePages = currentPage < totalPage;

  useEffect(() => {
    dispatch(FetchBilling(form.searched));
  }, []);

  return (
    <>
      <SzTypographie variant="h1" className="mb-3">
        {t("billing.title")}
      </SzTypographie>
      <div className="billing-header">
        <SearchBox />
      </div>
      <ContainerCollapse
        title={title}
        Collapse={CollapseBilling}
        formLoading={form.loading}
        loading={loading}
        currentPage={currentPage}
        totalPage={totalPage}
        displayShowMore={hasMorePages}
        list={clientFacturesPro}
        showMore={showMore}
      />
      <ModalEncart />
      <AutoPaiementModal />
    </>
  );
});

export default Billing;
