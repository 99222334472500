import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, useField, useFormik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  SzAlert,
  SzCheckbox,
  SzInput,
  SzRadioButton,
  SzSelect,
  SzTypographie
} from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { AppActions } from "../redux/actions";
import { getFormatedForm } from "../models";
import classnames from "classnames";

export function InputConstructor({
  schema,
  label,
  placeholder,
  className,
  info,
  onBlur = () => {},
  onChange = () => {},
  valid = false,
  ...props
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { resetError, startLoading } = AppActions;
  const [{ name, value, ...field }] = useField(props);
  const [changing, setChanging] = useState(null);
  const {
    values,
    errors,
    touched,
    setErrors,
    handleChange,
    setFieldTouched,
    setFieldError,
    validateField,
    validateForm,
    ...context
  } = useFormikContext();
  const classes = classnames("col-12 col-md-6", className);
  const appError = useSelector(({ app }) => app.error);
  const error = errors[name];
  const touch = touched[name];

  const removeError = (name, value) => {
    const { password, repeated_password, email, repeated_email } = values;
    const newErrors = { ...errors };

    if (name === "repeated_email" && value === email) {
      delete newErrors["repeated_email"];
    } else if (name === "password" && value === repeated_password) {
      delete newErrors["repeated_password"];
    } else if (name === "email" && value === repeated_email) {
      delete newErrors["repeated_email"];
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const customHandleChange = (e) => {
    const { name, value } = e.target;
    setChanging(true);
    if (name === "reference") {
      const last = value.substring(value.length - 1);
      const regEx = /(\d{1,12}|-|\.|,)/g;
      if (regEx.test(last) || values["reference"].length > value.length) {
        handleChange(e);
      }
    } else if (name === "invoiceNumber") {
      const regEx = /^\d{0,10}$/g;
      if (regEx.test(value)) handleChange(e);
    } else if (name === "amount") {
      const regEx = /^(-|-?\d+)?(\d+[.,](\d{1,2})?)?$/g;
      if (regEx.test(value)) handleChange(e);
    } else {
      handleChange(e);
    }

    if (!["repeated_email", "repeated_password"].includes(name)) {
      removeError(name, value);
    }
    if (appError) dispatch(resetError());
  };

  const customHandleBlur = (e) => {
    const { name, value } = e.target;
    setFieldTouched(name, true);
    setChanging(false);
    validateField(name);
    onBlur(e);
  };

  return (
    <div className={classes}>
      <SzInput
        {...props}
        valid={valid && changing !== name && !error && touch}
        value={value}
        error={touch && error}
        info={t(info)}
        label={t(label)}
        placeholder={t(placeholder)}
        onChange={customHandleChange}
        onBlur={customHandleBlur}
      />
      {value.length > 0 && error && touch && !changing && (
        <SzAlert type="danger">{t(error)}</SzAlert>
      )}
    </div>
  );
}

export function CheckBoxConstructor({ label, ...props }) {
  const { t } = useTranslation();
  const [{ name, value, ...field }] = useField(props);
  const {
    setFieldValue,
    values,
    validateForm,
    setFieldTouched,
    errors,
    setErrors,
    validateField,
    setFieldError,
    ...context
  } = useFormikContext();

  const removeError = (name) => {
    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);
  };

  const onChange = async (e) => {
    setFieldValue(name, e.target.checked);
    setFieldTouched(name, true);
    try {
      await validateField(name);
      removeError(name);
    } catch (error) {
      const { path, message } = error;
      setFieldError(path, message);
    }
  };

  return (
    <SzCheckbox
      {...props}
      type="checkbox"
      className="d-flex align-items-center mt-4 small pl-5"
      onChange={onChange}
      checked={value}
      label={<span dangerouslySetInnerHTML={{ __html: t(label) }} />}
    />
  );
}

export function RadioButtonConstructor({ schema, ...props }) {
  const [{ name, value, ...field }] = useField(props);
  const {
    values,
    errors,
    touched,
    setErrors,
    handleChange,
    setFieldTouched,
    setFieldError,
    setFieldValue,
    ...context
  } = useFormikContext();

  const removeError = (name) => {
    const { password, repeated_password, email, repeated_email } = values;
    const newErrors = { ...errors };
    delete newErrors[name];
    if (name === "password" && password === repeated_password) {
      delete newErrors["repeated_password"];
    }
    if (name === "email" && email === repeated_email) {
      delete newErrors["repeated_email"];
    }
    setErrors(newErrors);
  };

  const onBlur = async (e) => {
    const { name, value } = e.target;
    setFieldTouched(name, true);
    try {
      await schema.validateAt(name, { ...values, [name]: value });
      removeError(name);
    } catch (error) {
      const { path, message } = error;
      setFieldError(path, message);
    }
  };

  const onChange = (value) => {
    setFieldValue(name, value);
    removeError(name);
  };

  return (
    <SzRadioButton
      {...props}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}

export function SelectConstructor({
  placeholder,
  options,
  className,
  ...props
}) {
  const { t } = useTranslation();
  const [{ name, value, ...field }] = useField(props);
  const {
    setFieldValue,
    setFieldError,
    errors,
    validateField,
    setErrors,
    ...context
  } = useFormikContext();
  const classes = className || "col-12 col-md-6 pl-md-2";
  const error = errors[name];

  const removeError = (name) => {
    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);
  };

  const onChange = async (value, e) => {
    const { removedValue } = e;
    if (e.removedValue && e.removedValue.isFixed) return;
    setFieldValue(name, value);
    try {
      await validateField(name);
      removeError(name);
    } catch (error) {
      const { path, message } = error;
      setFieldError(path, message);
    }
  };

  return (
    <div className={classes}>
      {props.label && (
        <label
          className="sz-form-group__label sz-line-height-normal mb-1"
          htmlFor={props.name}
        >
          <SzTypographie color="inactive" weight="bold" tag="span">
            {t(props.label)}
          </SzTypographie>
        </label>
      )}

      <SzSelect
        placeholder={t(placeholder)}
        options={options}
        value={value}
        onChange={onChange}
        name={name}
        {...props}
      />
    </div>
  );
}

export default function FormConstructor({
  children,
  model,
  initialValues = {},
  className = "",
  ...props
}) {
  const [schema, values] = getFormatedForm(model, initialValues);
  const form = useRef();
  const classes = classnames("row", className);
  useEffect(() => {
    if (form.current) {
      form.current.validateForm();
    }
  }, []);
  return (
    <Formik
      {...props}
      innerRef={form}
      initialValues={values}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(props) => <Form className={classes}>{children({ ...props })}</Form>}
    </Formik>
  );
}
