import 'core-js/stable';
import 'regenerator-runtime/runtime';
import fromEntries from 'object.fromentries';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import moment from 'moment';
import 'moment/locale/fr';
import '@suezenv/react-theme-components/assets/css/main.css';

moment.locale('fr');
if (!Object.fromEntries) fromEntries.shim();

ReactDOM.render(<App />, document.getElementById('root'));
