import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import {
  SzSideMenu,
  SzIcon,
  SzTypographie
} from '@suezenv/react-theme-components';
import { Link, useLocation } from 'react-router-dom';
import useAdminServices from '../../hooks/useAdminServices';
import { useSelector } from 'react-redux';

export type MenuConf = {
  id: string;
  title: string;
  icon: string;
  link: string;
  className?: string;
  position: number;
}[];

export type MenuProps = {
  onClick(): void;
  isOpen: boolean;
};

const defaultMenu: MenuConf = [
  {
    id: 'dashboard',
    title: 'Tableau de bord',
    icon: 'layout-dashboard',
    link: '/tableau-de-bord',
    position: 1
  },
  {
    id: 'conso',
    title: 'Consommation',
    icon: 'analytics-bars-3d',
    link: '/espace-consommation',
    position: 2
  },
  {
    id: 'bills',
    title: 'Factures',
    icon: 'common-file-text',
    link: '/espace-facturation',
    position: 3
  }
];

export const Menu: FC<MenuProps> = ({ onClick, isOpen }) => {
  const { pathname } = useLocation();
  const asideClasses = classnames('tsme-aside connected', {
    'position-fixed': isMobile
  });
  const [menuState, setMenuState] = useState(defaultMenu);
  const [currentMenus, setCurrentMenus] = useState(defaultMenu);
  const isGranted = useAdminServices('GRAND_COMPTE');
  const [{ type }] = useSelector(({ app }: any) => [app.currentUser]);

  const [key, setKey] = useState<'granted' | 'ungranted'>('ungranted');

  let idsMenus = menuState.map((menu) => {
    return menu.id;
  });

  const addContactMenu = () => {
    if (!idsMenus.includes('contact') && isGranted) {
      const newMenu: any = [...menuState];

      setKey('granted');
      newMenu.push({
        id: 'contact',
        title: 'Contact',
        icon: 'messages-bubble-question',
        link: '/service-client',
        position: 4
      });

      newMenu.sort(function (a, b) {
        return a.position - b.position;
      });

      setMenuState(newMenu);
    }
  };
  const addAdminMenu = () => {
    const allowTypes = [
      'owner',
      'admin',
      'internal_adviser_admin',
      'internal_adviser_simple'
    ];

    if (!idsMenus.includes('admin') && allowTypes.includes(type)) {
      const newMenu: any = [...menuState];

      setKey('granted');
      newMenu.push({
        id: 'admin',
        title: 'Administrer',
        icon: 'single-neutral-actions-setting',
        link: type !== 'internal_adviser_simple' && '/espace-admin',
        position: 5
      });

      newMenu.sort(function (a, b) {
        return a.position - b.position;
      });

      setMenuState(newMenu);
    }
  };

  const addClassMTTop = (menu) => {
    const currentMenu = { ...menu };
    currentMenu['className'] = 'mt-auto';

    return currentMenu;
  };

  useEffect(() => {
    addContactMenu();
  }, [isGranted]);

  useEffect(() => {
    addAdminMenu();
  }, [type]);

  useEffect(() => {
    const updateMenu = menuState.map((menu) => {
      if (!idsMenus.includes('contact') && menu.id == 'admin') {
        return addClassMTTop(menu);
      }

      if (!idsMenus.includes('admin') && menu.id == 'contact') {
        return addClassMTTop(menu);
      }

      if (
        idsMenus.includes('admin') &&
        idsMenus.includes('contact') &&
        menu.id == 'contact'
      ) {
        return addClassMTTop(menu);
      }

      return menu;
    });

    setCurrentMenus(updateMenu);
  }, [menuState]);

  const ItemList = currentMenus.map(({ link, icon, title, className }) => {
    return (
      <SzSideMenu.Item
        key={title}
        active={link === pathname}
        className={className}
        onClick={onClick}
      >
        <Link className="w-100 h-100 d-flex align-items-center" to={link}>
          <SzIcon icon={icon} variant="line" />
          <SzTypographie
            weight="medium"
            tag="span"
            className="sz-aside__item--title"
          >
            {title}
          </SzTypographie>
        </Link>
      </SzSideMenu.Item>
    );
  });

  const footer = isOpen ? 'Tout Sur Mon Eau' : 'TSME';

  return (
    <SzSideMenu
      key={key}
      className={asideClasses}
      isOpen={isOpen}
      footer={footer}
    >
      {ItemList}
    </SzSideMenu>
  );
};

export default Menu;
