import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '../../themes';
import { Stepper } from '@suezenv/tsme-react-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from '../../services/Request';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GetRoleInfo, { Role } from '../../services/Role';
import { useParams, useHistory } from 'react-router-dom';

export const InitPaymentAuto = () => {
  const { item } = useSelector((app: any) => app.contract);
  const history = useHistory();

  if (!item) {
    history.push('/espace-facturation');
  }

  const { reference, roles } = item || {};

  interface paramsInitPaymentAuto {
    status: string;
    fullRef: string;
    transactionid: string;
  }

  const { status, fullRef, transactionid }: paramsInitPaymentAuto = useParams();

  useEffect(() => {
    const isPrelevEnabled = GetRoleInfo(roles as Role[], 'PRELEVEMENT').enabled;

    if (!isPrelevEnabled) {
      history.push('/espace-facturation');
    }
  });

  const SUCCESS_CODE = '00';
  const typeAction = 'P';

  const { t } = useTranslation('pages');

  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const [loaderSubmit, setLoaderSubmit] = useState(false);
  const [loaderDlPdf, setLoaderDlPdf] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  const [activeStep, setActiveStep] = useState(status ? 3 : 0);
  const { logo, brand } = useTheme();

  const getData = async () => {
    const dataGetIban = {
      params: {
        enable_tracking: 1,
        action: typeAction
      }
    };

    const { data } = await axios.get(
      `/api/contract/iban/${reference}`,
      dataGetIban
    );
    return data;
  };

  const { data: ibanData } = useQuery('get_iban', getData, {
    initialData: {},
    refetchOnWindowFocus: false,
    retry: 2,
    onSuccess: (response) => {
      if (response.code !== SUCCESS_CODE) {
        history.push('/espace-facturation');
      } else {
        setShowLoadingModal(false);
      }
    },
    onError: () => {
      history.push('/espace-facturation');
    }
  });

  const callPostIban = () => {
    let temp = {
      reference: ibanData?.reference,
      holder: ibanData?.titulaire,
      iban: ibanData?.iban
    };

    if (ibanData?.has_mandate === false && ibanData?.mobile) {
      temp['mobile_phone'] = ibanData?.mobile;
    }

    onSubmit(temp);
  };

  const onSubmit = (dataForm) => {
    setLoaderSubmit(true);
    setMessageSuccess('');
    setMessageError('');

    const currentDataForm = {... dataForm};

    currentDataForm.action = typeAction;
    currentDataForm.reference = reference;

    currentDataForm.has_mandate = ibanData?.has_mandate === true ? 1 : 0;

    if (currentDataForm?.civility) {
      currentDataForm.civility = currentDataForm.civility.value;
    }

    let newIban = '';

    for (let index = 1; index < 8; index++) {
      if (currentDataForm[`iban_field_${index}`]) {
        newIban += currentDataForm[`iban_field_${index}`];
        delete currentDataForm[`iban_field_${index}`];
      }
    }

    currentDataForm.iban = newIban || ibanData.iban;

    if (currentDataForm.company_name) {
      currentDataForm['last_name'] = currentDataForm.company_name;
      delete currentDataForm.company_name;
    }

    postIban.mutate(currentDataForm);
  };

  const postIban = useMutation(
    (value) => axios.post(`/api/contract/iban/${reference}`, value),
    {
      onSuccess: (response) => {
        const { data } = response;
        manageResponseApi(data);
      },
      onError: () => {
        setMessageError(t('components:errors.techError'));
        setLoaderSubmit(false);
      }
    }
  );

  const manageResponseApi = (response) => {
    setLoaderSubmit(false);

    if (response.code == SUCCESS_CODE) {
      if (response.urlSEPA) {
        window.location.href = response.urlSEPA;
      } else {
        setActiveStep(3);
      }
    } else {
      manageError(response);
    }
  };

  const manageError = (response) => {
    const fieldsNoCheckValidationError = [
      'holder',
      'reference',
      'action',
      'has_mandate'
    ];

    const validationErrors = response?.validation_errors;

    let messageError = clsx(response?.message, ' ');
    let messageErrorsFields = '';

    for (var key in validationErrors) {
      if (fieldsNoCheckValidationError.includes(key)) {
        continue;
      }

      if (validationErrors.hasOwnProperty(key)) {
        messageErrorsFields += clsx(validationErrors[key], ' ');
      }
    }

    if (messageErrorsFields !== '') {
      messageError = messageErrorsFields;
    }

    setMessageError(messageError);
  };

  let args = {
    dataUser: [ibanData?.name, ibanData?.reference, ibanData?.site_address],
    currentBrandCode: brand,
    srcLogo: logo,
    step: 0,
    address: ibanData?.site_address,
    price: ibanData?.balance ? ibanData.balance + ' €' : '',
    titulaire: ibanData?.titulaire,
    isMandat: ibanData?.has_mandate,
    status,
    onSubmit,
    goToNextStep: () => {}
  };

  if (ibanData?.iban) {
    args['iban'] = ibanData.iban;
  }

  const dlPdfMandat = async () => {
    setLoaderDlPdf(true);
    try {
      const { data } = await axios.get(
        `/api/contract/sepamandate/download/${fullRef}/sps`,
        {
          responseType: 'blob'
        }
      );
      setLoaderDlPdf(false);
      window.open(URL.createObjectURL(data));
    } catch (error) {
      setMessageError(t('components:errors.techError'));
    }
  };

  const toDashoard = () => {
    history.push('/');
  };

  const changePaymentMethod = () => {
    history.push('/espace-facturation');
  };

  const showOtherBill = () => {};

  

  return (
    <Stepper
      {...args}
      fromPlatform={'beta'}
      modeOfPayment={'paymentAuto'}
      activeStep={activeStep}
      showLoadingModal={showLoadingModal}
      isFooter={false}
      destClose={'/espace-facturation'}
      loaderSubmit={loaderSubmit}
      loaderDlPdf={loaderDlPdf}
      messageSuccess={messageSuccess}
      messageError={messageError}
      dlPdfMandat={dlPdfMandat}
      callPostIban={callPostIban}
      onSubmit={onSubmit}
      toDashoard={toDashoard}
      changePaymentMethod={changePaymentMethod}
      showOtherBill={showOtherBill}
      goPreviousStep={() => {}}
    />
  );
};
