import { PRODUCT } from '../types';
import { ProductActionTypes } from '../types/product';

export const initialState = {
  showSuccess: false,
  showForm: false
};

export default function product(state = { ...initialState }, action: ProductActionTypes) {
  switch (action.type) {
    case PRODUCT.CONTACT_REQUEST:
        return state
    case PRODUCT.SHOW_SUCCESS:
        return {
          ...state,
          showSuccess: true
        }
    case PRODUCT.HIDE_SUCCESS:
      return {
        ...state,
        showSuccess: false
      }
    case PRODUCT.SHOW_FORM:
        return {
          ...state,
          showForm: true
        }
    case PRODUCT.HIDE_FORM:
      return {
        ...state,
        showForm: false
      }
    default:
        return state
  }
}
