export default {
  LIST: {
    SET: 'CONTRACT_SET_LIST',
    UPDATE: 'CONTRACT_UPDATE_LIST',
    MEMORY: 'CONTRACT_SET_MEMORY'
  },
  ITEM: {
    SET: 'CONTRACT_SET_ITEM',
    UPDATE: 'CONTRACT_UPDATE_ITEM'
  },
  LOADING: {
    START: 'CONTRACT_START_LOADING',
    STOP: 'CONTRACT_STOP_LOADING'
  },
  ERROR: {
    SET: 'CONTRACT_SET_ERROR',
    RESET: 'CONTRACT_RESET_ERROR'
  },
  MODAL: {
    OPEN: 'CONTRACT_OPEN_MODAL',
    CLOSE: 'CONTRACT_CLOSE_MODAL',
    SET_ACTIVE_FORM: 'CONTRACT_SET_ACTIVE_FORM_MODAL',
    RESET_ACTIVE_FORM: 'CONTRACT_RESET_ACTIVE_FORM_MODAL',
    SET_CONTENT: 'CONTRACT_SET_CONTENT_MODAL',
    RESET_CONTENT: 'CONTRACT_RESET_CONTENT_MODAL'
  },
  FORM: {
    LOADING: {
      START: 'CONTRACT_FORM_START_LOADING',
      STOP: 'CONTRACT_FORM_STOP_LOADING'
    },
    ERROR: {
      SET: 'CONTRACT_FORM_SET_ERROR',
      RESET: 'CONTRACT_FORM_RESET_ERROR'
    }
  }
};
