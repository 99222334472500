import React from "react";
import {
  SzButton,
  SzModal,
  SzTypographie,
} from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import IdSuccessImg from "../../assets/img/id-success.svg";

export default function IdentityModal({ show, onClick, handleClose }) {
  const { t } = useTranslation();

  return (
    <SzModal show={show} title="" size="xl" handleClose={handleClose}>
      <div className="row my-3">
        <div className="col-12 col-lg-8">
          <SzTypographie variant="huge" weight="medium">
            {t("customerService.assistance.modal.title")}
          </SzTypographie>
          <SzTypographie>
            {t("customerService.assistance.modal.info1")}
          </SzTypographie>
          <SzTypographie>
            {t("customerService.assistance.modal.info2")}
          </SzTypographie>
          <div className="mt-5">
            <SzButton className="mr-4 px-5" onClick={handleClose}>
              {t("customerService.assistance.modal.validate")}
            </SzButton>
            <SzButton variant="secondary" onClick={onClick}>
              {t("customerService.assistance.modal.cancel")}
            </SzButton>
          </div>
        </div>
        <div className="col-md-4 d-none d-lg-block">
          <img src={IdSuccessImg} alt="id-success" className="mx-5" />
        </div>
      </div>
    </SzModal>
  );
}
