import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import app from "./app";
import signup from "./signup";
import billing from "./billing";
import contract from "./contract";
import consumption from "./consumption";
import product from "./product";
import customer from "./customer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    signup,
    billing,
    contract,
    consumption,
    product,
    customer,
  });
