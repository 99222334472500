import React, { FC, useEffect } from 'react';
import { ConfirmationProps } from '@suezenv/tsme-react-components/build/Page/paiment-etip/Confirmation';
import { useParams } from 'react-router-dom';
import axios from '../../services/Request';

export const DownloadMandat = () => {
  const { reference, type }: any = useParams();

  const dlPdfMandat = async () => {
    try {
      const { data } = await axios.get(
        `/api/contract/sepamandate/download/${reference}/${type}`,
        {
          responseType: 'blob'
        }
      );

      window.location.assign(URL.createObjectURL(data));
    } catch (error) {
      window.location.href = '/erreur/connexion';
    }
  };

  useEffect(() => {
    dlPdfMandat();
  });

  return null;
};
