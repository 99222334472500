import React, { useState, FC } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { ConnectedHeader as Header } from "../Header";
import { isMobile } from "react-device-detect";
import "./container.scss";
import Menu from "../Menu";

const readIsOpenStorage =
  String(localStorage.getItem("isOpen")).toLowerCase() == "true";

export const Container: FC = ({ children }) => {
  const connected = useSelector<any, any>(({ app }) => app.logged);
  const [isOpen, setIsOpen] = useState(isMobile ? false : readIsOpenStorage);

  const containerClasses = classnames(
    "tsme-container col overflow-auto p-3 h-100",
    {
      connected,
      isOpen: connected && isOpen,
    }
  );

  const toggleMenu = () => {
    setIsOpen((prev) => {
      localStorage.setItem("isOpen", prev ? "false" : "true");
      return !prev;
    });
  };

  const onClickMenu = () => {
    isMobile && setIsOpen(!isOpen);
  };

  return (
    <>
      <Header onClick={toggleMenu} isOpen={isOpen} />
      <div
        className="row no-gutters position-relative"
        style={{
          height: "calc(100% - 64px)",
        }}
      >
        <Menu onClick={onClickMenu} isOpen={isOpen} />
        <div className={containerClasses}>{children}</div>
      </div>
    </>
  );
};

export default Container;
