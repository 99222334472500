import * as Yup from 'yup';

export function getFormatedForm(model, defaultValues = {}) {
  const schema = Yup.object().shape(
    Object.fromEntries(
      Object.entries(model).map((value, index) => {
        return [value[0], value[1].schema];
      })
    )
  );
  const initialValues = Object.fromEntries(
    Object.entries(model).map((value, index) => [
      value[0],
      defaultValues[value[0]] == null ? '' : defaultValues[value[0]]
    ])
  );
  return [schema, initialValues];
}

export { default as SignupModel } from './Signup';
export { default as ContactModel } from './Contact';
export { default as AddContractModel } from './AddContract';
export { NameModel, EmailModel, PasswordModel } from './Profile';
export { default as ContractsModel } from './Contracts';
export {
  billing as BillingSearchModel,
  conso as ConsumptionSearchModel
} from './Search';
export { default as FilterContract } from './FilterContract';
