import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SzButton, SzDropDown, SzTypographie } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import DetailCard from './DetailCard';
import Histogram from './histogram';
import HistogramTr from './histogramTr';
import { ConsumptionActions } from '../../redux/actions';
import { useParams } from 'react-router-dom';
import { hideInEnv } from '../../services/Utils';

export default function Details() {
  const { t } = useTranslation('pages');
  const {
    FetchNonTelemetry,
    FetchTelemetry,
    fetchConsumption
  } = ConsumptionActions;
  const { clientCompteursPro } = useSelector(({ consumption }) => consumption);
  const dispatch = useDispatch();

  const { codeEquipement, reference, idPDS } = useParams();
  const item =
    clientCompteursPro &&
    clientCompteursPro.find(item => item.reference === reference);
  const hideInPreprodProd = hideInEnv('prod', 'preprod');
  useEffect(() => {
    const data = { reference, id_PDS: idPDS };
    const ToFetch = codeEquipement === 'TR' ? FetchTelemetry : FetchNonTelemetry;
    dispatch(
      fetchConsumption({
        ...data,
        currentPage: 1,
        itemsPerPage: 5
      })
    );
    dispatch(ToFetch(data))
  }, []);

  return (
    <div className="row no-gutters">
      <div className="col-12 pb-4">
        <Link to="/espace-consommation">
          <SzButton variant="tertiary" className="mr-3" icon="arrow-left">
            {t('consumption.previous')}
          </SzButton>
        </Link>
      </div>
      {!hideInPreprodProd && (
        <div className="col-12 bg-white pl-4 pr-3 py-3 mb-3 d-flex align-items-center">
          {t('consumption.coachConso')}
          <SzButton className="ml-auto">
            {t('consumption.activerCoachConso')}
          </SzButton>
        </div>
      )}
      <div className="col-12 col-xl-4 mb-3">
        <DetailCard {...item} />
      </div>
      <div className="col-12 col-xl-8 ml-auto">
        {codeEquipement === 'TR' ? <HistogramTr /> : <Histogram />}
      </div>
    </div>
  );
}
