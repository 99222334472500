import React, { FC } from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import ErrorImg from "../../assets/error/404-error.svg";
import severError from "../../assets/error/server-error.svg";
import AccessDenied from "../../assets/error/vip-access.svg";
import noResult from "../../assets/error/no-result.svg";

interface ErrorProps {
  title: string;
  subtitle?: string;
  errorType: "not-found" | "no-result" | "server-error" | "access-denid";
  searchError?: boolean;
}

export const Error: FC<ErrorProps> = ({
  children,
  title,
  subtitle,
  errorType,
  searchError,
}) => {
  let errorImage = "";
  switch (errorType) {
    case "not-found":
      errorImage = ErrorImg;
      break;
    case "no-result":
      errorImage = noResult;
      break;
    case "server-error":
      errorImage = severError;
      break;
    case "access-denid":
      errorImage = AccessDenied;
      break;
    default:
      errorImage = ErrorImg;
  }
  return searchError ? (
    <div className="h-100 pt-2 tsme-content-container m-auto bg-white error-container no-result">
      <div className="row justify-content-center">
        <div className="col-4">
          <img src={errorImage} alt={errorType} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-8 pt-1">
          <SzTypographie weight="medium" className="text-center">
            {title}
          </SzTypographie>
          {children}
        </div>
      </div>
    </div>
  ) : (
    <div className="row h-100 pt-5 tsme-content-container m-auto error-container">
      <div className="col-8 pt-5">
        <SzTypographie variant="huge">{title}</SzTypographie>
        <SzTypographie weight="medium">{subtitle}</SzTypographie>
        {children}
      </div>
      <div className="col-4">
        <img src={errorImage} alt={errorType} />
      </div>
    </div>
  );
};

export default Error;
