import { SzTypographie } from '@suezenv/react-theme-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmailSuccess from '../../assets/img/e-mail-success.svg';

export const ConfirmationRoles = () => {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-8 my-4">
        <SzTypographie variant="large" className="mb-3" weight="bold">
          {t('adminSpace.confirmationModal.title')}
        </SzTypographie>
        <SzTypographie
          weight="bold"
          color="disabled"
          variant="caption"
          size="M"
        >
          {t('adminSpace.confirmationModal.text1')}
        </SzTypographie>
        <SzTypographie
          weight="bold"
          color="disabled"
          variant="caption"
          size="M"
        >
          {t('adminSpace.confirmationModal.text2')}
        </SzTypographie>
      </div>
      <div className="col-4 my-auto">
        <img src={EmailSuccess} alt="" className="w-75" />
      </div>
    </div>
  );
};

export default ConfirmationRoles;
