import * as Yup from 'yup';
import './validators';

export default {
  civility: {
    schema: Yup.string()
      .matches(/(mr|miss)/, 'models:profile.civility.match')
      .required('models:profile.civility.required'),
    choices: [
      {
        label: 'Madame',
        value: 'miss'
      },
      {
        label: 'Monsieur',
        value: 'mr'
      }
    ]
  },
  first_name: {
    label: 'models:profile.first_name.label',
    type: 'text',
    icon: 'pencil-write',
    placeholder: 'models:profile.first_name.placeholder',
    schema: Yup.string()
      .min(1, 'models:profile.first_name.required')
      .matches(/^\D+$/, 'models:profile.first_name.match')
      .required('models:profile.first_name.required')
  },
  last_name: {
    label: 'models:profile.last_name.label',
    placeholder: 'models:profile.last_name.placeholder',
    type: 'text',
    icon: 'pencil-write',
    schema: Yup.string()
      .matches(/^\D+$/, 'models:profile.last_name.match')
      .required('models:profile.last_name.required')
  },
  email: {
    label: 'models:profile.email.label',
    placeholder: 'models:profile.email.placeholder',
    type: 'email',
    icon: 'envelope',
    schema: Yup.string()
      .min(1, 'models:profile.repeated_email.required')
      .email('models:profile.email.error')
      .required('models:profile.email.required')
  },
  repeated_email: {
    label: 'models:profile.repeated_email.label',
    placeholder: 'models:profile.repeated_email.placeholder',
    type: 'email',
    icon: 'envelope',
    schema: Yup.string()
      .min(1, 'models:profile.repeated_email.required')
      .oneOf([Yup.ref('email')], 'models:profile.repeated_email.error')
      .required('models:profile.repeated_email.required')
  },
  password: {
    label: 'models:profile.password.label',
    placeholder: 'models:profile.password.placeholder',
    type: 'password',
    icon: 'key-lock-1',
    schema: Yup.string()
      .min(8, 'models:profile.password.min')
      .isPassword('models:profile.password.password')
      .required('models:profile.password.required')
  },
  repeated_password: {
    label: 'models:profile.repeated_password.label',
    placeholder: 'models:profile.repeated_password.placeholder',
    type: 'password',
    icon: 'key-lock-1',
    schema: Yup.string()
      .oneOf([Yup.ref('password')], 'models:profile.repeated_password.min')
      .required('models:profile.repeated_password.required')
  },
  cgu: {
    label: 'models:signup.cgu',
    schema: Yup.bool()
      .oneOf([true], "Veuillez accepter les CGU's")
      .required("Veuillez accepter les CGU's")
  }
};
