export const releveeSort = ({ volume, date, index }: any) => {
  return {
    y: volume,
    name: date || '',
    lastIndex: index
  };
};

export const isTR = (toolCode: string, hasRoleTR: boolean) => {
  return toolCode === 'TR' && hasRoleTR;
};
