import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppActions } from '../redux/actions';
import instance from './Request';

export default function Authenticate(props) {
  const dispatch = useDispatch();
  const { tokenCheck } = AppActions;
  const location = new URL(window.location.href);
  const token = location.searchParams.get('token');
  const RelayState = location.searchParams.get('RelayState');

  const isValid = useSelector(({ app }) => app.token_valid);
  const { pathname } = RelayState ? new URL(RelayState) : { pathname: null };
  const redirectTo = pathname || '/espace-personnel';

  useEffect(() => {
    if (!token) props.history.push('/erreur/connexion');
    else {
      localStorage.setItem('token', token);
      instance.defaults.headers.Authorization = `Bearer ${token}`;
      dispatch(tokenCheck());
    }
  }, []);

  return isValid ? <Redirect to={redirectTo} /> : null;
}
