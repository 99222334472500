import React, { FC } from 'react';
import {
  SzButton,
  SzDropDown,
  SzHeader,
  SzTypographie
} from '@suezenv/react-theme-components';
import classnames from 'classnames';
import { ThemeSelector } from '../../themes';
import LogoLink from './Logo';
import UserIcon from '../../assets/img/avatar.svg';
import { Link } from 'react-router-dom';
import { hideInEnv, isAdminType } from '../../services/Utils';
import { useSelector } from 'react-redux';
import './index.scss';

export type ConnectedHeaderProps = {
  onClick(): void;
  isOpen: boolean;
};

export const Connected: FC<ConnectedHeaderProps> = ({ onClick, isOpen }) => {
  const hideInPreprodProd = hideInEnv('prod', 'preprod');
  const [userType] = useSelector(({ app }: any) => [
    app.loading,
    app.currentUser.contracts_status,
    app.currentUser.type
  ]);

  const menuOpt = [
    {
      content: 'Espace personnel',
      link: '/espace-personnel',
      icon: 'single-neutral-id-card-3'
    },
    {
      content: 'Administrer',
      link: '/espace-admin',
      icon: 'single-neutral-actions-setting',
      isAdmin: true
    },
    {
      content: 'Conditions d\'utilisation',
      link: '/cgu',
      icon: 'layout-bullets'
    },
    {
      content: 'Se déconnecter',
      link: '/logout',
      icon: 'logout-2'
    }
  ];

  const defaultMenuOpt = (
    <>
      <img
        src={UserIcon}
        width="40px"
        height="40px"
        className="mr-3"
        alt="User icon"
      />
      <SzTypographie tag="span" className="d-none d-sm-inline" weight="bold">
        Mon compte
      </SzTypographie>
    </>
  );

  return (
    <SzHeader className="tsme-header d-flex align-items-stretch justify-content-between">
      <div
        className="d-flex align-items-center cursor-pointer border-right"
        style={{ padding: '0 0.4rem' }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <SzButton
          variant="tertiary"
          icon={classnames({ 'navigation-menu': !isOpen, remove: isOpen })}
          className="w-100"
        />
      </div>
      <LogoLink />
      <SzTypographie
        tag="span"
        className="d-none d-md-flex align-items-center ml-auto px-3 border-right"
        color="inactive"
      >
        TSME (version pro)
      </SzTypographie>
      <SzDropDown
        alignRight
        className="tsme-header-dropdown d-md-flex justify-content-around align-items-stretch py-2 mx-md-2"
        value={defaultMenuOpt}
      >
        {menuOpt.map(({ link, icon, content, isAdmin }) => {
          if (isAdmin && !isAdminType(userType)) {
            return;
          }
          return (
            <SzDropDown.Item key={link} icon={icon}>
              <Link to={link} className="d-flex align-items-center">
                {content}
              </Link>
            </SzDropDown.Item>
          );
        })}
      </SzDropDown>
      {!hideInPreprodProd && <ThemeSelector />}
    </SzHeader>
  );
};

export default Connected;
