export type CustomerType =
  | "setActiveBTn"
  | "setCurrentTitleObject"
  | "setRequirements"
  | "setRequirementSelected"
  | "setHasSuccessSubmit"
  | "reset";

export type CustomerAction = {
  type: CustomerType;
  payload: CustomerState[keyof CustomerState];
};

export type CustomerState = {
  activeBtn: boolean[];
  hasSuccessSubmit: boolean;
  currentTitleObject: string;
  currentCodeObject: string;
  requirements: RequirementType[];
  requirementSelected: RequirementType | null;
};

export type RequirementType = {
  title?: string;
  requirement: string;
  formType: string;
  description: string;
  filling: string;
};

const initialState: CustomerState = {
  activeBtn: [],
  currentTitleObject: "",
  hasSuccessSubmit: false,
  currentCodeObject: "",
  requirements: [],
  requirementSelected: null,
};

export default (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "setActiveBTn":
      return { ...state, activeBtn: action.payload };
    case "setCurrentTitleObject":
      return { ...state, currentTitleObject: action.payload };
    case "setHasSuccessSubmit":
      return { ...state, hasSuccessSubmit: action.payload };
    case "setCurrentCodeObject":
      return { ...state, currentCodeObject: action.payload };
    case "setRequirements":
      return { ...state, requirements: action.payload };
    case "setRequirementSelected":
      return { ...state, requirementSelected: action.payload };
    case "reset":
      return { ...initialState };
    default:
      return state;
  }
};
