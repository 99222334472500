import React, { useState } from "react";
import { SzAlert } from "@suezenv/react-theme-components";
import { Form, Input } from "@suezenv/tsme-react-components";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import Capaddress, {
  SearchItemType,
  SearchType,
} from "../../../components/Capaddress";
import { AssistanceModel } from "../../../models/Assistance";
import axios from "../../../services/Request";
import Telephone from "./Telephone";

type params = {
  insee: string;
  hexaviaCode?: string;
  streetNumber?: string;
  type: SearchType;
};

export default function Assistance() {
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState<SearchItemType>();
  const [selectedField, setSelectedField] = useState(null);
  const [displayStreet, setDisplayStreet] = useState(false);
  const { location, address } = AssistanceModel;
  const defaultData = [
    { horaireCrc: null, telCrcUrgence: null, telCrcJour: null },
  ];

  const getMuni: (infos: params) => any = async ({ type, ...infos }) => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(infos)) {
      params.append(key, value);
    }
    const url = `/api/municipality/contract/contact?${params.toString()}`;
    try {
      const response = await axios.get(url);
      const { listeContacts } = response.data;
      setDisplayStreet((prev) => {
        if (prev && type !== "street") return false;
        return prev;
      });
      return listeContacts;
    } catch (error: any) {
      const { data } = error.response;
      if (data.code === "15") {
        setDisplayStreet(true);
      }
      throw data;
    }
  };

  const mutation: UseMutationResult<AxiosResponse, AxiosError, params> =
    useMutation(getMuni);

  const {
    isSuccess,
    isError,
    isIdle,
    data = defaultData,
    error,
    isLoading,
  } = mutation;

  const { horaireCrc, telCrcUrgence, telCrcJour } = (
    isError ? defaultData : data
  )[0];

  const codeErrorsToDisplay = ["16", "17", "03"];

  return (
    <div className="bg-white px-3 mb-5">
      <Form
        initialValues={{}}
        onSubmit={() => {}}
        validationSchema={AssistanceModel}
        className="no-gutters"
      >
        {({ values, setFieldValue }) => {
          const onChange = ({ target }) => {
            setSelectedField(target.name);
          };

          const onClick = (item: SearchItemType, type: SearchType) => {
            const {
              locality,
              localityId: insee,
              streetId: hexaviaCode,
              streetNumber,
              inputOutput,
            } = item;
            setSelectedField(null);
            if (type === "town") {
              setFieldValue("location", locality);
              setSelectedAddress(item);
              mutation.mutate({ insee, type });
            }
            if (type === "street") {
              setFieldValue("address", inputOutput);
              mutation.mutate({ insee, type, hexaviaCode, streetNumber });
            }
          };
          return (
            <>
              <div className="col-5 mb-2">
                <Input
                  {...location}
                  onChange={onChange}
                  className="cy-capaddress-location"
                  label={t("customerService.assistance.form.location.label")}
                  placeholder={t(
                    "customerService.assistance.form.location.placeholder"
                  )}
                />
                {values.location.length >= 3 &&
                  selectedField === "location" && (
                    <Capaddress
                      type="town"
                      value={values.location}
                      onClick={onClick}
                    />
                  )}
              </div>
              {displayStreet && (
                <div className="col-12 mb-2">
                  <Input
                    {...address}
                    className="cy-capaddress-address"
                    onChange={onChange}
                    label={t("customerService.assistance.form.address.label")}
                    placeholder={t(
                      "customerService.assistance.form.address.placeholder"
                    )}
                  />
                  {values.address.length >= 3 &&
                    selectedField === "address" && (
                      <Capaddress
                        type="street"
                        address={selectedAddress}
                        value={values.address}
                        onClick={onClick}
                      />
                    )}
                </div>
              )}
            </>
          );
        }}
      </Form>

      {error && error.code && codeErrorsToDisplay.includes(error.code) && (
        <SzAlert>{error.message}</SzAlert>
      )}

      {!isIdle && !isError && (
        <Telephone
          loading={isLoading}
          horaireCrc={horaireCrc}
          telCrcUrgence={telCrcUrgence}
          telCrcJour={telCrcJour}
        />
      )}
    </div>
  );
}
