import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  SzModal,
  SzList,
  SzTypographie,
  SzIcon,
  SzSpinner,
  SzButton
} from '@suezenv/react-theme-components';
import { AppActions, BillingActions } from '../../../redux/actions/';

export default function ModalListInvoice({ match }) {
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();
  const { closeModal } = AppActions;
  const { downloadEncarts } = BillingActions;
  const show = useSelector(({ app }) => app.modal);
  const { listsEncarts, encartInfos, loading, encartLoading } = useSelector(
    ({ billing }) => billing
  );

  const downloadEncart = insertDownloadCode =>
    dispatch(downloadEncarts({ ...encartInfos, insertDownloadCode }));

  const handleClose = () => dispatch(closeModal());

  const List = () => (
    <SzList>
      {listsEncarts.map(({ lbEncart, urlEncart }, index) => (
        <SzList.Item key={index} className="d-flex align-items-center">
          <SzIcon
            icon="attachment"
            variant="line"
            className="text-muted mr-2"
          />
          {lbEncart}
          <SzButton
            variant="tertiary"
            className="insert-download-btn ml-auto"
            icon="download-bottom"
            loader={encartLoading}
            onClick={() => downloadEncart(urlEncart)}
          />
        </SzList.Item>
      ))}
    </SzList>
  );

  return (
    <SzModal
      title={t('billing.invoiceModalTitle')}
      show={show}
      handleClose={handleClose}
    >
      <SzTypographie>{t('billing.invoiceModalSubTitle')}</SzTypographie>
      {loading ? (
        <div className="text-center">
          <SzSpinner />
        </div>
      ) : listsEncarts.length > 0 ? (
        <List />
      ) : (
        <SzTypographie type="label" className="text-center my-3">
          {t('billing.modals.noEncartMessage')}
        </SzTypographie>
      )}
    </SzModal>
  );
}
