import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SzTypographie } from "@suezenv/react-theme-components";
import FormToDisplay from "./Forms";
import Requirements from "./Requirements";
import ObjectList from "./ObjectList";
import useScrollTo from "../../hooks/useScrollTo";
import { useAdminServices } from "../../hooks";
import Confirmation from "./Confirmation";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

export default function CustomerService() {
  const { t } = useTranslation();
  const canRender = useAdminServices("GRAND_COMPTE", "/");
  const scroll = useScrollTo({ behavior: undefined });
  const { hasSuccessSubmit } = useAppSelector(({ customer }) => customer);

  useEffect(() => {
    scroll(0);
  }, [hasSuccessSubmit]);

  if (!canRender) {
    return null;
  }

  return (
    <main className="container">
      {hasSuccessSubmit ? (
        <Confirmation />
      ) : (
        <>
          <div className="row">
            <SzTypographie weight="bold" className="text-center col-12 mt-3">
              {t(`customerService.subtileObject`)}
            </SzTypographie>
          </div>

          <ObjectList />

          <Requirements />

          <FormToDisplay />
        </>
      )}
    </main>
  );
}
