import React, { useEffect } from 'react';
import { SzButton, SzSearchBar } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FilterContract, getFormatedForm } from '../../models';
import { AppActions, ContractActions } from '../../redux/actions';
import { Formik } from 'formik';

export default function FilterBox() {
  const [schema, initialValues] = getFormatedForm(FilterContract);
  const { t } = useTranslation('pages', 'models');
  const dispatch = useDispatch();
  const { openModal } = AppActions;
  const {
    contractFilter,
    initContractFilter,
    contractModalSetActiveForm
  } = ContractActions;
  const onClickModal = () => {
    dispatch(openModal());
    dispatch(contractModalSetActiveForm('addContract'));
  };
  const onSubmit = form => dispatch(contractFilter(form));
  const handleClear = () => dispatch(initContractFilter());

  useEffect(() => {
    return () => {
      dispatch(initContractFilter());
    };
  }, []);

  return (
    <div className="tsme-personal-space__actions d-flex flex-column flex-md-row align-items-center justify-content-between mb-4">
      <Formik
        enableReinitialize
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ handleSubmit, setFieldValue, values, submitForm }) => {
          const { filter_field } = FilterContract;
          const onClick = inputValue => {
            setFieldValue('filter_field', inputValue);
            submitForm();
          };
          return (
            <form onSubmit={handleSubmit} className="col-12 p-0">
              <div className="row tsme-personal-space__search-block">
                <div className="col-12 col-md-5">
                  <SzSearchBar
                    placeholder={t(filter_field.placeholder)}
                    onClick={onClick}
                    handleClear={handleClear}
                    icon
                  />
                </div>
                <div className="ml-auto mr-3">
                  <SzButton icon="add-square" onClick={onClickModal}>
                    {t('models:links.addContract')}
                  </SzButton>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
