import React from 'react';
import { Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Route from './Route';
import PrivateRoute from './PrivateRoute';
import { useTheme } from '../themes';
import { privateRoutes, publicRoutes } from './routes';

export function CustomHead() {
  const { title, favicon } = useTheme();

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
  );
}

export default function Router() {
  return (
    <>
      <CustomHead />
      <Switch>
        {privateRoutes.map((props) => (
          <PrivateRoute key={props.path} {...props} />
        ))}
        {publicRoutes.map((props) => (
          <Route key={props.path || '/'} {...props} />
        ))}
      </Switch>
    </>
  );
}
