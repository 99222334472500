import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SzAlert, SzButton } from '@suezenv/react-theme-components';
import { AppActions } from '../../redux/actions';
import { NameModel } from '../../models';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import FormConstructor, {
  InputConstructor,
  RadioButtonConstructor,
  SelectConstructor
} from '../FormConstructor';
import { usePages } from '../../hooks';
import { departmentCodeList } from '../../utils';
import useCheckTypeUser from '../../hooks/useCheckTypeUser';

export default function NameForm(props) {
  const { handleClose } = props;
  const { t } = useTranslation('models');
  const dispatch = useDispatch();
  const isProfil = usePages('renseignement-profil');
  const [user, isLoading, error] = useSelector(({ app }) => [
    app.currentUser,
    app.form.loading,
    app.form.error
  ]);

  const isAdviserSimpleUser = useCheckTypeUser('internal_adviser_simple');
  const isInternAdminUSer = useCheckTypeUser('internal_adviser_admin');

  const { updateName, formResetError } = AppActions;

  const onSubmit = (form) => {
    dispatch(updateName(form));
    if (handleClose) handleClose();
  };
  const { civility, first_name, last_name, entity_name, department_code } =
    NameModel;

  const { ...initialValues } = user;

  if (user.department_code) {
    if (typeof user.department_code === 'object') {
      initialValues['department_code'] = user.department_code;
    } else {
      initialValues['department_code'] = {
        label: departmentCodeList[user.department_code],
        value: user.department_code
      };
    }
  }

  useEffect(() => {
    return () => {
      dispatch(formResetError());
    };
  }, []);

  return (
    <FormConstructor
      initialValues={initialValues}
      model={NameModel}
      onSubmit={onSubmit}
      className="bg-white"
    >
      {({ isValid }) => (
        <>
          <RadioButtonConstructor
            name={'civility'}
            className="tsme-inscription-form__button-group col-12"
            {...civility}
          />
          <InputConstructor name="first_name" {...first_name} />
          <InputConstructor name="last_name" {...last_name} />

          <InputConstructor name="entity_name" {...entity_name} />
          <SelectConstructor name="department_code" {...department_code} />

          {error && (
            <div className="col-12 mt-4 text-right">
              <SzAlert type="danger">{error}</SzAlert>
            </div>
          )}
          {isProfil && (
            <div className="col-6 mt-4">
              <SzButton
                onClick={handleClose}
                variant="secondary"
                isDisabled={isLoading}
              >
                {t('profile.back_button')}
              </SzButton>
            </div>
          )}
          <div
            className={classnames('mt-4 text-right', {
              'col-6': isProfil,
              'col-12': !isProfil
            })}
          >
            <SzButton
              type="submit"
              loader={isLoading}
              isDisabled={
                isInternAdminUSer ||
                isAdviserSimpleUser ||
                !isValid ||
                isLoading
              }
              className={isLoading && 'col-4'}
            >
              {t('profile.button', { context: isProfil && 'profile' })}
            </SzButton>
          </div>
        </>
      )}
    </FormConstructor>
  );
}
