import React, { FC, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {
  SzButton,
  SzModal,
  SzTypographie,
  SzTextArea,
  SzAlert
} from '@suezenv/react-theme-components';
import { FormikProps } from 'formik';
import FormConstructor, {
  InputConstructor,
  SelectConstructor
} from '../../forms/FormConstructor';
import ProductModel from '../../models/Product';
import ReCAPTCHA from 'react-google-recaptcha';
import { ProductActions } from '../../redux/actions';
import { useParams } from 'react-router-dom';

export type ProductFormProps = {
  show: boolean;
  handleClose(): any;
  type: string;
};

export const ProductForm: FC<ProductFormProps> = props => {
  const { contactRequest } = ProductActions;
  const dispatch = useDispatch();
  const { type } = useParams() as any;
  const { loading, error } = useSelector((state: any) => state.app);
  // Préremplissage des champs avec les infos du user courant

  // const { list } = useSelector((state: any) => state.contract);
  // const { currentUser, loading, error, token_valid } = useSelector(
  //   (state: any) => state.app
  //   );
  // import { AppActions } from '../../redux/actions';
  // const { tokenCheck } = AppActions;
  // useEffect(() => {
  //   if (!token_valid) {
  //     dispatch(tokenCheck());
  //   }
  // }, []);
  // const listIsSet = list && list[0];
  // const { first_name, last_name, email } = currentUser as {
  //   [k: string]: string;
  // };
  // const company_name: string = listIsSet && list[0].company_name;
  // const reference: string = listIsSet && list[0].reference;
  // const phone: string = listIsSet && (list[0].phone || list[0].mobile_phone);

  const { subject, message, ...model } = ProductModel;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { t } = useTranslation();
  const onSubmit = ({ subject, ...form }: any) => {
    if (recaptchaRef && recaptchaRef.current) {
      const payload = { ...form };
      payload.subject = (subject || []).map((v: any) => v.value).join(',');
      payload.contact_type = 'contact_form_product_infos'; // this value doesn't change, it's associated to this precise form.
      payload.token_captcha = recaptchaRef.current.getValue();
      payload.message = messageValue;

      dispatch(contactRequest(payload));
    }
  };

  const [messageValue, setMessageValue] = useState<string>('');
  // type represents the product we're viewing. When showing the form, this value should be preselected.
  const initialValues: any = {
    subject: subject.options.filter(({ value }) => value === type)
  };

  const options = subject.options.map((option: any) => {
    if (option.value === type) option.isFixed = true;
    return option;
  });

  const {
    REACT_APP_CAPTCHA_SITE_KEY,
    REACT_APP_CAPTCHA_SECRETE_KEY
  } = process.env;

  return (
    <SzModal title={''} size="xl" {...props}>
      <SzTypographie variant="h2" weight="bold">
        {t('pages:product.title')}
      </SzTypographie>
      <SzTypographie>{t('pages:product.headline')}</SzTypographie>
      <FormConstructor
        model={ProductModel}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ submitForm, isValid, values, ...props }: FormikProps<{}>) => {
          const onButtonSubmit = (e: any) => {
            e.preventDefault();
            const captcha = recaptchaRef.current;

            if (captcha) {
              captcha.reset();
              captcha.execute();
            }
          };
          const captchaSubmit = (value: any) => {
            submitForm();
          };

          return (
            <>
              {Object.entries(model).map(([name, props], key) => {
                const { values, ...otherProps } = props as any;
                return (
                  <InputConstructor key={key} name={name} {...otherProps} />
                );
              })}
              <SelectConstructor
                name="subject"
                {...subject}
                options={options}
                isClearable
                isMulti
                isSearchable
              />
              <div className="col-12 pt-3">
                <SzTextArea
                  name="message"
                  placeholder={t('pages:product.message.placeholder')}
                  label={t('pages:product.message.label')}
                  value={messageValue}
                  onChange={({ target }) => setMessageValue(target.value)}
                  maxLength={5000}
                />
              </div>
              {error && (
                <div className="mt-4 row no-gutters">
                  <SzAlert className="col-12" variant="danger">
                    {error}
                  </SzAlert>
                </div>
              )}
              <div className="col-12 mt-4 row no-gutters justify-content-end">
                <SzButton
                  type="submit"
                  isDisabled={!isValid || loading}
                  onClick={onButtonSubmit}
                  loader={loading}
                >
                  {t('pages:product.confirm')}
                </SzButton>
              </div>
              <ReCAPTCHA
                sitekey={REACT_APP_CAPTCHA_SITE_KEY}
                stoken={REACT_APP_CAPTCHA_SECRETE_KEY}
                ref={recaptchaRef}
                size="invisible"
                onChange={captchaSubmit}
                badge="bottomleft"
              />
            </>
          );
        }}
      </FormConstructor>
    </SzModal>
  );
};

export default ProductForm;
