import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../redux/actions';

export default function Logout() {
  const dispatch = useDispatch();
  const { logout } = AppActions;
  useEffect(() => {
    localStorage.clear();
    dispatch(logout());
    
    window.location = `${process.env.REACT_APP_IDP_LOGOUT_URL}`;
  });
  return null;
}
