import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { CustomerActions } from "../../redux/actions";

type RequirementType = {
  title?: string;
  requirement: string;
  formType: string;
  description: string;
  filling: string;
};

interface objectProps {
  title: string;
  code: string;
  requirements: RequirementType[];
}

export default function ObjectList() {
  const { t } = useTranslation();
  const { activeBtn } = useAppSelector(({ customer }) => customer);
  const dispatch = useAppDispatch();
  const objects: objectProps[] = Object.values(
    t(`customerService.objects`, { returnObjects: true })
  );
  const defaultActiveBtn = new Array(objects.length).fill(false);
  const btnStyle = (active: boolean) => ({
    backgroundColor: active ? "rgba(119,183,252,0.2)" : "#ffffff",
    border: `2px solid ${active ? "#77B7FC" : "#ffffff"}`,
    borderRadius: "4px",
    boxShadow: "initial",
  });

  return (
    <div className="row mt-3 cy-object-list">
      {objects.map(({ title, requirements, code }, key) => {
        const onClickObject = (key, title) => {
          const currentActiveBtn: boolean[] = [...defaultActiveBtn];
          currentActiveBtn[key] = true;
          dispatch(CustomerActions.setRequirements(requirements));
          dispatch(CustomerActions.setRequirementSelected(null));
          dispatch(CustomerActions.setActiveBTn(currentActiveBtn));
          dispatch(CustomerActions.setCurrentTitleObject(title));
          dispatch(CustomerActions.setCurrentCodeObject(code));
        };

        return (
          <div
            key={title}
            className={clsx("col-12 col-md-4 col-lg-3 col-xl-2 mb-3 px-sm-1", {
              "cy-object-list-item": true,
            })}
          >
            <button
              className="btn sz-btn btn-primary py-5 w-100 shadow-sm"
              style={btnStyle(activeBtn[key])}
              onClick={() => onClickObject(key, title)}
            >
              {title}
            </button>
          </div>
        );
      })}
    </div>
  );
}
