import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Input, ModelConstructor } from '@suezenv/tsme-react-components';
import clsx from 'clsx';
import {
  SzAlert,
  SzButton,
  SzCheckbox,
  SzSelect,
  SzTextArea,
  SzTypographie
} from '@suezenv/react-theme-components';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Dropbox from '../Dropbox';
import Identity from './Identity';
import { ContactModel } from '../../../models';
import Capaddress from '../../../components/Capaddress';
import { AppActions } from '../../../redux/actions';
import { useFilterByInseeCode } from '../../../hooks/useFilter';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import IdentityModal from '../IdentityModal';
import { Link } from 'react-router-dom';
import useCheckTypeUser from '../../../hooks/useCheckTypeUser';

type Message = 'success' | 'danger';

export const ContactForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { requirementSelected, currentTitleObject, currentCodeObject } =
    useAppSelector(({ customer }) => customer);

  const isAdviserSimpleUser = useCheckTypeUser('internal_adviser_simple');
  const isInternAdminUSer = useCheckTypeUser('internal_adviser_admin');

  const [form, hasFirstLastName] = useAppSelector((state) => {
    const { app }: any = state;
    let hasFirstLastName = false;
    if (app.currentUser.first_name && app.currentUser.last_name) {
      hasFirstLastName = true;
    }

    return [app.form, hasFirstLastName];
  });

  // Dropdown
  const [postFiles, setPostFiles] = useState<File[]>([]);
  const [showErrorMessageDropBox, setShowErrorMessageDropBox] =
    useState<Message | null>();
  const [errorMessageDropBox, setErrorMessageDropBox] = useState<
    string | null
  >();

  const [disabledCheckedAllRef, setDisabledCheckedAllRef] = useState(true);
  const [showSelectRefs, setShowSelectRefs] = useState(true);

  const [checked, setChecked] = useState(false);

  const [displayModal, setDisplayModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [selectedField, setSelectedField] = useState<'location' | null>(null);

  const onClickIdentityBtn = () => {
    setDisplayModal(true);
  };

  const { clientServiceFiles } = AppActions;
  const { description, filling, formType } = requirementSelected || {};
  const RawModel: ModelConstructor = { ...ContactModel };

  if (formType === 'contactFormWithRefs') {
    RawModel['references'] = {
      schema: Yup.array()
        .required('Saisissez votre commune')
        .min(1, 'Saisissez une référence')
        .of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required()
          })
        )
    };
  }

  const ContactModelFistLastName: ModelConstructor = { ...RawModel };

  delete ContactModelFistLastName.first_name;
  delete ContactModelFistLastName.last_name;

  const { first_name, last_name, phone, location, references } = RawModel;

  const currentModel = hasFirstLastName ? ContactModelFistLastName : RawModel;

  const optionsRefs = useFilterByInseeCode(selectedAddress);
  const onSubmitForm = async (formData: any) => {
    if (postFiles) {
      formData.files = postFiles;
    }

    const data = {
      contact_type: 'contact_customer_service_infos',
      object: currentTitleObject,
      need: requirementSelected?.requirement,
      object_code: currentCodeObject,
      need_code: requirementSelected?.code,
      zipcode: selectedAddress?.postalCode,
      municipality: selectedAddress.locality,
      message: formData.message,
      insee: selectedAddress.localityId
    };

    for (let index = 0; index < formData?.files.length; index++) {
      const file = formData?.files[index];
      data[`attachments[${index}]`] = file;
    }

    const stringRefs = formData.references
      ? formData.references?.map(({ value }) => value).join(',')
      : null;

    if (stringRefs) {
      data['references'] = stringRefs;
    }

    if (formData.phone) {
      data['phone'] = formData.phone;
    }

    if (!hasFirstLastName) {
      data['first_name'] = formData.first_name;
      data['last_name'] = formData.last_name;
    }

    dispatch(clientServiceFiles(data, !hasFirstLastName));
  };

  const onCloseErrorMessage = () => {
    setShowErrorMessageDropBox(null);
  };

  const showError = (showErrorMessage: Message, message: string) => {
    setShowErrorMessageDropBox(showErrorMessage);
    setErrorMessageDropBox(message);

    setTimeout(function () {
      onCloseErrorMessage();
    }, 5000);
  };

  useEffect(() => {
    setMyKey(Date.now());
  }, [requirementSelected]);

  const [key, setMyKey] = useState(Date.now());

  const initialValues = {
    message: filling
  };

  const history = useHistory();

  const logout = () => {
    history.push('/logout');
  };
  const handleClose = () => {
    setDisplayModal(false);
  };

  if (!requirementSelected) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      key={key}
      validationSchema={currentModel}
      initialValues={initialValues}
      className="bg-white py-4 px-md-5 position-relative"
    >
      {({
        errors,
        values,
        setFieldValue,
        handleChange,
        isValid,
        setFieldTouched
      }) => {
        const onChange = ({ target }) => {
          setChecked(target.checked);

          if (target.checked) {
            setShowSelectRefs(false);
            setFieldValue('references', optionsRefs);
          } else {
            setShowSelectRefs(true);
            setFieldValue('references', []);
          }
        };

        const onChangeRefs = (selectedOption) => {
          const valueRef = selectedOption ?? '';
          setFieldValue('references', valueRef);
        };

        const onPostFiles = (files) => {
          setPostFiles(files);
        };

        const capAddressClick = (address, type) => {
          const { postalCode, locality, inputOutput, localityInfo_2 } = address;
          setSelectedAddress(address);

          setFieldValue('location', inputOutput);
          setFieldTouched('location', true);

          setSelectedField(null);
          setDisabledCheckedAllRef(false);
        };

        const onChangeCap = ({ target }) => {
          if (!target.value) {
            setDisabledCheckedAllRef(true);
            setSelectedAddress(null);
            setFieldValue('references', []);
            setChecked(false);
            setShowSelectRefs(true);
          }

          setSelectedField('location');
        };

        return (
          <>
            {hasFirstLastName && <Identity onClick={onClickIdentityBtn} />}

            <div className="row mb-4">
              {!hasFirstLastName && (
                <>
                  <div className="col-12 col-md-4 px-0 pr-md-4">
                    <SzTypographie
                      weight="medium"
                      className="mb-0"
                      color="disabled"
                    >
                      {t('customerService.contactForm.labelLastName')}
                    </SzTypographie>

                    <Input
                      name={'last_name'}
                      {...last_name}
                      changeCase="upperCase"
                    />
                  </div>

                  <div className="col-12 col-md-4 px-0 pr-md-4">
                    <SzTypographie
                      weight="medium"
                      className="mb-0"
                      color="disabled"
                    >
                      {t('customerService.contactForm.labelFirstName')}
                    </SzTypographie>

                    <Input
                      name={'first_name'}
                      {...first_name}
                      changeCase="upperCase"
                    />
                  </div>
                </>
              )}

              <div className="col-12 col-md-4 px-0 pr-md-0">
                <SzTypographie
                  weight="medium"
                  className="mb-0"
                  color="disabled"
                >
                  {t('customerService.contactForm.labelMobile')}
                </SzTypographie>
                <Input name={'phone'} {...phone} />
              </div>
            </div>

            {!hasFirstLastName && (
              <div className="row mb-2">
                <SzAlert variant="info" transparent className="col-12">
                  {t('customerService.contactForm.info')}
                </SzAlert>
              </div>
            )}

            <div className="row mb-2">
              <div className="col-12 col-md-6 px-0 pr-md-4">
                <SzTypographie
                  weight="medium"
                  className="mb-0"
                  color="disabled"
                >
                  {t('customerService.contactForm.labelLocalisation')}
                </SzTypographie>
                <Input name={'location'} {...location} onChange={onChangeCap} />

                {values['location'].length >= 3 &&
                  selectedField === 'location' && (
                    <Capaddress
                      type="town"
                      value={values['location']}
                      onClick={capAddressClick}
                    />
                  )}
              </div>
              <div className="col-12 col-md-6 pl-0 my-auto">
                <SzCheckbox
                  id="checkbox"
                  name="checkbox"
                  label={t('customerService.contactForm.labelCheckAllCities')}
                  type="checkbox"
                  checked={checked}
                  disabled={disabledCheckedAllRef}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row mb-4">
              <SzTypographie
                weight="medium"
                className="mb-0 col-12 pl-0"
                color="disabled"
              >
                {t('customerService.contactForm.labelRefs')}

                {formType === 'contactForm' && (
                  <SzTypographie weight="light" className="d-inline" tag="span">
                    {t('customerService.contactForm.subtilteRefOptional')}
                  </SzTypographie>
                )}
              </SzTypographie>

              {!showSelectRefs && (
                <SzTypographie
                  variant="caption"
                  size="M"
                  weight="medium"
                  className="mb-0 col-12 pl-0"
                >
                  {t('customerService.contactForm.titleAllRefs')}
                </SzTypographie>
              )}

              <SzSelect
                {...references}
                id="references"
                placeholder={'Saisissez une ou des réferences'}
                options={optionsRefs}
                value={values.references}
                onChange={onChangeRefs}
                className={clsx('col-12 px-0', {
                  'd-none': !showSelectRefs
                })}
                name="references"
                isClearable
                isMulti
              />
            </div>
            <div className="row">
              <SzTypographie
                weight="regular"
                className="bg-info-light col-12 pl-3 py-3"
              >
                {requirementSelected.code === 'RATTACHEMENT' ? (
                  <>
                    {description}
                    <Link className="font-family-bold" to="/espace-personnel">
                      cliquant ici
                    </Link>
                    .
                  </>
                ) : (
                  description
                )}
              </SzTypographie>
            </div>
            <div className="row mb-3">
              <SzTypographie
                weight="medium"
                className="mb-0 col-12 px-0"
                color="disabled"
              >
                {t('customerService.contactForm.labelMessage')}
              </SzTypographie>

              <SzTextArea
                name="message"
                placeholder="Renseignez ici toute information complémentaire"
                value={values.message}
                onChange={handleChange}
                className="col-12"
              />
            </div>

            <div className="row mb-3">
              <Dropbox
                postFiles={onPostFiles}
                showError={showError}
                onCloseErrorMessage={onCloseErrorMessage}
              />
            </div>
            {showErrorMessageDropBox && (
              <div className="row position-sticky fixed-bottom">
                <SzAlert
                  variant={showErrorMessageDropBox}
                  className="col-6 offset-3"
                  onClose={onCloseErrorMessage}
                >
                  {errorMessageDropBox}
                </SzAlert>
              </div>
            )}
            {form?.error && (
              <div className="row">
                <div className="col-12 p-0">
                  <SzAlert variant="danger">{form.error}</SzAlert>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12 text-center">
                <SzButton
                  type="submit"
                  loader={form.loading}
                  isDisabled={
                    isAdviserSimpleUser || isInternAdminUSer || !isValid
                  }
                >
                  {t('customerService.contactForm.btnSubmit')}
                </SzButton>
              </div>
            </div>
            <IdentityModal
              show={displayModal}
              handleClose={handleClose}
              onClick={logout}
            />
          </>
        );
      }}
    </Form>
  );
};
