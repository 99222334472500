import React from "react";
import {
  SzButton,
  SzModal,
  SzTypographie,
} from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";

export default function ActionModal({
  type,
  show,
  handleClose,
  userInfos,
  onClick,
  loader,
}) {

  const { email } = userInfos || {};
  const { t } = useTranslation();

  return (
    <SzModal title={""} show={show} handleClose={handleClose} size={"xl"}>
      <SzTypographie variant="h1" weight="bold">
        {t(`adminSpace.actions.${type}.title`)}
      </SzTypographie>
      <SzTypographie>
        {t(`adminSpace.actions.${type}.infoUser`, { email: email })}
      </SzTypographie>
      <div className="d-flex justify-content-between mt-4">
        <SzButton variant="secondary" onClick={onClick} loader={loader}>
          {t(`adminSpace.actions.${type}.validate`)}
        </SzButton>
        <SzButton onClick={() => handleClose(true)}>
          {t(`adminSpace.actions.${type}.cancel`)}
        </SzButton>
      </div>
    </SzModal>
  );
}
