import React, { useState, FC } from "react";
import { ConfirmationNoMandat } from "@suezenv/tsme-react-components";
import { ConfirmationProps } from "@suezenv/tsme-react-components/build/Page/paiment-etip/Confirmation";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "../../services/Request";
import { useTheme } from "../../themes";

export const ConfirmationPage: FC<ConfirmationProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation("pages");
  const { logo, brand } = useTheme();
  const [loaderDlPdf, setLoaderDlPdf] = useState(false);
  const [messageError, setMessageError] = useState("");

  interface paramsInitPaymentAuto {
    status: string;
    fullRef: string;
    transactionid: string;
  }

  const { status, fullRef, transactionid }: paramsInitPaymentAuto = useParams();
  let currentStatus = status;

  if (!fullRef) {
    currentStatus = "KO";
  }

  const dlPdfMandat = async () => {
    setLoaderDlPdf(true);
    try {
      const { data } = await axios.get(
        `/api/contract/sepamandate/download/${fullRef}/sps`,
        {
          responseType: "blob"
        }
      );
      setLoaderDlPdf(false);
      window.open(URL.createObjectURL(data));
    } catch (error) {
      setMessageError(t("components:errors.techError"));
    }
  };

  const toDashoard = () => {
    history.push("/");
  };

  const changePaymentMethod = () => {
    history.push("/espace-facturation");
  };

  const showOtherBill = () => {
    history.push("/espace-facturation");
  };

  const data = {
    srcLogo: logo,
    currentBrandCode: brand,
    destClose: "/espace-facturation",
    isMandat: false,
    status: currentStatus,
    modeOfPayment: "paymentAuto",
    fromPlatform: "beta",
    loaderDlPdf,
    messageError,
    dlPdfMandat,
    toDashoard,
    changePaymentMethod,
    showOtherBill
  };

  return <ConfirmationNoMandat {...data} {...props} />;
};
