import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SzTypographie, SzButton } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import UserIcon from '../../assets/img/avatar.svg';
import { AppActions, ContractActions } from '../../redux/actions';
import { departmentCodeList } from '../../utils';

export default function Profile(props) {
  const { t } = useTranslation(['pages', 'models']);
  const dispatch = useDispatch();
  const [
    { civility, first_name, last_name, email, entity_name, department_code },
    loading
  ] = useSelector(({ app }) => [app.currentUser, app.loading]);

  const isObj = department_code && typeof department_code === 'object';
  const departmentCode = isObj ? department_code.value : department_code;
  const department = departmentCode ? departmentCodeList[departmentCode] : null;

  const openModal = (active) => {
    dispatch(AppActions.openModal());
    dispatch(ContractActions.contractModalSetActiveForm(active));
  };

  const classNames = classnames('col-12 bg-white mb-3 shadow-sm');

  const nameBlock = (
    <div className={classNames}>
      <div className="row no-gutters px-4 py-4">
        <div className="col-1 d-flex justify-content-center">
          <img src={UserIcon} width="80px" height="80px" alt="User icon" />
        </div>
        <div className="col-11 pl-5">
          <SzTypographie variant="h2" weight="bold" color="secondary">
            {t('review.aboutYou')}
          </SzTypographie>
          <div className="row pl-2">
            <div className="col-6 col-lg-4">
              <SzTypographie
                weight="medium"
                color="disabled"
                variant="caption"
                size="M"
                className=" mb-0"
              >
                {t('review.identite')}
              </SzTypographie>
              {last_name && (
                <SzTypographie weight="medium" color="secondary" variant="text">
                  {civility && t(`values.civility.${civility}`)} {first_name}{' '}
                  {last_name}
                </SzTypographie>
              )}
            </div>
            <div className="col-6 col-lg-3">
              <SzTypographie
                weight="medium"
                color="disabled"
                variant="caption"
                size="M"
                className=" mb-0"
              >
                {t('review.entity')}
              </SzTypographie>
              {entity_name && (
                <SzTypographie weight="medium" color="secondary" variant="text">
                  {entity_name}
                </SzTypographie>
              )}
            </div>
            <div className="col-6 col-lg-3">
              <SzTypographie
                weight="medium"
                color="disabled"
                variant="caption"
                size="M"
                className=" mb-0"
              >
                {t('review.service')}
              </SzTypographie>
              {department && (
                <SzTypographie weight="medium" color="secondary" variant="text">
                  {department}
                </SzTypographie>
              )}
            </div>
            <div className="col-6 col-lg-2">
              {!loading && (
                <SzButton
                  className="tsme-open-modal-btn-name"
                  variant="tertiary"
                  icon="pencil-write"
                  onClick={() => openModal('name')}
                ></SzButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const emailBlock = (
    <div className={classNames}>
      <div className="row no-gutters px-4 mt-2">
        <div className="col-12 col-lg-11 offset-lg-1 pl-0 pl-lg-5 py-4">
          <SzTypographie
            variant="h2"
            weight="bold"
            color="secondary"
            className="mb-4"
          >
            Vos identifiants
          </SzTypographie>
          <div className="row p-3">
            <div className="col-10 col-lg-4 border border-right-0 mb-3 mb-lg-0 py-4">
              <SzTypographie
                weight="medium"
                color="disabled"
                variant="caption"
                size="M"
                className="col-12 mb-0"
              >
                {t('review.identifier')}
              </SzTypographie>
              {email && (
                <SzTypographie
                  weight="medium"
                  color="secondary"
                  variant="text"
                  className="col-12"
                  tag="span"
                >
                  {email}
                </SzTypographie>
              )}
            </div>
            <div className="col-2 col-lg-1 border border-left-0 mb-3 mb-lg-0 py-4">
              {!loading && (
                <SzButton
                  className="tsme-open-modal-btn-email"
                  variant="tertiary"
                  icon="pencil-write"
                  onClick={() => openModal('email')}
                />
              )}
            </div>

            <div className="col-10 col-lg-3  offset-lg-2 border border-right-0 py-4">
              <SzTypographie
                weight="medium"
                color="disabled"
                variant="caption"
                size="M"
                className="mb-0"
              >
                {t('review.pwdConnexion')}
              </SzTypographie>
              <SzTypographie
                weight="medium"
                color="secondary"
                variant="text"
                tag="span"
              >
                *************
              </SzTypographie>
            </div>
            <div className="col-2 col-lg-1 border border-left-0 py-4">
              {!loading && (
                <SzButton
                  className="tsme-open-modal-btn-password"
                  variant="tertiary"
                  icon="pencil-write"
                  onClick={() => openModal('password')}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="tsme-personal-space__infos text-primary">
      <div className="row no-gutters">
        {nameBlock}
        {emailBlock}
      </div>
    </div>
  );
}
