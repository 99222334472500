import React from "react";
import { SzModal, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { AppActions } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AddContractForm from "../../../forms/ContractForm/AddContractForm";
import EditContractForm from "../../../forms/ContractForm/EditContractForm";
import { NameForm, EmailForm, PasswordForm } from "../../../forms";
import IbanContractForm from "../../../forms/ContractForm/IbanContractForm";

export default function ProfileFormModal(props) {
  const { t } = useTranslation("components");
  const dispatch = useDispatch();
  const { closeModal } = AppActions;
  const { activeForm } = useSelector(({ contract }) => contract.modal);
  const show = useSelector(({ app }) => app.modal);

  const handleClose = () => dispatch(closeModal());

  switch (activeForm) {
    case "name":
      return (
        <SzModal
          title={t("modals.review.name.title")}
          show={show}
          handleClose={handleClose}
        >
          <NameForm />
        </SzModal>
      );
    case "email":
      return (
        <SzModal
          title={t("modals.review.email.title")}
          show={show}
          handleClose={handleClose}
        >
          <SzTypographie>{t("modals.review.email.content")}</SzTypographie>
          <EmailForm />
        </SzModal>
      );
    case "password":
      return (
        <SzModal
          title={t("modals.review.password.title")}
          show={show}
          handleClose={handleClose}
        >
          <SzTypographie>{t("modals.review.password.content")}</SzTypographie>
          <PasswordForm />
        </SzModal>
      );
    case "addContract":
      return (
        <SzModal
          title={t("modals.review.contract.title")}
          show={show}
          handleClose={handleClose}
        >
          <AddContractForm />
        </SzModal>
      );
    case "billing":
      return (
        <SzModal
          title={t("modals.review.billing.title")}
          show={show}
          handleClose={handleClose}
        >
          <EditContractForm active="billing" />
        </SzModal>
      );
    case "contact":
      return (
        <SzModal
          title={t("modals.review.contact.title")}
          show={show}
          handleClose={handleClose}
        >
          <EditContractForm active="contact" />
        </SzModal>
      );
    case "iban":
      return (
        <SzModal
          title={t("modals.review.iban.title")}
          show={show}
          handleClose={handleClose}
          size={'xl'}
        >
          <IbanContractForm active="iban" />
        </SzModal>
      );
    default:
      return null;
  }
}
