import React, { useState } from 'react';
import { SzModal, SzStepper } from '@suezenv/react-theme-components';
import Roles from '../Roles';
import AddUserForm from '../AddUserForm';
import ConfirmationRoles from '../ConfirmationRoles';
import ReactTags, { Tag } from 'react-tag-autocomplete';

export type setNextStep = (dataUser?: any) => void;

export const InviteModal = (props) => {
  const { show, handleClose } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [dataUser, setDataUser] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const setPrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const setNextStep = (dataUser) => {
    if (dataUser) {
      setDataUser(dataUser);
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  interface CustomReactTags {
    addTag(tag: Tag);
    clearInput();
  }

  const refInput = React.createRef<ReactTags & CustomReactTags>();

  const formProps: AddUserForm = {
    tags: tagsList,
    innerRef: refInput,
    setNextStep,
    errorMessage,
    onInput: (newTag) => {
      const reg = /[,; ]/;
      const addedTags = newTag.split(reg);

      if (addedTags.length > 1) {
        for (let index = 0; index < addedTags.length; index++) {
          if (refInput.current) {
            refInput.current.addTag({ id: index, name: addedTags[index] });
            refInput.current.clearInput();
          }
        }
      }
      if (errorMessage) setErrorMessage(null);
    },
    onAddition: (newTag) => {
      const { name, id } = newTag;
      const reg = /[,; ]/;
      const addedTags = name.split(reg);

      setTagsList((prevTags) => {
        const newTagsList = [...prevTags];
        for (const name of addedTags) {
          newTagsList.push(newTag);
        }
        if (refInput.current) {
          refInput.current.clearInput();
        }
        return newTagsList;
      });
    },
    onValidate: ({ name }) => {
      const reg =
        /([,; ])?[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/;
      const res = reg.test(name);
      if (!res) setErrorMessage("Le format n'est pas valide.");
      else setErrorMessage(null);
      return res;
    },
    onDelete: (i: number) => {
      const newTags = [...tagsList];
      newTags.splice(i, 1);
      setTagsList(newTags);
    }
  };

  return (
    <SzModal
      title={''}
      show={show}
      handleClose={() => {
        if (activeStep === 2) {
          setActiveStep(0);
          setTagsList([]);
        }
        handleClose(activeStep === 2);
      }}
      size={'xl'}
    >
      <SzStepper header={[]} activeStep={activeStep} className="p-0">
        <SzStepper.SzStep index={0}>
          <AddUserForm {...formProps} />
        </SzStepper.SzStep>
        <SzStepper.SzStep index={1}>
          {activeStep === 1 && (
            <Roles
              dataUser={dataUser}
              setNextStep={setNextStep}
              setPrevStep={setPrevStep}
            />
          )}
        </SzStepper.SzStep>
        <SzStepper.SzStep index={2}>
          <ConfirmationRoles />
        </SzStepper.SzStep>
      </SzStepper>
    </SzModal>
  );
};

export default InviteModal;
