import React from 'react';
import ReactDOM from 'react-dom';
import {
  SzButton,
  SzInput,
  SzSelect,
  SzChooseButton
} from '@suezenv/react-theme-components';
const MAX_LENGTH = 4;
export default class DebitForm extends React.Component {
  constructor(props) {
    super(props);
    this.iban = [];
    for (let index = 0; index < 6; index++) {
      this.iban[index] = React.createRef();
    }
    this.state = {
      country: 'FR',
      firstname: '',
      lastname: '',
      phone: '',
      iban: '',
      iban0: '',
      iban1: '',
      iban2: '',
      iban3: '',
      iban4: '',
      iban5: ''
    };
  }
  handleTextChange = (e, key) => {
    const { name, value } = e.target;
    if (value.length <= MAX_LENGTH) {
      this.setState({ [name]: value });
    }
    if (value.length === MAX_LENGTH && key + 1 < 6) {
      this.iban[key + 1].current.focus();
    }
  };
  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value, error: null });
  };
  selectCountry = e => {
    const country = e.target.value;
    this.setState({ country });
  };
  render() {
    const choices = [
      {
        value: 'Madame',
        icon: 'sz-icon-women'
      },
      {
        value: 'Monsieur',
        icon: 'sz-icon-man'
      }
    ];
    const countries = [
      {
        text: 'FRANCE',
        value: 'FR'
      },
      {
        text: 'ENGLAND',
        value: 'UK'
      }
    ];
    const {
      country,
      firstname,
      lastname,
      phone,
      iban,
      iban0,
      iban1,
      iban2,
      iban3,
      iban4,
      iban5
    } = this.state;

    const fullIban = country + iban0 + iban1 + iban2 + iban3 + iban4 + iban5;

    return (
      <form
        className="tsme-inscription-form bg-white"
        onSubmit={e => console.log(e)}
      >
        <SzChooseButton
          className="tsme-inscription-form__button-group d-flex justify-content-between"
          onChange={() => {}}
          choices={choices}
          name={'toto'}
          value={'Madame'}
        />
        <SzInput
          className="tsme-inscription-form__form-group d-block d-md-inline-block pr-md-3"
          label={'Prénom'}
          required
          name="firstname"
          value={firstname}
          onChange={this.onChange}
          placeholder={'Saisir votre prénom'}
          type={'text'}
        />
        <SzInput
          className="tsme-inscription-form__form-group d-block d-md-inline-block pl-md-3"
          label={'Nom'}
          required
          name="lastname"
          value={lastname}
          onChange={this.onChange}
          placeholder={'Saisir votre nom'}
          type={'text'}
        />
        <SzInput
          className="tsme-inscription-form__form-group d-block d-md-inline-block pr-md-3"
          label={'Téléphone portable'}
          required
          name="phone"
          value={phone}
          onChange={this.onChange}
          placeholder={'Saisir votre numéro'}
          icon={'mdi mdi-phone'}
          type={'text'}
        />
        <p className="text-grey">
          Lorsque vous nous communiquez vos données téléphoniques, vous disposez
          du droit de vous inscrire sur la liste d’opposition au démarchage
          téléphonique. Pour plus de rensignements, vous pouvez consulter le
          site wwww.bloctel.gouv.fr
        </p>
        <SzSelect
          label={'IBAN'}
          options={countries}
          onChange={this.selectCountry}
        />
        <div className="iban-container">
          <SzInput
            className="d-block d-md-inline-block pr-md-3 ibanInput"
            name="iban"
            value={country}
            onChange={this.handleTextChange}
            type={'text'}
            placeholder={'FR'}
          />
          <input type="hidden" name="iban" value={fullIban} />
          {this.iban.map((iban, index) => (
            <SzInput
              className="d-block d-md-inline-block pr-md-3 ibanInput"
              required
              name={`iban${index}`}
              value={eval(`iban` + index)}
              onChange={e => this.handleTextChange(e, index)}
              type={'text'}
              ref={iban}
              key={index}
              placeholder={''}
            />
          ))}
        </div>
        <div className="d-flex justify-content-center">
          <SzButton className="col-md-4" type="submit">
            VALIDER VOTRE INSCRIPTION
          </SzButton>
        </div>
      </form>
    );
  }
}
