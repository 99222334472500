import * as Yup from "yup";
import "./validators";

export const NameModel = {
  civility: {
    schema: Yup.string()
      .matches(/(mr|miss)/, "models:profile.civility.match")
      .required("models:profile.civility.required"),
    choices: [
      {
        label: "Madame",
        value: "miss",
      },
      {
        label: "Monsieur",
        value: "mr",
      },
    ],
  },
  first_name: {
    label: "models:profile.first_name.label",
    className: "pr-md-3",
    type: "text",
    icon: "pencil-write",
    placeholder: "models:profile.first_name.placeholder",
    schema: Yup.string()
      .matches(/^\D+$/, "models:profile.first_name.match")
      .min(2, "models:profile.first_name.min")
      .required("models:profile.first_name.required"),
  },
  last_name: {
    label: "models:profile.last_name.label",
    className: "pl-md-3",
    placeholder: "models:profile.last_name.placeholder",
    type: "text",
    icon: "pencil-write",
    schema: Yup.string()
      .matches(/^\D+$/, "models:profile.last_name.match")
      .min(2, "models:profile.last_name.min")
      .max(50, "models:profile.last_name.max")
      .required("models:profile.last_name.required"),
  },
  entity_name: {
    label: "models:profile.entity.label",
    type: "text",
    className: "pl-md-3 mt-2",
    schema: Yup.string().required("models:profile.entity.required"),
    placeholder: "models:profile.entity.placeholder",
  },
  department_code: {
    placeholder: "models:profile.service.placeholder",
    label: "models:profile.service.label",
    className: "col-12 col-md-6 pl-md-3 mt-2",
    schema: Yup.string().required("models:profile.service.required"),
    options: [
      {
        label: "Autre",
        value: "other",
      },
      {
        label: "Direction",
        value: "management",
      },
      {
        label: "Comptabilité, finance, trésorie",
        value: "accounting",
      },
      {
        label: "Service technique de gestion des fluides",
        value: "technical_service_fluid_management",
      },
      {
        label: "Service santé, sécurité, environnement",
        value: "health_safety_environment",
      },
    ],
  },
};

export const EmailModel = {
  email: {
    label: "models:profile.email.label",
    className: "pr-md-3",
    placeholder: "models:profile.email.placeholder",
    type: "email",
    icon: "envelope",
    schema: Yup.string()
      .min(1, "models:profile.email.error")
      .email("models:profile.email.error")
      .required("models:profile.email.required"),
  },
  repeated_email: {
    label: "models:profile.repeated_email.label",
    className: "pl-md-3",
    placeholder: "models:profile.repeated_email.placeholder",
    type: "email",
    icon: "envelope",
    schema: Yup.string()
      .min(1, "models:profile.repeated_email.required")
      .oneOf([Yup.ref("email")], "models:profile.repeated_email.error")
      .required("models:profile.repeated_email.required"),
  },
};

export const PasswordModel = {
  old_password: {
    label: "models:profile.old_password.label",
    className: "pr-md-3 mr-md-2 mb-4",
    placeholder: "models:profile.old_password.placeholder",
    type: "password",
    icon: "key-lock-1",
    schema: Yup.string().required("models:profile.old_password.required"),
  },
  password: {
    label: "models:profile.new_password.label",
    className: "pr-md-3",
    placeholder: "models:profile.new_password.placeholder",
    type: "password",
    icon: "key-lock-1",
    schema: Yup.string()
      .min(8, "models:profile.new_password.min")
      .isPassword("models:profile.new_password.password")
      .required("models:profile.new_password.required"),
  },
  repeated_password: {
    label: "models:profile.repeated_password.label",
    className: "pl-md-3",
    placeholder: "models:profile.repeated_password.placeholder",
    type: "password",
    icon: "key-lock-1",
    schema: Yup.string()
      .min(8, "models:profile.repeated_password.min")
      .oneOf([Yup.ref("password")], "models:profile.repeated_password.min")
      .required("models:profile.repeated_password.required"),
  },
};
