import React from 'react';
import { useTranslation } from 'react-i18next';
import { SzSpinner, SzTypographie } from '@suezenv/react-theme-components';

export default function Redirection(props) {
  const { t } = useTranslation('components');
  return (
    <div className="bg-white h-100 pt-5 text-center">
      <SzSpinner className="mt-5 mb-2"/>
      <SzTypographie weight="medium">{t('errors.redirection')}</SzTypographie>
    </div>
  );
}
