import React from "react";
import useCapaddress from "../../hooks/useCapaddress";

export type SearchType = "street" | "town";

export type SearchItemType = {
  [key: string]: any;
  countryCode: "FRA" | string;
  inputOutput: string;
  languageCode: string;
  locality: string;
  localityId: string;
  postalCode: string;
  qualityCode: string;
  step: "SearchStreet" | "CheckStreetNumber";
  streetNumberPosition: number;
  streetFlags: 0;
  streetId: string;
  streetInfo_1: string;
  streetInfo_2: string;
  streetName: string;
  streetNumber: string;
  streetType: "IMP" | "RUE" | string;
};

export interface CapaddressProps {
  onClick(item: SearchItemType, type: SearchType);
  value: string;
  type: SearchType;
  address?: SearchItemType;
}

export default function Capaddress(props: CapaddressProps) {
  const { onClick, value, type, address } = props;
  const addressesList = useCapaddress(type, value, address);

  return (
    <div
      className="capaddress position-absolute list-group overflow-auto"
      style={{ maxHeight: 250, zIndex: 100 }}
    >
      {addressesList.map((item, index) => {
        const { inputOutput } = item;
        return inputOutput ? (
          <button
            key={index}
            type="button"
            className="capaddress-item list-group-item list-group-item-action"
            onClick={(e) => onClick(item, type)}
          >
            {inputOutput}
          </button>
        ) : null;
      })}
    </div>
  );
}
