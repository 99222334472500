import React, { FC } from 'react';
import { SzTable } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { formatSold, hideInEnv, sortByDate } from '../../../services/Utils';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import Actions from './Actions';
import './billing.scss';
import { SzTableProps } from '@suezenv/react-theme-components/build/components/SzTable/SzTable';

export type TableBillingProps = {
  data: any;
  reference: any;
  startData: any;
  endData: any;
};

export const TableBilling: FC<TableBillingProps> = ({
  data,
  reference,
  startData,
  endData
}) => {
  const { t } = useTranslation('components');
  const hideInPreprodProd = hideInEnv('prod', 'preprod');
  const defaultSorted = [
    { dataField: 'dateFacture', order: 'asc' }
  ] as SzTableProps['defaultSorted'];

  const columns = [
    {
      dataField: 'numeroFacture',
      text: t('billing.table.title.numeroFacture'),
      formatter: (value: any) => (
        <span className="font-weight-bold">{value}</span>
      )
    },
    {
      dataField: 'dateFacture',
      text: t('billing.table.title.dateFacture'),
      sort: true,
      sortFunc: sortByDate
    },
    !hideInPreprodProd && {
      dataField: 'dateEcheance',
      text: t('billing.table.title.dateEcheance'),
      sort: true,
      sortFunc: sortByDate
    },
    {
      dataField: 'montantFacture',
      text: t('billing.table.title.montantFacture'),
      formatter: (value: any) => (
        <span className="font-weight-bold">{formatSold(value)} €</span>
      )
    },
    {
      dataField: 'status',
      text: t('billing.table.title.status'),
      formatter: (value: any) => {
        const context = value === 'A' ? 'waiting' : 'sold';
        const statusText = t('billing.table.content.status', { context });
        return (
          <span className={!value ? '-' : context}>
            {!value ? '-' : statusText}
          </span>
        );
      }
    },
    !hideInPreprodProd && {
      dataField: 'ConsoRelevée',
      text: t('billing.table.title.ConsoRelevée'),
      formatter: (value: any) => value && `${value} m3`
    },
    {
      dataField: 'downloadLink',
      text: t('billing.table.title.image'),
      formatter: (
        value: any,
        { numeroFacture, dateFacture }: { [key: string]: any }
      ) => (
        <Actions
          reference={reference}
          value={value}
          numeroFacture={numeroFacture}
          dateFacture={dateFacture}
        />
      )
    }
  ];

  return (
    <SzTable
      key={moment().unix()}
      keyField="numeroFacture"
      className="w-100 m-0"
      data={data.slice(startData, endData)}
      columns={columns}
      defaultSorted={defaultSorted}
      sticky={isMobile}
    />
  );
};

export default React.memo(TableBilling);
