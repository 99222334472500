import React from 'react';
import { useTranslation } from 'react-i18next';
import { SzTypographie } from '@suezenv/react-theme-components';
import { useAppSelector } from '../../../redux/hooks';
import HelperImage from '../../../assets/img/helper.svg';
import Loading from '../../../components/Loading';

export default function Telephone({
  horaireCrc,
  telCrcUrgence,
  telCrcJour,
  loading
}) {
  const { t } = useTranslation();
  const { formType } = useAppSelector(
    ({ customer }) => customer.requirementSelected
  );

  if (formType === 'contactTelUrgency') {
    return (
      <div className="row border m-0 cy-tel-urgence">
        <Loading count={5} duration={5} loading={loading} className="px-4">
          <div className="col-12">
            <SzTypographie
              variant="h1"
              weight="bold"
              color="disabled"
              className="mt-3 text-center"
              tag="p"
            >
              {t('customerService.assistance.urgency')}
            </SzTypographie>
          </div>
          <div className="col-12 text-center">
            <SzTypographie variant="huge">{telCrcUrgence}</SzTypographie>
            <SzTypographie variant="caption" size="M" className="mb-3">
              {t('customerService.assistance.taxes')}
            </SzTypographie>
          </div>
        </Loading>
      </div>
    );
  }

  return (
    <div className="row border m-0 cy-tel-crc">
      <div className="col-4 pl-5 pr-0">
        <Loading count={5} duration={5} loading={loading}>
          <SzTypographie
            variant="h1"
            weight="bold"
            color="disabled"
            className="mt-3"
            tag="p"
          >
            {t('customerService.assistance.hours')}
          </SzTypographie>
          <SzTypographie weight="bold">{horaireCrc}</SzTypographie>
        </Loading>
      </div>
      <div className="col-3">
        <img src={HelperImage} alt="helper" />
      </div>
      <div className="col-5">
        <Loading count={5} duration={5} loading={loading}>
          <SzTypographie variant="huge" className="mt-5">
            {telCrcJour}
          </SzTypographie>
          <SzTypographie variant="caption" size="M">
            {t('customerService.assistance.taxes')}
          </SzTypographie>
        </Loading>
      </div>
    </div>
  );
}
