import React, { FC } from 'react';
import { SzCard, SzTypographie } from '@suezenv/react-theme-components';
import { SzCardProps } from '@suezenv/react-theme-components/build/components/SzCard/SzCard';

export type CardType = {
  title: string;
  list: string[];
  image?: string;
};

export const Card: FC<CardType> = ({ title, list, image }) => {
  const cardProps: Omit<SzCardProps, 'children'> = {
    className: 'mt-2 bg-white',
    direction: 'left',
    color: 'gold'
  };
  return (
    <div className="container pb-5">
      <SzTypographie tag="h2" variant="large" weight="medium" className="mt-5">
        {title}
      </SzTypographie>
      <SzCard {...cardProps}>
        <div className="row">
          <div className="col-12 col-md-8 d-flex flex-column justify-content-center">
            <ul className="list-unstyled m-0 px-3">
              {list.map((content, key) => (
                <li key={key} className="ml-3">
                  <SzTypographie className="mb-0">{content}</SzTypographie>
                </li>
              ))}
            </ul>
          </div>
          {image && (
            <div className="col-12 col-md-4 mt-4 mt-md-0 d-flex flex-column justify-content-center">
              <img src={image} width="100%" />
            </div>
          )}
        </div>
      </SzCard>
    </div>
  );
};

export default Card;
