import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SzButton,
  SzModal,
  SzTypographie
} from '@suezenv/react-theme-components';
import { AppActions } from '../../../redux/actions';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../themes';
import { URL } from '../../../constants';

export default function AutoPaiement() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { show, contract } = useSelector(({ app, contract }: any) => {
    const show = app.modal && app.modalName === 'billing_paiement';
    const { item } = contract;

    return { show, contract: item || {} };
  });
  const { reference } = contract;
  const { tsmePart } = useTheme();
  const domain = tsmePart.slice(0, -1);
  const href = domain.concat(URL.TSME_PART.MODE_PAIEMENT, reference);

  const handleClose = () => {
    dispatch(AppActions.closeModal());
  };

  const handleContinue = () => {
    window.open(href, '_blank');
  };

  return (
    <SzModal
      size="lg"
      title={t('billing.modals.autopayment.title')}
      show={show}
      handleClose={handleClose}
    >
      <div className="row no-gutters">
        <SzTypographie variant="h2">
          {t('billing.modals.autopayment.subtitle')}
        </SzTypographie>
        <SzTypographie className="mb-4 col-12">
          {t('billing.modals.autopayment.info')}
        </SzTypographie>
        <SzButton variant="secondary" onClick={handleClose}>
          {t('billing.modals.autopayment.buttons.cancel')}
        </SzButton>
        <SzButton className="ml-auto" onClick={handleContinue}>
          {t('billing.modals.autopayment.buttons.continue')}
        </SzButton>
      </div>
    </SzModal>
  );
}
