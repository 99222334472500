import React, { useState } from 'react';
import {
  SzPagination,
  SzAccordion,
  SzTypographie,
  SzIcon
} from '@suezenv/react-theme-components';
import ConsumptionTable from '../Table/Consumption';
import { displayedContentPerPage } from '../../services/Utils';
import { useTranslation } from 'react-i18next';
import { hideInEnv, string_truncate } from '../../services/Utils';
import { isMobile } from 'react-device-detect';

export default function CollapseConsumption({ item, eventKey, onClick }) {
  const {
    name,
    reference,
    dateDebutDerniereConsoRelevee,
    dateFinDerniereConsoRelevee,
    nbCompteurTotal,
    compteursPro
  } = item;

  const { adresseDesserte } = compteursPro[0];
  const { t } = useTranslation('components');
  const [activePage, setActivePage] = useState(1);
  const [startData, setStartData] = useState(0);
  const [endData, setEndData] = useState(5);
  const [totalItemsCount] = useState(Math.ceil(nbCompteurTotal / 5));
  const hideInPreprodProd = hideInEnv('prod', 'preprod');

  const onChangePage = (page) => {
    const [start, end] = displayedContentPerPage(
      page,
      totalItemsCount,
      nbCompteurTotal
    );
    setStartData(start);
    setEndData(end);
    setActivePage(page);
  };
  const defaultText = t('components:consumption.collapse.defaultName');
  const contractName = name || defaultText;
  const address = adresseDesserte
    ? string_truncate(adresseDesserte, 67)
    : defaultText;
  const header = (
    <div className="row">
      <div className="col-md-3 col-12">
        <SzTypographie weight="bold" className="mb-1">
          {t('consumption.collapse.refClient', { reference })}
        </SzTypographie>
        <SzTypographie
          variant="caption"
          size="M"
          weight="medium"
          color="disabled"
        >
          {address}
        </SzTypographie>
      </div>
      {!isMobile && (
        <>
          <div className="col-md-3 col-12 border-left pl-4">
            <SzTypographie
              variant="caption"
              size="M"
              weight="medium"
              color="disabled"
              className="mb-1"
            >
              {t('consumption.collapse.nomContrat')}
            </SzTypographie>
            <SzTypographie tag="span" weight="medium">
              {contractName}
            </SzTypographie>
          </div>
          {!hideInPreprodProd && (
            <div className="col-md-3 col-12 d-flex align-items-center pl-4">
              <SzIcon variant="line" icon="data-file-bars-info" />
              <div className="ml-3">
                <SzTypographie
                  variant="caption"
                  size="M"
                  weight="medium"
                  color="disabled"
                  className="mb-1"
                >
                  {t('consumption.collapse.dateConso', {
                    dateDebutDerniereConsoRelevee,
                    dateFinDerniereConsoRelevee
                  })}
                </SzTypographie>
                <SzTypographie tag="span" weight="medium">
                  657 m3 relevé
                </SzTypographie>
              </div>
            </div>
          )}
          {!hideInPreprodProd && (
            <div className="col-md-3 col-12 d-flex align-items-center pl-4">
              <SzIcon variant="line" icon="alarm-bell" />
              <div className="ml-3">
                <SzTypographie
                  variant="caption"
                  size="M"
                  weight="medium"
                  color="disabled"
                  className="mb-1"
                >
                  {t('consumption.collapse.alerteCompteur')}
                </SzTypographie>
                <SzTypographie tag="span" weight="medium">
                  0
                </SzTypographie>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  const body = (
    <ConsumptionTable
      item={item}
      data={compteursPro.slice(startData, endData)}
    />
  );

  const footer = (
    <div className="d-flex align-items-center justify-content-center mt-3">
      <SzPagination
        totalItemsCount={totalItemsCount}
        activePage={activePage}
        onChange={onChangePage}
        itemsCountPerPage={1}
        pageRangeDisplayed={5}
      />
    </div>
  );

  return (
    <SzAccordion.SzCollapse
      eventKey={eventKey}
      onClick={onClick}
      header={header}
      body={body}
      footer={totalItemsCount > 1 && footer}
    />
  );
}
