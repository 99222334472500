import axios from "axios";

export const parseData = (data) => {
  const params = new URLSearchParams();
  for (const key in data) {
    params.append(key, data[key]);
  }
  return params.toString();
};

export const parseFormData = (data) => {
  const form = new FormData();
  for (const key in data) {
    form.append(key, data[key]);
  }
  return form;
}

const techErrorMessage =
  "Une erreur technique s'est produite. Merci de réessayer ultérieurement ou contacter votre service client.";

const option = {
  headers: {
    get: {
      "Content-Type": "application/json",
    },
  },
};

if (localStorage.getItem("token"))
  option.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

const instance = axios.create(option);
instance.defaults.transformRequest = [
  (data, headers) => {
    if (headers.post["Content-Type"] === "application/json") {
      return JSON.stringify(data);
    }
    if (headers.post["Content-Type"] === "multipart/form-data") {
      return parseFormData(data);
    }

    return data ? parseData(data) : data;
  },
];
instance.interceptors.request.use((req) => {
  !req.data && delete req.data;
  return req;
});
instance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      const { origin } = window.location;
      window.location = origin + process.env.REACT_APP_IDP_LOGIN_URL;
    }
    if (
      error.response.status >= 500 ||
      (Number(error.response.data.code) >= 10 &&
        Number(error.response.data.code) <= 13)
    ) {
      error.response.data.message = techErrorMessage;
    }

    return Promise.reject(error);
  }
);

export default instance;
