import React, { useContext } from 'react';
import { SzTitle, SzTypographie } from '@suezenv/react-theme-components';
import { useTheme } from '../../themes';

export default function PartB() {
  return (
    <article>
      <Intro />
      <AccessCondition />
      <SignupProcess />
      <AccountManagement />
      <OnlineService />
      <EditorGuarantee />
      <FinancialDisposition />
      <Duration />
      <PersonnalData />
      <ApplicableLaw />
      <PianoAnalytics />
    </article>
  );
}

function Intro() {
  const { brand } = useTheme();
  return (
    <>
      <div className="cpu-title">
        <SzTitle variant="h1">
          B. Conditions particulières d'utilisation du compte en ligne / service
          en ligne
        </SzTitle>
      </div>
      <SzTypographie>
        Les présentes Conditions Particulières régissent l’accès et
        l’utilisation du Compte en ligne proposé par l’Editeur. Elles complètent
        et font partie intégrante des Conditions Générales d’Utilisation du
        Site, que le Client reconnaît avoir lues, comprises et acceptées. Les
        Conditions applicables sont celles disponibles en ligne à la date de la
        consultation. Sauf disposition contraire, toute nouvelle caractéristique
        ou tout nouveau service proposé par l’Editeur seront soumis aux
        présentes Conditions d’Utilisation.
      </SzTypographie>
      <SzTypographie weight="medium">Définitions :</SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium">« Exploitant »</SzTypographie> : {brand}
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium">« Client »</SzTypographie> : désigne
        toute personne disposant d'une référence-client auprès de l’Exploitant.
        « Compte en ligne » : désigne le service d'accès personnalisé au compte
        du client.
      </SzTypographie>
    </>
  );
}

function AccessCondition() {
  const { tsmePart } = useTheme();

  return (
    <>
      <SzTitle variant="h2">1. Conditions d’accès au Compte en ligne</SzTitle>
      <SzTypographie>
        Le Compte en ligne permet au Client de l’Exploitant d’accéder, de
        visualiser et de gérer son Compte Client par internet. Différents
        services sont mis à sa disposition gratuitement et accessibles sur son
        Compte en Ligne à l’adresse
        <a href={tsmePart}>{tsmePart}</a> 24h/24 et 7j/7, dans les limites
        fixées par les Conditions Générales d’Utilisation du Site.
      </SzTypographie>
      <SzTypographie>
        Pour cela, le Client doit disposer d’un ordinateur, d’une connexion et
        d’un navigateur internet possédant la configuration adaptée.
      </SzTypographie>
    </>
  );
}

function SignupProcess() {
  return (
    <>
      <SzTitle variant="h2">
        2. Procédure d’inscription au Compte en ligne
      </SzTitle>
      <SzTypographie>
        Sur la page d’accueil, le lien « Je crée mon Compte en ligne » propose
        au Client, aux fins de vérification préalable de son raccordement au
        réseau de l’Exploitant de saisir :
      </SzTypographie>
      <ul>
        <li>
          <SzTypographie>Référence client</SzTypographie> : cette référence est
          indiquée sur toute facture émise par l’Exploitant. Elle permet
          d’identifier le Compte Client. Un lien propose au Client de lui
          montrer où trouver cette information sur sa facture.
        </li>
        <li>
          <SzTypographie>Identifiant</SzTypographie> : cet identifiant est
          indiqué sur toute facture émise par l’Exploitant. Un lien propose au
          Client de lui montrer où trouver cette information sur sa facture.
        </li>
      </ul>
      <SzTypographie>
        Pour cela, le Client doit disposer d’un ordinateur, d’une connexion et
        d’un navigateur internet possédant la configuration adaptée.
      </SzTypographie>
      <SzTypographie>
        Une fois ces informations complétées et validées, le Client saisit tous
        les champs obligatoires demandés, à savoir :
      </SzTypographie>
      <ul>
        <li>
          si son contrat concerne
          <SzTypographie weight="medium" tag="span">
            son domicile ou son activité professionnelle,
          </SzTypographie>
        </li>
        <li>
          choisit
          <SzTypographie weight="medium" tag="span">
            un nom pour son contrat
          </SzTypographie>
          afin de l'identifier plus facilement,
        </li>
        <li>
          <SzTypographie weight="medium" tag="span">
            Votre adresse e-mail
          </SzTypographie>{' '}
          : l’indication d’un e-mail valide est indispensable à la création d’un
          Compte en ligne. Un e-mail de confirmation de l’inscription sera
          notamment envoyé à cette adresse par l’Exploitant et ce dernier pourra
          adresser au Client des messages ou sa e-facture, s’il souscrit au
          Service, à cette adresse.
        </li>
        <li>
          <SzTypographie weight="medium" tag="span">
            Choisissez votre mot de passe
          </SzTypographie>
          : le Client choisit un mot de passe comportant 8 caractères
          minimum avec une lettre minuscule, une lettre majuscule et un nombre
          et le confirme. Celui-ci lui permettra d’accéder à son Compte en ligne
          de manière sécurisée.
        </li>
        <li>
          le recueil des consentements pour l’utilisation des coordonnées
          numériques.
        </li>
        <li>Les numéros de téléphone sont des champs facultatifs.</li>
      </ul>
      <SzTypographie>
        Avant validation de son inscription, en cochant la case « J’accepte les
        Conditions Générales d’Utilisation du Compte en ligne », le Client
        confirme avoir lu et accepté toutes les stipulations desdites
        Conditions. Pour finaliser la création de son Compte en ligne, le Client
        clique sur le bouton « Valider ».
      </SzTypographie>
    </>
  );
}

function AccountManagement() {
  return (
    <>
      <SzTitle variant="h2">3. Gestion du Compte en ligne</SzTitle>
      <SzTypographie>Le client a la possibilité de :</SzTypographie>
      <ul>
        <li>
          <SzTypographie weight="medium" tag="span">
            ajouter un contrat
          </SzTypographie>
          sur son Compte en ligne. Le Client peut activer plusieurs références
          Client pour des contrats en cours.
        </li>
      </ul>
    </>
  );
}

function OnlineService() {
  return (
    <>
      <SzTitle variant="h2">
        4. Rubriques du Compte en ligne / service en ligne
      </SzTitle>
      <SzTypographie>
        Tous les Services proposés par les Rubriques du Compte en ligne sont
        gratuits.
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          - Page Tableau de bord
        </SzTypographie>{' '}
        : cette page récapitule l’adresse du Client, sa référence Client, le
        montant de sa dernière facture datée, le net à payer s’il y a lieu,
        l’accès à la dernière facture et, le cas échéant, les services à
        souscrire.
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          - Mes factures
        </SzTypographie>{' '}
        : le Client peut consulter le montant et le détail de ses factures.
        Elles sont disponibles et téléchargeables au format Pdf pendant 2 ans.
        Si le Client souhaite conserver plus longtemps le fichier de ses
        factures, il lui appartient de procéder lui-même à leur archivage dans
        ses propres ressources de mémoire.
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          - Ma consommation
        </SzTypographie>{' '}
        : présente, par compteur, l’indication des index relevés ou estimés pour
        la facturation ainsi que la consommation d’eau en m3 correspondante.
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          - Cas particulier du Client bénéficiant de la télérelève
        </SzTypographie>{' '}
        : le Client bénéficiant de la télérelève de son/ses compteur(s) trouvera
        dans son Compte en ligne :
      </SzTypographie>
      <ul>
        <li>
          ses consommations facturées :
          <ul>
            <li>
              l’indication des index relevés ou estimés pour la facturation et
              la consommation correspondante;
            </li>
          </ul>
        </li>
        <li>
          l’historique de ses consommations : état mensuel sous forme graphique
          en m3 et en litre et dans un tableau récapitulatif par mois en m3;
        </li>
        <li>
          le détail de sa consommation : état journalier en m3 et en litre sous
          forme de graphique et dans un tableau récapitulatif en m3.
        </li>
      </ul>
      <SzTypographie>
        L’information sur la gestion des données liés au compteur équipé d’un
        dispositif de relève à distance est détaillée au paragraphe A/ 6.
        Données personnelles.
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          - Mes informations personnelles
        </SzTypographie>{' '}
        : le Client retrouve sur cette page ses informations personnelles
        (adresse e-mail, mot de passe, nom du titulaire de l’abonnement, adresse
        de facturation, coordonnées téléphoniques.
      </SzTypographie>
      <SzTypographie>
        Pour toute modification, le Client clique sur « modifier » et saisit ses
        données dans les champs prévus à cet effet. Il termine la procédure en
        cliquant sur le bouton « Valider ».
      </SzTypographie>
      <SzTypographie>
        Une modification sur le nom du titulaire d’abonnement (correction
        orthographique, ajout d’un co-titulaire..) ne peut pas se faire en
        ligne. Le Client doit contacter le service Client.
      </SzTypographie>
    </>
  );
}

function ClientEngagement() {
  return (
    <>
      <SzTitle variant="h2">5. Engagements du Client</SzTitle>
      <SzTypographie>
        Dans le cadre de l’utilisation de son Compte en ligne, le Client
        s’engage à :
      </SzTypographie>
      <ul>
        <li>
          fournir des informations exactes et complètes lors de la création de
          son Compte en ligne et pendant toute sa durée d’utilisation. Le Client
          reste seul responsable de l’exactitude et de la saisie des
          informations le concernant;
        </li>
        <li>
          préserver la confidentialité de ses identifiant et mot de passe. A
          défaut, le Client sera seul responsable des conséquences de la
          divulgation de ses identifiant et mot de passe, des informations le
          concernant ou de l’usage qui pourrait en être fait par une tierce
          personne;
        </li>
        <li>
          ne pas entraver ou perturber le bon fonctionnement du Compte en ligne;
        </li>
        <li>
          ne pas transmettre d’élément susceptible de contenir un virus ou autre
          élément susceptible d’endommager, intercepter, interférer tout ou
          partie du Site.
        </li>
      </ul>
      <SzTypographie>
        En outre, le Client s’interdit d’accéder, ou de tenter d’accéder, ou
        encore d’utiliser des données personnelles appartenant à d’autres
        utilisateurs du site.
      </SzTypographie>
    </>
  );
}

function EditorGuarantee() {
  return (
    <>
      <SzTitle variant="h2">6. Garantie de l’Editeur</SzTitle>
      <SzTypographie>
        L’Editeur n’accorde aucune garantie de quelque nature que ce soit,
        expresse ou implicite, quant à l’utilisation du Compte en ligne par le
        Client. De même, l’Editeur ne garantit pas que le Contenu ou les
        Services obtenus sur le Site seront conformes aux attentes du Client.
      </SzTypographie>
    </>
  );
}

function FinancialDisposition() {
  return (
    <>
      <SzTitle variant="h2">7. Dispositions Financières</SzTitle>
      <SzTypographie>
        Le Compte en ligne est mis gratuitement à la disposition du Client.
        Néanmoins, l’accès par internet au Compte en ligne reste à la charge du
        Client.
      </SzTypographie>
    </>
  );
}

function Duration() {
  return (
    <>
      <SzTitle variant="h2">8. Durée</SzTitle>
      <SzTypographie>
        Le Compte en ligne est ouvert par le Client pour une durée indéterminée.
      </SzTypographie>
    </>
  );
}

function PersonnalData() {
  return (
    <>
      <SzTitle variant="h2">9. Données Personnelles</SzTitle>
      <SzTypographie>
        (cf la rubrique A.6. des Conditions générales d’utilisation)
      </SzTypographie>
    </>
  );
}

function ApplicableLaw() {
  return (
    <>
      <SzTitle variant="h2">10. Loi applicable</SzTitle>
      <SzTypographie>
        Les présentes Conditions Particulières sont soumises à la loi française.
      </SzTypographie>
    </>
  );
}

function PianoAnalytics() {
  return (
    <>
      <div className="piano-title"><SzTitle variant="h2">11. Piano Analytics (mode opt-out)</SzTitle>
      </div>
      <SzTypographie>
        L’option « opt-out » mise en place par le service Piano Analytics (AT Internet) permet d’exclure les données de
        votre navigation sur ce site Web, qui utilise le service de mesure d’audience de Piano Analytics (AT Internet),
        qui y collecte des données statistiques.
      </SzTypographie>
      <SzTypographie>
        Pour activer cette option, vous devez au préalable activer le dépôt d’un cookie « analyticsOptOut ». Cette
        activation garantit que votre demande d’opt-out sera reconnue et mémorisée chaque fois que vous visiterez ce
        site Web. Cette option ne sera prise en compte que sur le navigateur sur lequel le cookie opt-out est déposé. Si
        vous n’activez pas ce cookie, si vous le supprimez ultérieurement, ou si vous consultez ce site Web en
        navigation privée, nous ne serons pas en mesure de détecter votre demande et nous continuerons à récolter des
        données sur le suivi de votre navigation.
        Je comprends qu’après l’activation de cette option, je serai comptabilisé comme un internaute refusant d’être
        audité et que mes données de navigation seront exclues des données restituées par Piano Analytics (AT Internet).
      </SzTypographie>
      <div className="row">
        <div className="mx-auto underlined-link activate-opt-out">
          <a href={'/cgu/#'} className="opt-out-lnk">Activer l’option « opt-out »</a>
        </div>
      </div>
    </>
  );
}
