import React, { useState, useEffect } from "react";
import {
  SzSelect,
  SzChart,
  SzTypographie,
  SzSpinner,
  SzButton,
  SzIcon,
} from "@suezenv/react-theme-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./counter.scss";
import ExportModal from "./exportModal";
import useHistogram from "../../hooks/useHistogram";

export default function Histogram() {
  const { t } = useTranslation("components", "pages");
  const { error, loading, measures } = useSelector(
    ({ consumption }) => consumption
  );
  const [selectedOption, setSelectedOption] = useState({
    label: moment().year(),
    value: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    // When measure data are loaded, loop over them to define what is the most recent year with available data
    if (measures.length > 0) {
      const highestYearWithData = measures.reduce(
        (acc, current) =>
          Math.max(moment(current.date, "DD/MM/YYYY").year(), acc),
        0
      ); // initial year set to 0 to be sure the first year returned is the first year read.

      setSelectedOption({
        label: highestYearWithData,
        value: moment().format(`${highestYearWithData}-MM-DD`),
      });
    }
  }, [measures]);

  const releveeFilter = ({ type }) => type === "R";
  const estimeeFilter = ({ type }) => type === "E";

  const categoriesSort = ({ date }) =>
    date.indexOf(selectedOption.label) >= 0 && date;

  const yearList = [];
  const dateListMap = ({ date }) => {
    const label = moment(date, "DD/MM/YYYY").year();
    const value = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    if (yearList.indexOf(label) < 0) {
      yearList.push(label);
      return {
        value,
        label,
      };
    }
  };

  const selector = ({ consumption }) => {
    const measures = consumption.measures.filter(
      ({ date }) => date.indexOf(selectedOption.label) >= 0
    );
    const categories = measures.map(categoriesSort).reverse();
    const sort = ({ volume, date, index }) => ({
      y: volume,
      x: categories.indexOf(date),
      name: date,
      lastIndex: index,
    });
    const dataListRelevee = measures.filter(releveeFilter).map(sort).reverse();
    const dataListEstime = measures.filter(estimeeFilter).map(sort).reverse();

    const dateList = consumption.measures
      .flatMap(dateListMap)
      .filter((option) => option !== undefined);

    return [dataListRelevee, dataListEstime, categories, dateList];
  };

  const [dataListRelevee, dataListEstime, categories, dateList] =
    useSelector(selector);
  const handleChange = (selectedOption) => setSelectedOption(selectedOption);

  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const SelectYear = () => (
    <SzSelect
      options={dateList}
      value={selectedOption}
      onChange={handleChange}
      isSearchable={false}
      className="border-secondary ml-4 col-3"
      name="data-year"
    />
  );

  const hookOptions = {
    chart: {
      events: {
        render: function () {
          const reducer = (a, b) => a + b;
          const seriesData = this.series
            .map(({ data }) => {
              const dataValue = data.map(({ y }) => y !== undefined && y);
              return (dataValue || []).reduce(reducer, 0);
            })
            .reduce(reducer, 0);

          const options = {
            text: t("consumption.histogram.total", {
              value: seriesData > 0 ? seriesData : 0,
            }),
          };
          this.setTitle(options, false, false);
        },
      },
    },
    tooltip: {
      formatter: function () {
        const options = {
          date: this.point.name,
          name: this.series.name,
          y: this.y,
          lastIndex: this.point.lastIndex,
        };
        return t("consumption.histogram.tooltip", options);
      },
    },
    xAxis: {
      categories,
      type: "datetime",
      min: 0,
      max: categories.length - 1,
    },
    plotOptions: {
      column: {
        grouping: false,
      },
    },
    series: [
      {
        name: "Consommation relevée",
        data: loading ? [] : dataListRelevee,
        color: "#84DFFF",
        pointPadding: 0,
      },
      {
        name: "Consommation estimée",
        data: loading ? [] : dataListEstime,
        color: "#2E88FF",
        pointPadding: 0,
      },
    ],
  };

  const options = useHistogram(hookOptions);

  return (
    <>
      <div className="period-selector row no-gutters justify-content-center align-items-center bg-white mb-3 shadow-sm py-2 px-3">
        <SzTypographie weight="medium" tag="span">
          {t("pages:consumption.histogram.selectYear")}
        </SzTypographie>
        <SelectYear />
        <SzButton className="ml-auto" onClick={handleOpen} variant="tertiary">
          <SzIcon icon="office-file-xls-1" variant="bold" />
        </SzButton>
      </div>
      <div className="shadow-sm bg-white p-3">
        {error && (
          <div className="position-absolute chart-error text-center d-flex">
            <div className="justify-content-center align-items-center row">
              {error}
            </div>
          </div>
        )}
        {loading && (
          <div className="position-absolute chart-error text-center d-flex">
            <div className="justify-content-center align-items-center row">
              <SzSpinner variant={"secondary"} />
            </div>
          </div>
        )}
        <SzChart options={options} />
      </div>
      {show && <ExportModal show={show} handleClose={handleClose} />}
    </>
  );
}
