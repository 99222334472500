import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ProductActions } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import { SzButton, SzTypographie } from '@suezenv/react-theme-components';
import { SzButtonProps } from '@suezenv/react-theme-components/build/components/SzButton/SzButton';
import * as CSS from 'csstype';

export type BannerProps = {
  title: string;
  subtitle: string;
  text: string;
  video?: string;
  image?: string;
  poster?: string;
  leaflet?: string;
  file?: string;
};

export const Banner: FC<BannerProps> = ({
  title,
  subtitle,
  text,
  video,
  poster,
  image,
  file
}) => {
  const dispatch = useDispatch();
  const { showFormAction } = ProductActions;
  const { t } = useTranslation();
  const styleVideo: CSS.Properties = {
    position: 'relative',
    top: '50px',
    borderRadius: '.5rem'
  };

  const iconProps: SzButtonProps = {
    icon: 'common-file-text-download',
    variant: 'tertiary'
  };

  const onClick = () => dispatch(showFormAction());

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 pt-4 pb-lg-5">
          <SzTypographie variant="large" tag="h1" weight="medium">
            {title}
          </SzTypographie>
          <SzTypographie weight="medium" color="disabled">
            {subtitle}
          </SzTypographie>
          <SzTypographie>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </SzTypographie>
          <div className="row no-gutters mt-4">
            <div className="d-flex align-items-center">
              <SzButton icon="envelope" onClick={onClick}>
                {t('product.contact')}
              </SzButton>
            </div>
            {file && (
              <div className="d-flex justify-content-center align-items-end">
                <SzButton
                  className="d-none d-md-inline-flex text-center ml-3"
                  {...iconProps}
                >
                  <a href={file} target="_blank" rel="noopener">
                    {t('product.download')}
                  </a>
                </SzButton>
              </div>
            )}
            {poster && (
              <div className="d-flex justify-content-center align-items-end">
                <SzButton
                  className="d-none d-md-inline-flex text-center ml-3"
                  {...iconProps}
                >
                  <a href={poster} target="_blank" rel="noopener">
                    {t('product.download')}
                  </a>
                </SzButton>
                <a
                  href={poster}
                  target="_blank"
                  rel="noopener"
                  className="ml-3"
                >
                  <SzButton
                    className="d-block d-md-none text-center"
                    {...iconProps}
                  />
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-lg-6 d-flex align-items-end">
          {video && (
            <iframe
              className="shadow-sm"
              // style={styleVideo} A CORRIGER la balise style n'existe pas
              width="100%"
              height="300px"
              src={video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
          {image && (
            <img
              className="shadow-sm"
              src={image}
              // style={styleVideo} A CORRIGER la balise style n'existe pas
              width="100%"
              alt={title}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
