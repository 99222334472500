import * as Yup from 'yup';

const fixFMatch = /^0([0-5]|9)[0-9]{8}$/;
const mobileMatch = /^0(6|7)[0-9]{8}$/;

export default {
  billing: {
    name: {
      className: 'col-10 mt-3',
      label: 'models:editContract.name.label',
      icon: 'pencil-write',
      placeholder: 'models:editContract.name.placeholder',
      type: 'text',
      schema: Yup.string().max('30', "Message d'erreur")
    },
    siret: {
      className: 'col-5 pr-md-3 align-top mb-4',
      label: 'models:editContract.siret.label',
      icon: 'pencil-write',
      placeholder: 'models:editContract.siret.placeholder',
      type: 'text',
      schema: Yup.string().matches(
        /([0-9]{14})/,
        'models:editContract.siret.match'
      )
    },
    naf_code: {
      className: 'col-5 pl-md-3 align-top',
      label: 'models:editContract.naf_code.label',
      icon: 'pencil-write',
      placeholder: 'models:editContract.naf_code.placeholder',
      type: 'text',
      schema: Yup.string().matches(
        /^\d{4}[A-Za-z]{1}$/,
        'models:editContract.naf_code.match'
      )
    },
    billing_zip_code: {
      className: 'col-5 pr-md-3 align-top',
      label: 'models:editContract.billing_zip_code.label',
      placeholder: 'models:editContract.billing_zip_code.placeholder',
      type: 'text',
      schema: Yup.string().matches(
        /^\d{5}$/,
        'models:editContract.billing_zip_code.error'
      )
    },
    billing_town: {
      className: 'col-5 pl-md-3 align-bottom',
      label: 'models:editContract.billing_town.label',
      placeholder: 'models:editContract.billing_town.placeholder',
      type: 'text',
      schema: Yup.string()
    },
    billing_address_1: {
      label: 'models:editContract.billing_address_1.label',
      className: 'col-12',
      placeholder: 'models:editContract.billing_address_1.placeholder',
      type: 'text',
      schema: Yup.string()
    },
    billing_address_2: {
      className: 'col-12',
      placeholder: 'models:editContract.billing_address_2.placeholder',
      type: 'text',
      schema: Yup.string()
    }
  },
  contact: {
    email: {
      className: 'col-10 mt-3',
      label: 'models:editContract.email.label',
      placeholder: 'models:editContract.email.placeholder',
      type: 'text',
      icon: 'envelope',
      schema: Yup.string().email('review.emailModel.email.error')
    },
    phone: {
      className: 'col-5 pr-md-3 align-top',
      label: 'models:editContract.office_phone.label',
      placeholder: 'models:editContract.office_phone.placeholder',
      type: 'text',
      icon: 'phone-actions-voice-mail',
      schema: Yup.string().matches(
        fixFMatch,
        "Le numéro de téléphone n'est pas valide"
      )
    },
    mobile_phone: {
      className: 'col-5 pl-md-3 align-top',
      label: 'models:editContract.mobile_phone.label',
      placeholder: 'models:editContract.mobile_phone.placeholder',
      type: 'text',
      icon: 'mobile-phone',
      schema: Yup.string().matches(
        mobileMatch,
        "Le numéro de téléphone n'est pas valide"
      )
    }
  }
};
