import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SzModal, SzTypographie } from "@suezenv/react-theme-components";
import { NameForm } from "../../forms";
import { usePages } from "../../hooks";
import { useHistory } from "react-router";

export default function ProfileModal({ setRun }) {
  const { t } = useTranslation("pages");
  const isProfil = usePages("renseignement-profil");
  const [show, setShow] = useState(isProfil);
  const title = t("dashboard.profile.title");
  const history = useHistory();

  const handleClose = () => {
    setShow(false);
    setRun(true);
  };

  return (
    <SzModal title={title} show={show} size="lg" handleClose={handleClose}>
      <SzTypographie>{t("dashboard.profile.subtitle")}</SzTypographie>
      <NameForm handleClose={handleClose} />
    </SzModal>
  );
}
