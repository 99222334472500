import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { UnconnectedContainer as Container } from '../components/Container';

export default function CustomRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <Container>
          <Component {...props} />
        </Container>
      )}
    />
  );
}
