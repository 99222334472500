import React from 'react';
import { useSelector } from 'react-redux';
import {
  SzButton,
  SzIcon,
  SzTypographie
} from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';

export default function Identity({ onClick }) {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state: any) => state.app);

  return (
    <div className="row mb-3 border pt-4 pb-3">
      <div className="col-12 col-md-5">
        <div className="row">
          <div className="col-2 my-auto">
            <SzIcon variant="line" icon="envelope" />
          </div>
          <div className="col-10">
            <SzTypographie
              weight="medium"
              color="disabled"
              variant="caption"
              size="M"
            >
              {t('customerService.contactForm.labelEmail')}
            </SzTypographie>
            <SzTypographie weight="bold" className="text-wrap">
              {currentUser.email}
            </SzTypographie>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-5">
        <div className="row">
          <div className="col-2 my-auto">
            <SzIcon variant="line" icon="single-neutral-id-card-3" />
          </div>
          <div className="col-10">
            <SzTypographie
              weight="medium"
              color="disabled"
              variant="caption"
              size="M"
            >
              {t('customerService.contactForm.labelIdentity')}
            </SzTypographie>
            <SzTypographie weight="bold">
              {currentUser.last_name} {currentUser.first_name}
            </SzTypographie>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-2 text-right my-auto">
        <SzButton
          variant="tertiary"
          className="mr-3"
          icon="pencil-write"
          onClick={onClick}
        />
      </div>
    </div>
  );
}
