import React, { Suspense, FC } from 'react';
import { ConnectedCustomHeader as Header } from '../Header';
import { SzSpinner } from '@suezenv/react-theme-components';
import './container.scss';
import clsx from 'clsx';

export const Container: FC = ({ children, ...props }) => {
  const keyFullScreenNoHeader = 'FullScreenNoHeader';
  
  return (
    <>
      {props['displayType'] !== keyFullScreenNoHeader && (
        <Suspense fallback={<SzSpinner />}>
          <Header {...props} />
        </Suspense>
      )}

      <div
        className={clsx('tsme-custom-container', {
          'overflow-auto p-3': props['displayType'] !== keyFullScreenNoHeader
        })}
      >
        {children}
      </div>
    </>
  );
};

export default Container;
