import React, { useState } from 'react';
import { displayedContentPerPage } from '../services/Utils';

export default function usePagination(
  data: any[],
  pageRangeDisplayed: number,
  defaultActivePage: number = 1
) {
  const [activePage, setActivePage] = useState(defaultActivePage);
  const [startData, setStartData] = useState(0);
  const [endData, setEndData] = useState(pageRangeDisplayed);
  const totalItemsCount = Math.ceil(data.length / pageRangeDisplayed);

  const onChangePage = (page: number) => {
    const [start, end] = displayedContentPerPage(
      page,
      totalItemsCount,
      data.length,
      pageRangeDisplayed
    );

    setStartData(start);
    setEndData(end);
    setActivePage(page);
  };

  return {
    activePage,
    data: data.slice(startData, endData),
    totalItemsCount,
    onChangePage
  };
}
