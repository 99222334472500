import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import store, { history } from './redux';
import Router from './router';
import { Theme, SzSpinner } from '@suezenv/react-theme-components';
import { useTheme } from './themes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AnalyticsProvider } from 'use-analytics';
import analytics from './analytics';
import './App.scss';

export default function App() {
  const queryClient = new QueryClient();
  const { brand } = useTheme();
  const loader = <SzSpinner />;

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={loader}>
        <Provider store={store}>
          <AnalyticsProvider instance={analytics}>
            <ConnectedRouter history={history}>
              <Theme.Provider brand={brand}>
                <Router />
              </Theme.Provider>
            </ConnectedRouter>
          </AnalyticsProvider>
        </Provider>
      </Suspense>
    </QueryClientProvider>
  );
}
