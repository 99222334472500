import React from 'react';
import { SzTypographie, SzButton } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import Text from '../../components/Text';
import { hideInEnv } from '../../services/Utils';
import { AppActions } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import TechSheetModal from '../../components/Modal/Consumption/TechSheet';

export default function DetailCard({ reference, compteursPro }) {
  const { t } = useTranslation('pages');
  const { matriculeCompteur, adresseDesserte, usage, codeEquipement } =
    compteursPro && reference ? compteursPro[0] : {};
  const dispatch = useDispatch();
  const hideInPreprodProd = hideInEnv('prod', 'preprod');
  const openModal = () => {
    dispatch(AppActions.openModal());
  };
  return (
    <div className="detail-card bg-white shadow-sm d-flex h-100 flex-column mr-xl-2">
      <div className="detail-card-header p-0 mb-3 bg-white">
        <SzTypographie variant="h1" className="bg-light p-4">
          {t('consumption.details.refNum', { reference })}
        </SzTypographie>
      </div>
      <Text
        className="border-bottom px-4"
        label={t('consumption.details.contractSeries')}
      >
        {matriculeCompteur}
      </Text>
      <div className="px-4 py-3">
        {!hideInPreprodProd && (
          <Text label={t('consumption.details.leakPresumption')}>Test</Text>
        )}
        <Text label={t('consumption.details.address')}>{adresseDesserte}</Text>
        <Text label={t('consumption.details.reportMode')}>
          {t('consumption.details.releveMode', { context: codeEquipement })}
        </Text>
        {!hideInPreprodProd && (
          <Text label={t('consumption.details.use')}>
            {t('consumption.details.usage', { context: usage })}
          </Text>
        )}
      </div>
      <div className="mt-auto pl-3 pb-4">
        <SzButton
          icon="common-file-text"
          variant="secondary"
          className="techSheet"
          onClick={() => openModal()}
        >
          {t('consumption.details.techLink')}
        </SzButton>
        <TechSheetModal />
      </div>
    </div>
  );
}
