import React, { FC, useState } from 'react';
import { SzAccordion, SzPagination } from '@suezenv/react-theme-components';
import TableBilling from '../../Table/Billing';
import { displayedContentPerPage } from '../../../services/Utils';
import Header, { CollapseHeaderProps } from './Header';

export type CollapseBillingProps = {
  facture: CollapseHeaderProps;
  eventKey: string;
  onClick: () => any;
};

export const CollapseBilling: FC<CollapseBillingProps> = ({
  facture,
  eventKey,
  onClick
}) => {
  const { reference, facturesPro, nbFacture } = facture;
  const [activePage, setActivePage] = useState(1);
  const [startData, setStartData] = useState(0);
  const [endData, setEndData] = useState(5);
  const [totalItemsCount] = useState(Math.ceil(nbFacture / 5));

  const onChangePage = (page: number) => {
    const [start, end] = displayedContentPerPage(
      page,
      totalItemsCount,
      nbFacture
    );
    setStartData(start);
    setEndData(end);
    setActivePage(page);
  };

  const body = (
    <TableBilling
      data={facturesPro}
      startData={startData}
      endData={endData}
      reference={reference}
    />
  );

  const footer = (
    <div className="d-flex align-items-center justify-content-center">
      <SzPagination
        totalItemsCount={totalItemsCount}
        activePage={activePage}
        onChange={onChangePage}
        itemsCountPerPage={1}
        pageRangeDisplayed={5}
      />
    </div>
  );

  return (
    <SzAccordion.SzCollapse
      eventKey={eventKey}
      onClick={onClick}
      header={<Header {...facture} />}
      body={body}
      footer={totalItemsCount > 1 && footer}
    />
  );
};

export default CollapseBilling;
