import React from 'react';
import classnames from 'classnames';
import { SzAlert, SzIcon, SzTypographie } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import './PasswordRules.scss';

export default function PasswordRules({ passwordValidation }) {
  const { t } = useTranslation('components');
  const { length, upper, small, digit, other } = passwordValidation;
  const classes = {
    length: classnames({ 'passRules__square pl-3': !length }),
    upper: classnames({ 'passRules__square pl-3': !upper }),
    small: classnames({ 'passRules__square pl-3': !small }),
    digit: classnames({ 'passRules__square pl-3': !digit }),
    other: classnames({ 'passRules__square pl-3': !other })
  };
  return (
    <div className="passRules d-md-inline-block pl-md-3 col-12 col-md-6">
      <SzAlert variant="info" transparent className="align-items-start">
        <div className="py-2">
          <SzTypographie
            className="mb-2 text-gray"
            color="disabled"
            weight="medium"
          >
            {t('passwordValidation.title')}
          </SzTypographie>
          <ul className="list-unstyled m-0">
            <li className={classes.length}>
              {length && (
                <SzIcon
                  variant="bold"
                  icon="check"
                  className="normal text-primary-brand mr-1"
                />
              )}
              <SzTypographie tag="span" color="disabled" weight="medium">
                {t('passwordValidation.length')}
              </SzTypographie>
            </li>
            <li className={classes.upper}>
              {upper && (
                <SzIcon
                  variant="bold"
                  icon="check"
                  className="normal text-primary-brand mr-1"
                />
              )}
              <SzTypographie tag="span" color="disabled" weight="medium">
                {t('passwordValidation.upper')}
              </SzTypographie>
            </li>
            <li className={classes.small}>
              {small && (
                <SzIcon
                  variant="bold"
                  icon="check"
                  className="normal text-primary-brand mr-1"
                />
              )}
              <SzTypographie tag="span" color="disabled" weight="medium">
                {t('passwordValidation.small')}
              </SzTypographie>
            </li>
            <li className={classes.digit}>
              {digit && (
                <SzIcon
                  variant="bold"
                  icon="check"
                  className="normal text-primary-brand mr-1"
                />
              )}
              <SzTypographie tag="span" color="disabled" weight="medium">
                {t('passwordValidation.digit')}
              </SzTypographie>
            </li>
            <li className={classes.other}>
              {other && (
                <SzIcon
                  variant="bold"
                  icon="check"
                  className="normal text-primary-brand mr-1"
                />
              )}
              <SzTypographie tag="span" color="disabled" weight="medium">
                {t('passwordValidation.other')}
              </SzTypographie>
            </li>
          </ul>
        </div>
      </SzAlert>
    </div>
  );
}
