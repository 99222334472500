import * as Yup from 'yup';

export default {
  filter_field: {
    placeholder: 'review.searchBox',
    name: 'filterContract',
    type: 'text',
    schema: Yup.string()
  }
};
