import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  SzSpinner,
  SzButton,
  SzBox,
  SzAccordion,
  SzTypographie
} from '@suezenv/react-theme-components';
import Error from '../Error/ErrorModel';
import { useTranslation } from 'react-i18next';
import CollapseLoader from './CollapseLoader';

export default function CollapseContainer({
  title,
  Collapse,
  showMore,
  loading,
  formLoading,
  list,
  onInit,
  displayShowMore,
  serverError
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation('components');
  const [activeKey, setActiveKey] = useState('0');
  const onClick = (key) => setActiveKey(key === activeKey ? null : key);

  useEffect(() => {
    onInit && dispatch(onInit());
  }, []);

  const ShowMoreButton = () => (
    <SzBox className="d-flex justify-content-center mb-3">
      <SzButton
        className="cy-btn-showmore btn-show-more"
        variant="secondary"
        onClick={showMore}
        loader={loading}
      >
        {t('collapseContainer.showMore')}
      </SzButton>
    </SzBox>
  );

  if (formLoading) {
    return <CollapseLoader />;
  }

  if (serverError) {
    return (
      <Error
        title={t('components:errors.techError')}
        errorType={'server-error'}
      />
    );
  }

  if (!(list || []).length) {
    return (
      <Error
        title={t('components:errors.noResult')}
        errorType={'no-result'}
        searchError
      />
    );
  }

  return (
    <div className="collapse-container">
      <SzTypographie
        variant="caption"
        size="M"
        weight="medium"
        className="collapse-title border-bottom my-3"
      >
        {title}
      </SzTypographie>
      <SzAccordion activeKey={activeKey}>
        {list.map((item, key) => (
          <Collapse
            key={key}
            eventKey={key.toString()}
            item={item}
            facture={item}
            onClick={onClick}
          />
        ))}
      </SzAccordion>
      {displayShowMore && <ShowMoreButton />}
    </div>
  );
}
