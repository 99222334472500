import { CONTRACT } from "../types";
import { closeModal, getProfile } from "./app";
import { createdUser } from "./signup";
import {
  login,
  startLoading,
  stopLoading,
  setTokenValid,
  setError,
  resetError
} from "./app";
import { push } from "connected-react-router";
import axios from "../../services/Request";

export const setListContract = (list) => ({
  type: CONTRACT.LIST.SET,
  list
});
export const setListContractMemory = (list) => ({
  type: CONTRACT.LIST.MEMORY,
  list
});
export const setContract = (item) => ({
  type: CONTRACT.ITEM.SET,
  item
});
export const updateListContractWithItem = (item) => ({
  type: CONTRACT.LIST.UPDATE,
  item
});
export const contractStartLoading = () => ({
  type: CONTRACT.LOADING.START
});
export const contractStopLoading = () => ({
  type: CONTRACT.LOADING.STOP
});
export const contractSetError = (error) => ({
  type: CONTRACT.ERROR.SET,
  error
});
export const contractResetError = () => ({
  type: CONTRACT.ERROR.RESET
});
export const contractFormStartLoading = () => ({
  type: CONTRACT.FORM.LOADING.START
});
export const contractFormStopLoading = () => ({
  type: CONTRACT.FORM.LOADING.STOP
});
export const contractFormSetError = (error) => ({
  type: CONTRACT.FORM.ERROR.SET,
  error
});
export const contractFormResetError = () => ({
  type: CONTRACT.FORM.ERROR.RESET
});
export const contractModalSetActiveForm = (activeForm) => ({
  type: CONTRACT.MODAL.SET_ACTIVE_FORM,
  activeForm
});

export const updateContract = (form) => {
  const url = "/api/contract/update_info";
  return async (dispatch) => {
    dispatch(contractFormStartLoading());
    try {
      await axios.post(url, form);
      dispatch(updateListContractWithItem(form));
      dispatch(closeModal());
    } catch ({ response }) {
      dispatch(contractFormSetError(response.data.message));
    }
    dispatch(contractFormStopLoading());
  };
};

export const updateIban = (form) => {
  const url = "/api/contract/update_iban";
  return async (dispatch) => {
    dispatch(contractFormStartLoading());
    try {
      await axios.post(url, form);
      form["rib_holder"] = form["holder"];
      dispatch(updateListContractWithItem(form));
      dispatch(closeModal());
    } catch ({ response }) {
      dispatch(contractFormSetError(response.data.message));
    }
    dispatch(contractFormStopLoading());
  };
};

export const addContract = (form, isNew) => {
  const url = "/api/user/addContract";
  const body = { ...form };
  body.reference = body.reference.replace(/(,|-|\.)/gi, "");
  return async function (dispatch) {
    dispatch(contractFormStartLoading());
    try {
      await axios.post(url, body);
      dispatch(contractFormStopLoading());
      dispatch(contractFormResetError());
      dispatch(setContract(body));
      dispatch(getProfile());
      dispatch(login());
      dispatch(closeModal());
      if (isNew) {
        dispatch(createdUser(true));
        dispatch(push("/espace-personnel"));
      }
    } catch ({ response }) {
      dispatch(contractFormStopLoading());
      dispatch(contractFormSetError(response.data.message));
    }
  };
};

export const selectByRef = (ref) => {
  return (dispatch, getState) => {
    const contract = getState().contract.list.reduce((first, second) => {
      if (first && first.reference === ref) return first;
      if (second && second.reference === ref) return second;
    }, {});
    dispatch(setContract(contract));
  };
};

export const contractFilter = (form) => {
  return function (dispatch, getState) {
    const { contract } = getState();
    const { memoryList } = contract;
    const filter = form.filter_field.split(" ").join("|");
    const toSearch = new RegExp(filter, "gi");
    const contractPro = memoryList.filter(
      ({ reference, name, served_address }) =>
        (reference && reference.match(toSearch)) ||
        (name && name.match(toSearch)) ||
        (served_address && served_address.match(toSearch))
    );
    dispatch(setListContract(contractPro));
  };
};

export const initContractFilter = () => {
  return function (dispatch, getState) {
    const { contract } = getState();
    dispatch(setListContract(contract.memoryList));
  };
};
export default {
  setListContract,
  setListContractMemory,
  setContract,
  contractFormResetError,
  updateListContractWithItem,
  updateContract,
  updateIban,
  addContract,
  selectByRef,
  contractFilter,
  initContractFilter,
  contractModalSetActiveForm
};
