import React from "react";
import { SearchContainer } from "../../components/SearchArea";
import { ConsumptionSearchForm } from "../../forms";

export default function Searchbox() {
  return (
    <SearchContainer>
      <ConsumptionSearchForm />
    </SearchContainer>
  );
}
