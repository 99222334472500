import React, { useRef, useEffect } from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import { ContactForm } from "./ContactForm";
import Assistance from "./Assistance";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import useScrollTo from "../../../hooks/useScrollTo";

export default function FormToDisplay() {
  const { t } = useTranslation("pages");
  const style = {
    borderTop: "8px solid var(--blue-sky-light)"
  };
  const showContactForm = ["contactForm", "contactFormWithRefs"];
  const showContactTel = ["contactTelMtc", "contactTelUrgency"];
  const { requirementSelected } = useAppSelector(({ customer }) => customer);

  const containerRef = useRef<HTMLDivElement>(null);

  const scroll = useScrollTo();

  useEffect(() => {
    const { current } = containerRef;
    scroll(current?.offsetTop);
  }, [requirementSelected]);

  if (requirementSelected) {
    const { formType } = requirementSelected;

    return (
      <div ref={containerRef} className="row no-gutters my-5 bg-white px-5 cy-formToDisplay" style={style}>
        <div className="col-12 mt-4 mb-2">
          <SzTypographie weight="bold" className="text-center">
            {requirementSelected.title}
          </SzTypographie>
        </div>
        <div className="col-12 mb-2">
          <SzTypographie weight="regular" className="col-12">
            {t("customerService.labelsform", { context: formType })}
          </SzTypographie>
        </div>
        <div className="col-12 mb-2">
          {showContactTel.includes(formType) && <Assistance />}
          {showContactForm.includes(formType) && <ContactForm />}
        </div>
      </div>
    );
  }
  return null;
}
