import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

const duration = 500;

export default function FadeSlide({ children, inProp }) {
  const defaultStyle = {
    transition: `${duration}ms ease-in-out`,
    position: 'relative',
    opacity: 0,
    left: 50
  };

  const transitionStyles = {
    entering: { opacity: 1, left: 0 },
    entered: { opacity: 1, left: 0 },
    exiting: { opacity: 0, left: 50 },
    exited: { opacity: 0, left: 50 }
  };
  return (
    <Transition in={inProp} timeout={duration}>
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}
