import { BILLING } from "../types";

const now = new Date();
const lastYear = new Date();
lastYear.setMonth(lastYear.getMonth() - 12);

export const form = {
  searched: {
    currentPage: 1,
    itemsPerPage: 5,
    dates: [lastYear, now],
  },
  loading: false,
  error: null,
};
export const initialState = {
  currentPage: 1,
  totalPage: 1,
  nbCodeRefFull: 0,
  clientFacturesPro: [],
  memoryclientFacturesPro: [],
  selectedEncartInfos: {},
  listsEncarts: [],
  error: null,
  loading: false,
  encartInfos: [],
  encartLoading: false,
  nbFactureFull: 0,
  form,
  download: [],
};

export default function billing(state = { ...initialState }, action) {
  const {
    clientFacturesPro,
    memoryclientFacturesPro,
    listsEncarts,
    currentPage,
    error,
    totalPage,
    searched,
    nbCodeRefFull,
    download,
    nbFactureFull,
    encartInfos,
  } = action;
  switch (action.type) {
    case BILLING.LIST.MEMORY:
      return (state = { ...state, memoryclientFacturesPro });
    case BILLING.LIST.ENCART:
      return (state = { ...state, listsEncarts });
    case BILLING.LOADING.START:
      return (state = { ...state, loading: true });
    case BILLING.LOADING.STOP:
      return (state = { ...state, loading: false });
    case BILLING.ERROR.SET:
      return (state = { ...state, error: error });
    case BILLING.ERROR.RESET:
      return (state = { ...state, error: null });
    case BILLING.FORM.LOADING.START:
      return (state = { ...state, form: { ...state.form, loading: true } });
    case BILLING.FORM.LOADING.STOP:
      return (state = { ...state, form: { ...state.form, loading: false } });
    case BILLING.FORM.ERROR.SET:
      return (state = { ...state, form: { ...state.form, error } });
    case BILLING.FORM.ERROR.RESET:
      return (state = { ...state, form: { ...state.form, error: null } });
    case BILLING.FORM.SEARCHED.SET:
      return (state = { ...state, form: { ...state.form, searched } });
    case BILLING.FORM.SEARCHED.RESET:
      return (state = { ...state, form: { ...state.form, searched: {} } });
    case BILLING.CURRENT_PAGE.SET:
      return (state = { ...state, currentPage });
    case BILLING.TOTAL_PAGE.SET:
      return (state = { ...state, totalPage });
    case BILLING.FACTURES.SET:
      return (state = {
        ...state,
        clientFacturesPro,
        memoryclientFacturesPro: clientFacturesPro,
      });
    case BILLING.NB_FACTURES.SET:
      return (state = { ...state, nbFactureFull });
    case BILLING.CODEREF.SET:
      return (state = { ...state, nbCodeRefFull });
    case BILLING.ENCART.INFOS:
      return (state = { ...state, encartInfos });
    case BILLING.ENCART.LOADING.START:
      return (state = { ...state, encartLoading : true });
    case BILLING.ENCART.LOADING.STOP:
      return (state = { ...state, encartLoading : false });
    case BILLING.DOWNLOAD.START:
      return (state = { ...state, download: [...state.download, download] });
    case BILLING.DOWNLOAD.STOP:
      return (state = {
        ...state,
        download: [...state.download].filter((item) => item !== download),
      });
    default:
      return state;
  }
}
