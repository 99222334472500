export const banner = {
  type: 'water',
  title: 'SUEZ Water Data Transfer',
  subtitle: "FACILITEZ VOTRE SUIVI DE CONSOMMATION D'EAU",
  text:
    "Vous êtes déjà équipé d'un outil de gestion des fluides et vous souhaitez ajouter vos suivis de consommation et de facturation d'eau des compteurs opérés par SUEZ Eau France&nbsp;? La solution SUEZ Water Data Transfer vous permet de recevoir automatiquement vos données par API (Interface de Programmation d'Application).<br />Si vous n'êtes pas encore équipé d'un outil de gestion des fluides, découvrez notre offre <u><a href=\"/espace-produit/fluids/\" target=\"_blank\" rel=\"noopener\">ON’connect™ fluids</a></u>.",
  image: '/img/products/wdt.jpg',
  file: '/docs/Plaquette_SWDT-26-10-23.pdf',
};

export const cards = [
  {
    title: 'Vos Enjeux',
    list: [
      'L’efficacité opérationnelle :',
      '• Gagner du temps grâce à la collecte automatique des données (reporting facilité, fiabilisé, certains déplacements évités)',
      '• Analyser plus facilement les consommations',
      '• Intervenir plus vite en cas de fuite',
      'La maîtrise budgétaire :',
      '• Identifier les économies réalisables et élaborer un plan d’action',
      '• Suivre plus finement les budgets',
      'Le développement durable :',
      '• Réduire les pertes en eau',
      '• Respecter les règlementations / normes environnementales',
      '• Communiquer auprès de vos cibles des actions concrètes en faveur de l’environnement'
    ]
  },
  {
    title: 'Nos réponses',
    list: [
      "Deux types de données sont accessibles via API (Interface de Programmation d'Application) :",
      '1 - Les données de facturation des compteurs sont disponibles pour l’ensemble des compteurs d’eau opérés par SUEZ EAU France',
      '2 - Les données de comptage sont disponibles pour les compteurs télé relevés dotés d’un équipement communicant',
      'Vous pouvez obtenir ces données de façon indépendante ou couplée (plusieurs abonnements disponibles), contactez-nous ! '
    ]
  }
];

export default {
  cards,
  banner
};
