import { BILLING } from '../types';
import moment from 'moment';
import axios from '../../services/Request';
import {
  downloadPdf,
  formatAmount,
  getFactureLength
} from '../../services/Utils';
import { openModal, setFacturesPro } from './app';
import BillingNlzr from '../normalize/billing';

export const setMemoryList = memoryList => ({
  type: BILLING.LIST.MEMORY,
  memoryList
});
export const setCurrentPage = currentPage => ({
  type: BILLING.CURRENT_PAGE.SET,
  currentPage
});
export const setTotalPage = totalPage => ({
  type: BILLING.TOTAL_PAGE.SET,
  totalPage
});
export const setList = list => ({
  type: BILLING.LIST.DISPLAY,
  list
});
export const setListEncart = listsEncarts => ({
  type: BILLING.LIST.ENCART,
  listsEncarts
});

export const encartStartLoading = () => ({
  type: BILLING.ENCART.LOADING.START,
});

export const encartStopLoading = () => ({
  type: BILLING.ENCART.LOADING.STOP,
});

export const billingStartLoading = () => ({
  type: BILLING.LOADING.START
});
export const billingStopLoading = () => ({
  type: BILLING.LOADING.STOP
});
export const billingSetError = error => ({
  type: BILLING.ERROR.SET,
  error
});
export const billingResetError = () => ({
  type: BILLING.ERROR.RESET
});
export const billingFormStartLoading = () => ({
  type: BILLING.FORM.LOADING.START
});
export const billingFormStopLoading = () => ({
  type: BILLING.FORM.LOADING.STOP
});
export const billingFormSetError = error => ({
  type: BILLING.FORM.ERROR.SET,
  error
});
export const billingFormResetError = () => ({
  type: BILLING.FORM.ERROR.RESET
});
export const billingFormSetSearched = searched => ({
  type: BILLING.FORM.SEARCHED.SET,
  searched
});
export const billingFormResetSearched = () => ({
  type: BILLING.FORM.SEARCHED.RESET
});
export const setNbCodeRef = nbCodeRefFull => ({
  type: BILLING.CODEREF.SET,
  nbCodeRefFull
});
export const setClientFacturesPro = clientFacturesPro => ({
  type: BILLING.FACTURES.SET,
  clientFacturesPro
});
export const setNbFacturesPro = nbFactureFull => ({
  type: BILLING.NB_FACTURES.SET,
  nbFactureFull
});
export const startDownload = download => ({
  type: BILLING.DOWNLOAD.START,
  download
});
export const stopDownload = download => ({
  type: BILLING.DOWNLOAD.STOP,
  download
});
export const setEncartInfos = encartInfos => ({
  type: BILLING.ENCART.INFOS,
  encartInfos
});

export const FetchBilling = (form, showMore) => {
  const url = '/api/invoices';
  const startLoading = showMore ? billingStartLoading : billingFormStartLoading;
  const stopLoading = showMore ? billingStopLoading : billingFormStopLoading;

  return async (dispatch, getState) => {
    try {
      const dates = {
        minBillingDate: moment(form.dates[0]).format('YYYY-MM-DD'),
        maxBillingDate: moment(form.dates[1]).format('YYYY-MM-DD')
      };

      const state = getState();
      const {
        billing: { currentPage, clientFacturesPro: prevFactures }
      } = state;

      const page = showMore ? currentPage + 1 : 1;
      const body = Object.fromEntries(
        Object.entries({
          ...form,
          ...dates,
          currentPage: page,
          itemsPerPage: 5
        }).filter(([key, value]) => value && value.toString().length > 0)
      );

      if (body.amount) body.amount = formatAmount(body.amount);

      if (!showMore) {
        dispatch(setClientFacturesPro([]));
      }
      
      delete body.dates
      
      dispatch(startLoading());
      const { data, headers } = await axios.post(url, body);
      const { clientFacturesPro, nbFactureFull, nbCodeRefFull } = data;
      dispatch(setFacturesPro(clientFacturesPro));
      const mergeFactures = [...prevFactures, ...clientFacturesPro];
      const totalPage = parseInt(headers['x-pagination-page-count']);
      const factures = showMore ? mergeFactures : clientFacturesPro;

      dispatch(billingSetError(null));
      if (!showMore) {
        dispatch(setTotalPage(totalPage));
        dispatch(setNbCodeRef(nbCodeRefFull));
        dispatch(setNbFacturesPro(nbFactureFull));
      }
      dispatch(billingFormSetSearched({ ...form }));
      dispatch(setClientFacturesPro(factures));
      dispatch(setCurrentPage(page));
      dispatch(stopLoading());
      return data;
    } catch ({ response }) {
      dispatch(billingSetError(response.data.message));
      dispatch(stopLoading());
    }
  };
};

export const filteredList = (showPaid = true, showPending = true) => {
  return function (dispatch, getState) {
    const { billing } = getState();
    const { memoryList } = billing;
    const newList = { ...memoryList, clientFacturesPro: [] };
    memoryList.clientFacturesPro.filter(billsInContract => {
      const filterFunc = ({ status }) => {
        return ('S' === status && showPaid) || ('A' === status && showPending);
      };
      const facturesPro = billsInContract.facturesPro.filter(filterFunc);
      newList.clientFacturesPro.push({ ...billsInContract, facturesPro });
    });
    dispatch(setList(newList));
  };
};

export const downloadBill = (url, billNb) => {
  return async dispatch => {
    dispatch(startDownload(billNb));
    try {
      const { data, headers } = await axios.get(url, { responseType: 'blob' });
      const filename = headers['content-disposition'].slice(21);
      dispatch(stopDownload(billNb));
      downloadPdf(data, filename);
    } catch (error) {
      dispatch(stopDownload(billNb));
      //Définir gestion erreur
    }
  };
};

export const getListEncart = form => {
  const url = '/api/invoice/inserts';
  return async dispatch => {
    try {
      dispatch(billingStartLoading());
      dispatch(openModal());
      dispatch(setEncartInfos(form));
      const { data } = await axios.post(url, form);
      dispatch(billingStopLoading());
      dispatch(setListEncart(data.ListeEncarts));
    } catch (error) {
      dispatch(billingStopLoading());
      dispatch(setListEncart([]));
    }
  };
};

export const downloadEncarts = (form, invoiceNumber) => {
  const url = '/api/invoice/inserts/download';
  return async dispatch => {
    try {
      dispatch(encartStartLoading());
      const { data, headers } = await axios.post(url, form, {
        responseType: 'blob'
      });
      const filename = headers['content-disposition'].slice(21);
      downloadPdf(data, filename);
      dispatch(encartStopLoading());
    } catch (error) {
      dispatch(encartStopLoading());
      dispatch(stopDownload());
    }
  };
};

export default {
  FetchBilling,
  setList,
  setMemoryList,
  setCurrentPage,
  filteredList,
  downloadBill,
  getListEncart,
  downloadEncarts,
  startDownload,
  stopDownload
};
