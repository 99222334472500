import React, { useEffect, useState } from "react";
import {
  SzAlert,
  SzSpinner,
  SzTypographie,
  SzAccordion,
} from "@suezenv/react-theme-components";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Profile from "./profile";
import CollapseContract from "../../components/Collapse/Contract";
import FilterBox from "../../components/FilterBox/FilterBox";
import Error from "../../components/Error/ErrorModel";
import Modal from "../../components/Modal/Review/Profile";
import { createdUser } from "../../redux/actions/signup";
import CollapseLoader from "../../components/CollapseContainer/CollapseLoader";
import CollapseContainer from "../../components/CollapseContainer";

export default function Review(props) {
  const { t } = useTranslation("pages", "components");
  const [activeKey, setActiveKey] = useState("0");
  const onClick = (key) => setActiveKey(key === activeKey ? null : key);
  const dispatch = useDispatch();
  const [contract, isLoading, user_created, contractStatus] = useSelector(
    ({ app, contract, signup }) => [
      contract.list,
      app.loading,
      signup.user_created,
      app.currentUser.contracts_status,
    ]
  );

  useEffect(() => {
    return () => {
      dispatch(createdUser(false));
    };
  }, []);

  const title = t("review.contract", { contractLength: contract.length });

  return (
    <main>
      <SzTypographie variant="h1" className="mb-3">
        {t("pages:review.title")}
      </SzTypographie>
      {user_created && (
        <SzAlert variant="success">{t("review.accountCreated")}</SzAlert>
      )}
      <Profile />
      <FilterBox />

      <CollapseContainer
        title={title}
        Collapse={CollapseContract}
        formLoading={isLoading}
        list={contract}
        displayShowMore={false}
        serverError={contractStatus === "KO"}
      />
      <Modal />
    </main>
  );
}
