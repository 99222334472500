import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SzChooseButton,
  SzButton,
  SzInput,
  SzCheckbox,
  SzAlert,
  SzTypographie
} from '@suezenv/react-theme-components';
import { Formik } from 'formik';
import { SignupModel, getFormatedForm } from '../../models';
import { SignupActions, AppActions } from '../../redux/actions';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { checkPassRules, hideInEnv } from '../../services/Utils';
import PasswordRules from '../../components/PasswordRules';
import FormConstructor, {
  InputConstructor,
  CheckBoxConstructor,
  RadioButtonConstructor
} from '../FormConstructor';

export default function SignUpForm() {
  const recaptchaRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [changing, setChanging] = useState([]);
  const [passwordValidation, setPasswordValidation] = useState(
    checkPassRules('')
  );
  const [appError, isLoading, activeStep] = useSelector(({ app, signup }) => [
    app.error,
    app.loading,
    signup.step
  ]);
  const { startLoading } = AppActions;
  const { signup } = SignupActions;
  const onSubmit = form => {
    const token_captcha = recaptchaRef.current.getValue();
    dispatch(signup({ ...form, token_captcha }));
  };

  const {
    civility,
    cgu,
    first_name,
    last_name,
    email,
    repeated_email,
    password,
    repeated_password
  } = SignupModel;

  const initialValues = { cgu: false };

  const {
    REACT_APP_CAPTCHA_SITE_KEY,
    REACT_APP_CAPTCHA_SECRETE_KEY
  } = process.env;

  return (
    <FormConstructor
      model={SignupModel}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ submitForm, values, isValid, errors, ...props }) => {
        const onButtonSubmit = e => {
          const show = hideInEnv('test', 'development');
          if (!show) {
            dispatch(startLoading());
            e.preventDefault();
            const captcha = recaptchaRef.current;
            captcha.reset();
            captcha.execute();
          }
        };
        const captchaSubmit = value => {
          if (activeStep === 0) {
            submitForm();
          }
        };
        const onBlur = () => {
          setPasswordValidation(checkPassRules(values['password']));
        };
        return (
          <>
            <RadioButtonConstructor
              name={'civility'}
              className="tsme-inscription-form__button-group col-12"
              {...civility}
            />
            <InputConstructor name={'last_name'} {...last_name} />
            <InputConstructor name={'first_name'} {...first_name} />
            <SzTypographie variant="h2" weight="bold" className="col-12 my-3">
              {t('pages:signup.id')}
            </SzTypographie>
            <InputConstructor name={'email'} {...email} />
            <InputConstructor name={'repeated_email'} {...repeated_email} />
            <div className="col-12 col-md-6">
              <InputConstructor
                {...password}
                className="col-md-12 p-0"
                name={'password'}
                onBlur={onBlur}
                showPassword
              />
              <InputConstructor
                {...repeated_password}
                name={'repeated_password'}
                className="col-md-12 p-0"
                showPassword
              />
            </div>
            <ReCAPTCHA
              sitekey={REACT_APP_CAPTCHA_SITE_KEY}
              stoken={REACT_APP_CAPTCHA_SECRETE_KEY}
              ref={recaptchaRef}
              size="invisible"
              onChange={captchaSubmit}
              badge="bottomleft"
              className="container-recaptcha position-fixed"
            />
            <PasswordRules passwordValidation={passwordValidation} />
            <CheckBoxConstructor label={t(cgu.label)} id="cgu" name={'cgu'} />
            <div className="col-12 mt-5">
              {appError && <SzAlert type="danger">{appError}</SzAlert>}
              <div className="row justify-content-between no-gutters">
                <SzButton
                  variant="secondary"
                  className="m-0 mr-3 mb-4 mb-md-0 sz-normal-font-size"
                >
                  {t('models:signup.buttons.ownership')}
                </SzButton>
                <SzButton
                  className="col-9 col-md-3 m-0"
                  type="submit"
                  onClick={onButtonSubmit}
                  isDisabled={!isValid || isLoading}
                  loader={isLoading}
                >
                  {t('models:signup.buttons.nextStep')}
                </SzButton>
              </div>
            </div>
          </>
        );
      }}
    </FormConstructor>
  );
}
