import { CONTRACT } from '../types';

const initialState = {
  list: [],
  memoryList: [],
  error: null,
  loading: false,
  modal: {
    open: false,
    activeForm: null,
    content: null
  },
  form: {
    loading: false,
    error: null
  },
  item: null
};

export default (
  state = initialState,
  { type, list, item, error, content, activeForm }
) => {
  switch (type) {
    case CONTRACT.LIST.SET:
      return { ...state, list };
    case CONTRACT.LIST.MEMORY:
      return (state = { ...state, memoryList: list });
    case CONTRACT.LIST.UPDATE:
      const updatedList = [...state.list];
      const index = updatedList.indexOf(state.item);
      updatedList[index] = {
        ...updatedList[index],
        ...item
      };
      return { ...state, list: updatedList };
    case CONTRACT.ITEM.SET:
      return { ...state, item };
    case CONTRACT.LOADING.START:
      return (state = { ...state, loading: true });
    case CONTRACT.LOADING.STOP:
      return (state = { ...state, loading: false });
    case CONTRACT.ERROR.SET:
      return (state = { ...state, error });
    case CONTRACT.ERROR.RESET:
      return (state = { ...state, error: null });
    case CONTRACT.FORM.LOADING.START:
      return (state = { ...state, form: { ...state.form, loading: true } });
    case CONTRACT.FORM.LOADING.STOP:
      return (state = { ...state, form: { ...state.form, loading: false } });
    case CONTRACT.FORM.ERROR.SET:
      return (state = { ...state, form: { ...state.form, error } });
    case CONTRACT.FORM.ERROR.RESET:
      return (state = { ...state, form: { ...state.form, error: null } });
    case CONTRACT.MODAL.OPEN:
      return (state = { ...state, modal: { ...state.modal, open: true } });
    case CONTRACT.MODAL.CLOSE:
      return (state = { ...state, modal: { ...state.modal, open: false } });
    case CONTRACT.MODAL.SET_ACTIVE_FORM:
      return (state = { ...state, modal: { ...state.modal, activeForm } });
    case CONTRACT.MODAL.RESET_ACTIVE_FORM:
      return (state = {
        ...state,
        modal: { ...state.modal, activeForm: null }
      });

    case CONTRACT.MODAL.SET_CONTENT:
      return (state = { ...state, modal: { ...state.modal, content } });
    case CONTRACT.MODAL.RESET_CONTENT:
      return (state = { ...state, modal: { ...state.modal, content: null } });
    default:
      return state;
  }
};
