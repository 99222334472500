import { SIGNUP } from '../types';

const initialState = {
  step: 0,
  user_created: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP.LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SIGNUP.ACTIVESTEP:
      return {
        ...state,
        step: 1
      };
    case SIGNUP.USER_CREATED:
      return {
        ...state,
        user_created: action.user_created
      };
    default:
      return state;
  }
};
