import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  SzAlert,
  SzButton,
  SzIcon,
  SzTooltip,
  SzTypographie
} from '@suezenv/react-theme-components';
import { Form, Select } from '@suezenv/tsme-react-components';
import axios from '../../services/Request';
import { setNextStep } from './Modal/Invite';
import classnames from 'classnames';

interface usersProps {
  email: string;
  action: string;
}

type userStatus =
  | 'doesnt_exist'
  | 'exist_on_other_account'
  | 'exist_on_this_account';

export interface RolesProps {
  dataUser: usersProps[];
  setNextStep: setNextStep;
  setPrevStep: () => void;
}
const KEY_CODE_NO_ADMIN: userStatus = 'doesnt_exist';

export const Roles: FC<RolesProps> = (props) => {
  const { dataUser, setNextStep, setPrevStep } = props;
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [loaderSubmit, setLoaderSubmit] = useState(false);

  const onSubmitForm = async (form: any) => {
    setErrorMessage('');
    setLoaderSubmit(true);
    const body: any = [];
    for (let index = 0; index < dataUser.length; index++) {
      const role = form[`user${index}_role`];
      const type =
        typeof role === 'object' && role !== null ? role.value : role;

      if (type) {
        body.push({
          type,
          email: form[`user${index}_mail`]
        });
      }
    }

    try {
      const response = await axios.post('/api/management/user', body, {
        headers: { post: { 'Content-Type': 'application/json' } }
      });
      setLoaderSubmit(false);

      if (response.data.code === '00') {
        setNextStep();
      }
    } catch (error: any) {
      setLoaderSubmit(false);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const modelForm: any = {};
  const initialValues: any = {};

  for (const index in dataUser) {
    const { email, action } = dataUser[index];
    // Set schema
    modelForm[`user${index}_mail`] = {
      name: `user${index}_mail`,
      schema: Yup.string()
    };
    modelForm[`user${index}_role`] = {
      name: `user${index}_role`,
      schema: Yup.string()
    };

    if (action === KEY_CODE_NO_ADMIN) {
      // Set initialValues
      initialValues[`user${index}_mail`] = email;
      initialValues[`user${index}_role`] = {
        label: 'Utilisateur',
        value: 'simple'
      };

      // Set extra model data
      Object.assign(modelForm[`user${index}_role`], {
        className: ' ',
        options: [
          {
            label: 'Utilisateur',
            value: 'simple'
          },
          {
            label: 'Administrateur',
            value: 'admin'
          }
        ]
      });
    }
  }

  return (
    <div className="px-2 px-md-5 mx-md-5">
      <div className="row">
        <SzTypographie weight="bold">
          {t('adminSpace.inviteModal.title', { count: dataUser.length })}
        </SzTypographie>

        <SzTypographie weight="regular">
          {t('adminSpace.inviteModal.subtitle')}
        </SzTypographie>
      </div>

      <Form
        onSubmit={onSubmitForm}
        validationSchema={modelForm}
        initialValues={initialValues}
      >
        {() => {
          return (
            <>
              <div className="border w-100">
                {dataUser.map(({ email, action }, index) => {
                  return (
                    <div
                      className={classnames(`row mx-0 py-1`, {
                        'bg-light': (index + 1) % 2
                      })}
                      key={email}
                    >
                      <div className="col-7 col-md-7 my-auto">
                        <SzTypographie weight="bold" className="m-0">
                          {email}
                        </SzTypographie>
                        <input
                          type="hidden"
                          name={`user${index}_mail`}
                          value={email}
                        />
                      </div>
                      <div className="col-6 col-md-5">
                        {action === KEY_CODE_NO_ADMIN ? (
                          <div className="row">
                            <Select
                              {...modelForm[`user${index}_role`]}
                              className="offset-2 col-8"
                            />
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-6 offset-4">
                              <SzTypographie
                                variant="caption"
                                size="M"
                                color="disabled"
                                align="center"
                                className="border py-2"
                              >
                                {t(`components:admins.roles.${action}`)}
                              </SzTypographie>
                              <input
                                type="hidden"
                                name={`user${index}_role`}
                                value={action}
                              />
                            </div>
                            <div className="col-2 text-center">
                              <SzTooltip
                                content={t(
                                  `components:admins.tooltip.${action}`
                                )}
                              >
                                <SzIcon
                                  variant="line"
                                  icon="information-circle"
                                />
                              </SzTooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {errorMessage && (
                <SzAlert variant="danger" className="row p-0 m-0 mt-3 ">
                  {t(`components:errors.techError`)}
                </SzAlert>
              )}

              <div className="row px-3 justify-content-between mt-3">
                <SzButton
                  className={`bg-transparent`}
                  variant="secondary"
                  icon="arrow-left"
                  onClick={setPrevStep}
                >
                  {t('adminSpace.inviteModal.back_button')}
                </SzButton>
                <SzButton type="submit" loader={loaderSubmit}>
                  {t('adminSpace.inviteModal.button')}
                </SzButton>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default Roles;
