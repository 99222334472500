import React from 'react';
import { SzHeader } from '@suezenv/react-theme-components';
import LogoLink from './Logo';

export const Unconnected = () => (
  <SzHeader className="tsme-header unlogged d-flex align-items-stretch justify-content-between">
    <LogoLink />
  </SzHeader>
);

export default Unconnected;
