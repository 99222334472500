import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Payment(props) {
  const { t } = useTranslation();
  return (
    <div className="m-auto">
      <h1 className="py-3 sz-normal-font-size font-weight-bold">
        ESPACE DE PAIEMENT
      </h1>
      <div className="row">
        <iframe
          title="This is a unique title"
          src="https://ssl-sps-cat-sign-service.aw.atos.net/signature/fr/mandateinputpage"
          width="100%"
          height="700px"
        ></iframe>
      </div>
    </div>
  );
}
