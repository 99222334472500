import React from "react";
import Skeleton from "react-loading-skeleton";

export default function CollapseLoader() {
  return (
    <div className="w-100 pt-4">
      <div className="border-bottom">
        <Skeleton count={5} duration={2} width={40} />
      </div>
      <Skeleton className="mt-3" count={5} duration={2} height={93} />
    </div>
  );
}
